<template>
  <footer id="footer" class="container mb-2 mt-10 h-auto">
    <div class="flex flex-col justify-center px-8 py-4 md:mx-8">
      <div class="m-auto my-4 flex justify-center md:w-1/2">
        <a
          href="https://www.facebook.com/FyrTechnologies"
          target="_blank"
          class="mx-6 cursor-pointer text-gray-500 transition duration-300 hover:text-facebook-blue focus:text-facebook-blue focus:outline-none"
          aria-label="Fyr's Facebook"
        >
          <Icon name="facebook" class="h-5 w-6" />
        </a>
        <a
          href="https://www.instagram.com/fyr_ai/"
          target="_blank"
          class="mx-6 cursor-pointer text-gray-500 transition duration-300 hover:text-pink-700 focus:text-pink-700 focus:outline-none"
          aria-label="Fyr's Instagram"
        >
          <Icon name="instagram" class="h-5 w-6" />
        </a>
        <a
          href="https://fyr.ai/"
          target="_blank"
          class="mx-6 cursor-pointer text-gray-500 transition duration-300 hover:text-lighthouse-orange focus:text-lighthouse-orange focus:outline-none"
          aria-label="Fyr's website"
        >
          <Icon name="browser" class="h-5 w-6" />
        </a>
      </div>
      <p
        class="m-auto my-2 flex justify-center divide-x divide-purple-500 text-xs *:px-1 first:*:pr-1 last:*:pl-1 md:w-1/2"
      >
        <RouterLink
          :to="{
            name: 'docs-document',
            params: { document: 'company-privacy-policy' },
          }"
          class="inline-link"
        >
          Company Privacy Policy
        </RouterLink>
        <RouterLink
          :to="{
            name: 'docs-document',
            params: { document: 'application-privacy-policy' },
          }"
          class="inline-link"
        >
          App Privacy Policy
        </RouterLink>
        <RouterLink
          :to="{
            name: 'docs-document',
            params: { document: 'disclosures' },
          }"
          class="inline-link"
        >
          Disclosures
        </RouterLink>
      </p>
      <div class="m-auto flex justify-center md:w-1/2">
        <span class="text-sm text-gray-500">
          &#169; {{ currentYear }} Fyr. All rights reserved.
        </span>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import Icon from "@/components/Core/Icon.vue";

const currentYear = new Date().getFullYear();
</script>
