import { AxiosPromise } from "axios";
import api from "@/services/api";
import { Profile } from "@/services/user";
export function changePassword(
  password: string,
  confirmPassword: string,
  oldPassword: string,
): AxiosPromise<void> {
  return api.post("/auth/password_change/", {
    password,
    confirmPassword,
    oldPassword,
  });
}
export function resetPassword(
  uidb64: string,
  password: string,
  confirmPassword: string,
): AxiosPromise<void> {
  return api.post(`/auth/reset/${uidb64}/set-password/`, {
    password,
    confirmPassword,
  });
}

export function validatePassword(
  password: string,
  email?: string,
  name?: string,
): AxiosPromise<void> {
  return api.post("/auth/validate_password/", {
    password,
    email,
    name,
  });
}

export function getUserByResetToken(
  token: string | string[],
): AxiosPromise<Profile> {
  return api.post("/auth/user_info_by_reset_token/", {
    uidb64: token,
  });
}

export function logout(): AxiosPromise<void> {
  return api.post("/auth/logout/");
}
