import { RouteRecordRaw } from "vue-router";

export function backendEmailRoutes(): RouteRecordRaw[] {
  return [
    {
      path: "/backendEmail",
      name: "backendEmail",
      meta: {
        title: "BackendEmail",
        includeNavbar: false,
        includeFooter: false,
      },
      component: () => import("@/views/BackendEmails/Index.vue"),
      children: [
        {
          path: "statCard",
          name: "statCard",
          meta: { title: "StatCard" },
          component: () => import("@/views/BackendEmails/StatCard.vue"),
        },
        {
          path: "gauge",
          name: "gauge",
          meta: { title: "Gauge" },
          component: () => import("@/views/BackendEmails/Gauge.vue"),
        },
        {
          path: "siteScoreLegend",
          name: "siteScoreLegend",
          meta: { title: "SiteScoreLegend" },
          component: () => import("@/views/BackendEmails/SiteScoreLegend.vue"),
        },
        {
          path: "recommendationCard",
          name: "recommendationCard",
          meta: { title: "RecommendationCard" },
          component: () =>
            import("@/views/BackendEmails/RecommendationCard.vue"),
        },
        {
          path: "barChart",
          name: "barChart",
          meta: { title: "BarChart" },
          component: () => import("@/views/BackendEmails/BarChart.vue"),
        },
      ],
    },
  ];
}
