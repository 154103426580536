import api from "@/services/api";
import { useAuthStore } from "@/store/auth";
import { DateTime } from "luxon";
import { reactive, toRef } from "vue";
import { useQuery } from "@tanstack/vue-query";

export enum Level {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export interface Notice {
  pk: number;
  start: DateTime;
  end: DateTime;
  level: Level;
  title: string;
  content: string;
}

export const LIST_NOTICES_URL = "/notices/";

function listNotices(): Promise<Notice[]> {
  return api.get(LIST_NOTICES_URL).then((response) => response.data);
}

export function useNoticesQuery() {
  const authStore = useAuthStore();
  return reactive(
    useQuery({
      queryKey: ["notices"],
      queryFn: listNotices,
      enabled: toRef(authStore, "isAuthed"),
    }),
  );
}
