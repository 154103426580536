export function isURL(input: string): boolean {
  let url;
  const protocolPattern = new RegExp("http(s)?");

  if (!protocolPattern.test(input)) {
    return false;
  }

  try {
    url = new URL(input);
  } catch {
    return false;
  }

  if (!url.host.includes(".")) {
    return false;
  } else {
    return true;
  }
}

export function isVideo(url: string | null): boolean {
  const video_extensions = [
    ".asf",
    ".avi",
    ".gifv",
    ".m4p",
    ".m4v",
    ".mov",
    ".mp2",
    ".mp4",
    ".mpeg",
    ".mpg",
    ".ogv",
    ".qt",
    ".webm",
    ".wmv",
  ];
  return video_extensions.some(
    (ext) => url?.toLowerCase().includes(ext) ?? false,
  );
}

export function isImage(url: string | null): boolean {
  const image_extensions = [".gif", ".jpg", ".jpeg", ".png", ".webp"];
  return image_extensions.some(
    (ext) => url?.toLowerCase().includes(ext) ?? false,
  );
}
