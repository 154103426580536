import { RouteRecordRaw } from "vue-router";

export const publicReportRoute: RouteRecordRaw[] = [
  {
    path: "report/:id",
    name: "report",
    meta: {
      title: "Report",
      includeNavbar: "isAuthed",
      requiresAuth: false,
    },
    component: () => import("@/views/Reporting/ReportPublic.vue"),
  },
];
export const reportingRoutes: RouteRecordRaw[] = [
  {
    path: "dashboard",
    name: "dashboards",
    redirect: { name: "dashboard-overview" },
    meta: {
      title: "Custom dashboards",
      description: "Dynamically updated dashboards showing your current stats.",
      icon: "browser",
      helpDeskPath: "reporting",
    },
    children: [
      {
        path: "overview",
        name: "dashboard-overview",
        meta: { title: "Custom Dashboards" },
        component: () => import("@/views/Dashboard/DashboardManagement.vue"),
      },
      {
        path: "edit/:pk",
        name: "edit-dashboard",
        meta: {
          title: "Edit Dashboard",
        },
        component: () => import("@/views/Dashboard/CustomizeDashboard.vue"),
      },
      {
        path: ":pk",
        name: "view-dashboard",
        meta: {
          title: "View Dashboard",
        },
        component: () => import("@/views/Dashboard/Dashboard.vue"),
      },
    ],
  },
  {
    path: "reports",
    redirect: { name: "reporting-overview" },
    name: "reports-reports-base",
    meta: {
      title: "Reporting",
      added: "2023-01-06",
      text: "Reports",
      description: "Write and read reports for specific date ranges.",
      icon: "file-alt",
      helpDeskPath: "reporting",
    },
    component: () => import("@/views/Reporting/ReportingBase.vue"),
    children: [
      {
        path: "overview",
        name: "reporting-overview",
        meta: { title: "Report Overview" },
        component: () => import("@/views/Reporting/Overview.vue"),
      },
      {
        path: "edit/:id",
        name: "report-editor",
        meta: { title: "Report Viewer" },
        component: () => import("@/views/Reporting/ReportEditor.vue"),
      },
    ],
  },
];
