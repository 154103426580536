import api, { baseUrl } from "@/services/api";
import { RecommendationType } from "@/services/recommendations/recommendations";
import { ResourceChannel, channelToPath } from "@/services/resources";
import { assertUnreachable } from "@/utils/typing";
import { computed, reactive } from "vue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { RouteLocationRaw } from "vue-router";
import { toURLParam } from "@/utils/recommendations";
import { useAuthStore } from "@/store/auth";

export enum NotificationType {
  RESOURCE_ADDED = "RESOURCE_ADDED",
  RESOURCE_REMOVED = "RESOURCE_REMOVED",
  URGENT_RECOMMENDATION = "URGENT_RECOMMENDATION",
  AUTOPILOT_ENABLED = "AUTOPILOT_ENABLED",
  RESOURCE_UNAVAILABLE = "RESOURCE_UNAVAILABLE",
  TOKEN_EXPIRES_SOON = "TOKEN_EXPIRES_SOON",
  DLC_PAUSE_AD = "DLC_PAUSE_AD",
  DLC_ENABLE_AD = "DLC_ENABLE_AD",
  NEW_FEATURE_TOKEN = "NEW_FEATURE_TOKEN",
}
type ReadStatus = "READ" | "PARTIALLY_READ" | "UNREAD";

interface BaseNotification {
  pk: number;
  organization: number;
  createdAt: string;
  readStatus: ReadStatus;
}

// Technically this is not part of the first iteration spec..
export interface ResourceAddedNotification extends BaseNotification {
  type: NotificationType.RESOURCE_ADDED;
  details: {
    resourceChannel: ResourceChannel;
    user: number;
  };
}
export interface ResourceRemovedNotification extends BaseNotification {
  type: NotificationType.RESOURCE_REMOVED;
  details: {
    name: string;
    channel: ResourceChannel;
    user: number;
  };
}
export interface UrgentRecommendationNotification extends BaseNotification {
  type: NotificationType.URGENT_RECOMMENDATION;
  details: { recommendationType: RecommendationType };
}
export interface AutoPilotEnabledNotification extends BaseNotification {
  type: NotificationType.AUTOPILOT_ENABLED;
  details: { recommendationType: RecommendationType; user: number };
}
export interface ResourceUnavailableNotification extends BaseNotification {
  type: NotificationType.RESOURCE_UNAVAILABLE;
  details: { cause: string; id: number; channel: ResourceChannel };
}
export interface TokenExpiresSoonNotification extends BaseNotification {
  type: NotificationType.TOKEN_EXPIRES_SOON;
  details: {
    tokenId: number;
    expiresAt: string;
    isFirstNotification: boolean;
    channel: ResourceChannel;
  };
}

export interface DlcPauseAdNotification extends BaseNotification {
  type: NotificationType.DLC_PAUSE_AD;
  details: { autopilotEnabled: boolean };
}
export interface DlcEnableAdNotification extends BaseNotification {
  type: NotificationType.DLC_ENABLE_AD;
  details: { autopilotEnabled: boolean };
}

export interface NewFeatureTokenNotification extends BaseNotification {
  type: NotificationType.NEW_FEATURE_TOKEN;
  details: { channel: ResourceChannel; id: number };
}

export type Notification =
  | ResourceAddedNotification
  | UrgentRecommendationNotification
  | AutoPilotEnabledNotification
  | ResourceRemovedNotification
  | ResourceUnavailableNotification
  | TokenExpiresSoonNotification
  | DlcEnableAdNotification
  | DlcPauseAdNotification
  | NewFeatureTokenNotification;

export function getNotificationCtaLink(
  notification: Notification,
): RouteLocationRaw | string {
  switch (notification.type) {
    case NotificationType.RESOURCE_ADDED:
      return { name: "resources" };
    case NotificationType.RESOURCE_REMOVED:
      return { name: "resources" };
    case NotificationType.RESOURCE_UNAVAILABLE:
      return { name: "resources" };
    case NotificationType.AUTOPILOT_ENABLED:
      return {
        name: "recommendation-details",
        params: {
          type: toURLParam(notification.details.recommendationType),
        },
      };
    case NotificationType.URGENT_RECOMMENDATION:
      return { name: "recommendations" };
    case NotificationType.TOKEN_EXPIRES_SOON:
      return { name: "resources" }; //TODO: Link to the auth flow for the channel
    case NotificationType.DLC_ENABLE_AD:
      return {
        name: "recommendation-details",
        params: {
          type: toURLParam(RecommendationType.DEAD_LINK_CHECKER),
        },
      };
    case NotificationType.DLC_PAUSE_AD:
      return {
        name: "recommendation-details",
        params: {
          type: toURLParam(RecommendationType.DEAD_LINK_CHECKER),
        },
      };
    case NotificationType.NEW_FEATURE_TOKEN:
      return `${baseUrl}/api/oauth/${
        channelToPath[notification.details.channel]
      }/authorize/?flow=refresh&token_id=${notification.details.id}`;
    default:
      assertUnreachable(notification);
  }
}

export function listAllNotifications(): Promise<Notification[]> {
  return api.get("/notifications/").then((response) => response.data);
}

export function useNotificationsQuery() {
  const authStore = useAuthStore();
  return reactive(
    useQuery({
      queryKey: ["notifications"],
      queryFn: listAllNotifications,
      enabled: computed(() => authStore.isAuthed),
    }),
  );
}

export type UpdateNotificationPayload = Pick<Notification, "pk" | "readStatus">;

export function updateNotification(
  notification: UpdateNotificationPayload,
): Promise<Notification> {
  return api
    .put(`/notifications/${notification.pk}/`, notification)
    .then((res) => res.data);
}

export function useUpdateNotificationMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: updateNotification,
      onSuccess: (updatedNotification) => {
        queryClient.setQueryData<Notification[]>(
          ["notifications"],
          (notifications) =>
            notifications?.map((notification) =>
              notification.pk === updatedNotification.pk
                ? updatedNotification
                : notification,
            ),
        );
      },
    }),
  );
}

function readAllNotifications(): Promise<void> {
  return api.post("/notifications/mark_all_read/");
}

export function useReadAllNotificationsMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: readAllNotifications,
      onSettled: () =>
        queryClient.invalidateQueries({ queryKey: ["notifications"] }),
    }),
  );
}
