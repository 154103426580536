import { Tier } from "@/services/organization";
import { DeepReadonly } from "vue";
import { RouteRecordRaw } from "vue-router";
import { Organization } from "@/services/organization";
import { UserRole } from "@/services/user";

export function hasAccessTier(
  current: Tier | undefined,
  required: Tier | undefined,
): boolean {
  if (!current) return false;
  if (!required) return true;
  if (required === Tier.LARGE) return current === Tier.LARGE;
  if (required === Tier.MEDIUM) return current !== Tier.SMALL;
  if (required === Tier.SMALL) return true;
  return false;
}
export function hasRequiredUserRole(
  current: UserRole | string | undefined,
  required?: UserRole,
): boolean {
  if (!current) return false;
  if (!required) return true;
  if (required === UserRole.MANAGER) return current === UserRole.MANAGER;
  if (required === UserRole.USER) return true;
  return false;
}

export function routeDisabledDueToAccessTier(
  route: RouteRecordRaw,
  organization: DeepReadonly<Organization> | undefined,
): boolean {
  return route.meta?.requiredTier
    ? !hasAccessTier(organization?.subscriptionTier, route.meta.requiredTier)
    : false;
}
