import { ResourceChannel } from "@/services/resources";
import { Organization } from "@/services/organization";
import { Color } from "@/utils/colors";

export enum RecommendationStatus {
  // Abstract
  OPEN = "Open",
  DONE = "Done",
  DISMISSED = "Dismissed",
  ASSIGNED = "Assigned to me",
}
export const RECOMMENDATION_STATUSES: RecommendationStatus[] =
  Object.values(RecommendationStatus);

export enum RecommendationPriority {
  LOW = "Low priority",
  MEDIUM = "Medium priority",
  HIGH = "High priority",
  URGENT = "Urgent",
}

export function getRecommendationPriorityText(recommendationWithPriority: {
  priority: RecommendationPriority;
}): string {
  switch (recommendationWithPriority.priority) {
    case RecommendationPriority.LOW:
      return "suggestion";
    case RecommendationPriority.MEDIUM:
      return "improvement";
    case RecommendationPriority.HIGH:
      return "issue";
    case RecommendationPriority.URGENT:
      return "critical issue";
    default:
      return RecommendationPriority[recommendationWithPriority.priority];
  }
}
export interface Recommendation {
  name: RecommendationType;
  priority: RecommendationPriority;
  taskCount: number;
  todoCount: number;
  inProgressCount: number;
  doneCount: number;
  dismissedCount: number;
  assignedToMeCount: number;
  availableSolutions: string[];
  availableCopilotSolutions: string[];
  availableAutopilotSolutions: string[];
}
export enum RecommendationTag {
  ACCOUNT_STRUCTURE = "Account structure",
  CAMPAIGN_SETTINGS = "Campaign settings",
  BIDS = "Bids",
  BUDGETS = "Budgets",
  KEYWORDS = "Keywords",
  ADS = "Ads",
  AD_EXTENSIONS = "Ad extensions",
  AUTOMATION = "Automation",
  TRACKING = "Tracking",
  AUDIENCE = "Audience",
  SEO = "SEO",
  SOCIAL_MEDIA = "Social media",
  WEBSITE = "Website",
}
export enum RecommendationChannel {
  GOOGLE_ADS = "Google Ads",
  FACEBOOK_ADS = "Facebook Ads",
  GOOGLE_SEARCH = "Google Search",
  GOOGLE_ANALYTICS = "Google Analytics",
  GOOGLE_ANALYTICS_4 = "Google Analytics 4",
  YOUTUBE = "YouTube",
  LINKED_IN_ADS = "LinkedIn Ads",
  SEO = "Website",
  FACEBOOK_ORGANIC = "Facebook Organic",
  LINKED_IN_ORGANIC = "LinkedIn Organic",
  INSTAGRAM_ORGANIC = "Instagram Organic",
  XANDR = "Xandr",
  SNAPCHAT_ADS = "Snapchat Ads",
  BING_ADS = "Bing Ads",
  TIK_TOK_ADS = "TikTok Ads",
  TIK_TOK_ORGANIC = "TikTok Organic",
}

export interface RecommendationTypeDetail {
  title: string;
  tag: RecommendationTag;
  channels: RecommendationChannel[];
  description: string;
  feedCardText(count: number): string;
  linkUrl?: string;
  linkText?: string;
}

export enum RecommendationType {
  GOOGLE_ADS_RESPONSIVE_SEARCH_AD_IMPROVE_AD_STRENGTH = "GOOGLE_ADS_RESPONSIVE_SEARCH_AD_IMPROVE_AD_STRENGTH",
  GOOGLE_ADS_DISCOVERY_ADS_IMPROVE_AD_STRENGTH = "GOOGLE_ADS_DISCOVERY_ADS_IMPROVE_AD_STRENGTH",
  GOOGLE_ADS_MAXIMUM_RECOMMENDED_AMOUNT_OF_KEYWORDS_PER_AD_GROUP = "GOOGLE_ADS_MAXIMUM_RECOMMENDED_AMOUNT_OF_KEYWORDS_PER_AD_GROUP",
  GOOGLE_ADS_MINIMUM_ADS_OF_TYPE_PER_AD_GROUP = "GOOGLE_ADS_MINIMUM_ADS_OF_TYPE_PER_AD_GROUP",
  GOOGLE_ADS_OVERLAPPING_KEYWORDS = "GOOGLE_ADS_OVERLAPPING_KEYWORDS",
  GOOGLE_ADS_CALLOUT_AMOUNT = "GOOGLE_ADS_CALLOUT_AMOUNT",
  GOOGLE_ADS_SITELINK_AMOUNT = "GOOGLE_ADS_SITELINK_AMOUNT",
  GOOGLE_ADS_EXCLUDE_IRRELEVANT_SEARCH_TERMS = "GOOGLE_ADS_EXCLUDE_IRRELEVANT_SEARCH_TERMS",
  GOOGLE_ANALYTICS_4_TRACKING_CODE = "GOOGLE_ANALYTICS_4_TRACKING_CODE",
  GOOGLE_ADS_ZERO_IMPRESSION_KEYWORDS = "GOOGLE_ADS_ZERO_IMPRESSION_KEYWORDS",
  GOOGLE_ADS_BROAD_MATCH_TYPE_KEYWORD = "GOOGLE_ADS_BROAD_MATCH_TYPE_KEYWORD",
  GOOGLE_ADS_CONVERSION_ACTIONS = "GOOGLE_ADS_CONVERSION_ACTIONS",
  GOOGLE_ADS_SHARED_NEGATIVE_LIST = "GOOGLE_ADS_SHARED_NEGATIVE_LIST",
  GOOGLE_ADS_LIMITED_BY_BUDGET = "GOOGLE_ADS_LIMITED_BY_BUDGET",
  GOOGLE_ADS_BRANDED_GENERIC_KEYWORD_MIX = "GOOGLE_ADS_BRANDED_GENERIC_KEYWORD_MIX",
  FACEBOOK_AD_SET_SIZE = "FACEBOOK_AD_SET_SIZE",
  FACEBOOK_AD_FREQUENCY = "FACEBOOK_AD_FREQUENCY",
  FACEBOOK_MISSING_PIXEL_INSTALLATION = "FACEBOOK_MISSING_PIXEL_INSTALLATION",
  FACEBOOK_MISSING_UTM_TRACKING = "FACEBOOK_MISSING_UTM_TRACKING",
  FACEBOOK_AD_TEXT_LENGTH = "FACEBOOK_AD_TEXT_LENGTH",
  LINKED_IN_ADS_CAMPAIGN_SIZE = "LINKED_IN_ADS_CAMPAIGN_SIZE",
  LINKED_IN_ADS_TARGETING_FOR_SMALL_AUDIENCE = "LINKED_IN_ADS_TARGETING_FOR_SMALL_AUDIENCE",
  LINKED_IN_ADS_URL_IN_AD_TEXT = "LINKED_IN_ADS_URL_IN_AD_TEXT",
  LINKED_IN_ADS_TEXT_LENGTH = "LINKED_IN_ADS_TEXT_LENGTH",
  LINKED_IN_ADS_CAMPAIGN_CONVERSIONS = "LINKED_IN_ADS_CAMPAIGN_CONVERSIONS",
  SEO_TITLE_ISSUE = "SEO_TITLE_ISSUE",
  SEO_DESCRIPTION_ISSUE = "SEO_DESCRIPTION_ISSUE",
  SEO_HEADING_ISSUE = "SEO_HEADING_ISSUE",
  SEO_INDEXABILITY_ISSUE = "SEO_INDEXABILITY_ISSUE",
  SEO_OPEN_GRAPH_ISSUE = "SEO_OPEN_GRAPH_ISSUE",
  SEO_IMAGE_ISSUE = "SEO_IMAGE_ISSUE",
  SEO_REDIRECT_ISSUE = "SEO_REDIRECT_ISSUE",
  SEO_LINK_ISSUE = "SEO_LINK_ISSUE",
  SEO_CANONICAL_ISSUE = "SEO_CANONICAL_ISSUE",
  SEO_TEXT_ISSUE = "SEO_TEXT_ISSUE",
  ACTIVE_SOCIAL_MEDIA_ACCOUNT = "ACTIVE_SOCIAL_MEDIA_ACCOUNT",
  ADDRESS_POLICY_VIOLATIONS = "ADDRESS_POLICY_VIOLATIONS",
  DEAD_LINK_CHECKER = "DEAD_LINK_CHECKER",
  SOFT_404 = "SOFT_404",
  RENEW_SSL_CERTIFICATE = "RENEW_SSL_CERTIFICATE",
}

export const RecommendationTypeDetails: Record<
  RecommendationType,
  RecommendationTypeDetail
> = {
  GOOGLE_ADS_DISCOVERY_ADS_IMPROVE_AD_STRENGTH: {
    title: "Improve Ad Strength - Discovery Ads",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Google strongly recommends maximizing the number of headlines and descriptions to optimize your ad's performance. It is essential to make your headlines and descriptions unique to increase the combinations that can be generated for an ad, including using keywords from your ad group.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ads" : "a new ad"
      }  to have too few headlines and/or descriptions.`,
    linkUrl:
      "https://support.google.com/google-ads/answer/10912345?sjid=9019933664374328005-EU",
    linkText: "Learn more about Ad Strength for discovery ads",
  },
  GOOGLE_ADS_RESPONSIVE_SEARCH_AD_IMPROVE_AD_STRENGTH: {
    title: "Improve Ad Strength - Responsive Search Ads",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Google strongly recommends maximizing the number of headlines and descriptions to optimize your ad's performance. It is essential to make your headlines and descriptions unique to increase the combinations that can be generated for an ad, including using keywords from your ad group. Advertisers who improve ad strength for their responsive search ads from poor to excellent see 9% more clicks and conversions on average, according to Google internal data.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ads" : "a new ad"
      }  to have too few headlines and/or descriptions.`,
    linkUrl:
      "https://support.google.com/google-ads/answer/9921843?hl=en&sjid=1239549286801565003-EU",
    linkText: "Learn more about Ad Strength for responsive search ads",
  },
  GOOGLE_ADS_CALLOUT_AMOUNT: {
    title: "Make sure to have at least 4 callouts per ad group",
    tag: RecommendationTag.AD_EXTENSIONS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Google highly recommends creating at least 4 Callout extensions at ad group level. Callout extensions are a great way to make the ads take up more space while also pushing our competitors down the page, and so it's an easy way to improve ad quality score and increase the CTR. By using non-generic callouts, you increase your chance to stand out from your competition. You can even tailor your call-outs by setting up different callouts for a specific day of the week or time of the day.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ad groups" : "a new ad group"
      }  to have too few callouts.`,
    linkUrl:
      "https://support.google.com/google-ads/answer/7301149?hl=en&ref_topic=3119125",
    linkText: "Learn more about using callouts",
  },
  GOOGLE_ADS_SITELINK_AMOUNT: {
    title: "Make sure to have at least 4 sitelinks per ad group",
    tag: RecommendationTag.AD_EXTENSIONS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Google Ads sitelinks are ad extensions that allow advertisers to promote up to four additional links to their website within one PPC ad. Sitelinks are a way to stand out against your competitors while driving a 10-15% uplift in the CTR. By providing users with relevant links and landing pages to fulfil their search intent, we can improve the user experience and so increase the conversion rate. We recommend creating the sitelinks at ad group level to be able to customize them.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ad groups" : "a new ad group"
      } to have too few sitelinks.`,
    linkUrl:
      "https://support.google.com/google-ads/answer/2375416?hl=en&ref_topic=3119125",
    linkText: "Learn more about using sitelinks",
  },
  GOOGLE_ADS_MAXIMUM_RECOMMENDED_AMOUNT_OF_KEYWORDS_PER_AD_GROUP: {
    title: "Keep ad groups limited to a maximum of 10 keywords",
    tag: RecommendationTag.ACCOUNT_STRUCTURE,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "To ensure that the ad content is consistent with the user's search term, it is important to only include keywords that are closely related in the same ad group. A rule of thumb is to never exceed 10 keywords per group. More often than not, it's better to use even less keywords, to ensure a close link between your keywords and ads. To improve ad relevance, include your focus keyword in Headline 1 and Description 1. Consider your current keywords carefully; If some are only slightly related to the ad group's focus, it's better to create new ad groups containing ads tailored to those keywords - or to remove the keywords if they're not relevant to your target audience.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ad groups" : "a new ad group"
      } to have too many keywords.`,
    linkUrl:
      "https://support.google.com/google-ads/answer/6337820?hl=en&ref_topic=3122865",
    linkText: "Learn more about optimizing keywords",
  },
  GOOGLE_ADS_OVERLAPPING_KEYWORDS: {
    title: "Prevent keywords from overlapping",
    tag: RecommendationTag.KEYWORDS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Having the same keyword in multiple ad groups can lead to a disorganized ad account. Limiting a keyword to one ad group allows you to direct which ads are shown related to that specific keyword.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new keywords" : "a new keyword"
      } to be overlapping.`,
    linkUrl:
      "https://support.google.com/google-ads/answer/2756257?hl=en&visit_id=637486643325451679-3188617709&rd=1",
    linkText: "How to find and remove duplicate keywords",
  },
  GOOGLE_ADS_MINIMUM_ADS_OF_TYPE_PER_AD_GROUP: {
    title: "Replace ETA ads with RSA ads",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Starting June 2022 Google deprecated the ad type Extended Text Ads (ETA). Existing ads will be allowed to continue to run, but new ads of this type can't be created. The new preferred format, Responsive Search Ads (RSA), will help Google discover the best ad version based on your audience's search terms. This can lead to a higher clickthrough rate on your ad.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ad groups" : "a new ad group"
      } to have too few RSA ads.`,
    linkUrl:
      "https://support.google.com/google-ads/answer/7684791?hl=en&ref_topic=3119117",
    linkText: "Learn more about ad structuring and Responsive search ads",
  },
  GOOGLE_ADS_ZERO_IMPRESSION_KEYWORDS: {
    title: "Remove keywords with zero impressions last 12 months",
    tag: RecommendationTag.KEYWORDS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "The more keywords you have in your campaigns, the harder they are to manage and review. To improve your account quality score and make your ad groups streamlined, find all the keywords bringing no traffic over the last 12 months, and remove them from your targeted keywords list.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new keywords" : "a new keyword"
      } to have zero impressions.`,
    linkUrl: "https://support.google.com/google-ads/answer/2454069?hl=en",
    linkText: "Learn how to filter keywords based on performance",
  },
  GOOGLE_ADS_BROAD_MATCH_TYPE_KEYWORD: {
    title: 'Apply either "phrase" or [Exact] match type to your keywords',
    tag: RecommendationTag.KEYWORDS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      'Keywords with Broad match type are more expensive and generally have a lower CTR and higher CPC than keywords using "phrase" or [Exact] match type. This is because broad keywords serve your ads on search queries that are only loosely related to your keyword. "Phrase" and [Exact] match types help narrow down which search queries your ads appear on so that you receive the most relevant traffic to your landing page.',
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new keywords" : "a new keyword"
      } to have broad match type.`,
    linkUrl: "https://www.storegrowers.com/keyword-match-types/",
    linkText: "Learn about keyword match types",
  },
  GOOGLE_ADS_CONVERSION_ACTIONS: {
    title: "Set up conversion actions in Google Ads",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Conversions are specific actions that you want your user to do once they land on your website. This can for example be download a specific file, purchase a product or subscribe to a newsletter. Adding conversions will help you manage your Google Ads campaigns more efficiently and based on a key metric. ",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " accounts" : "an account"
      } to be missing conversion actions.`,
    linkUrl: "https://support.google.com/google-ads/answer/1722022?hl=en",
    linkText: "Learn about conversion tracking",
  },
  GOOGLE_ADS_EXCLUDE_IRRELEVANT_SEARCH_TERMS: {
    title: "Exclude irrelevant search terms",
    tag: RecommendationTag.KEYWORDS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "In the Search Term report, you can tag irrelevant search terms with the tag 'Irrelevant'. To directly exclude these search terms from your Google Ads campaigns, you can enable this automation. By removing search terms that aren't relevant to your business, you can save money on clicks that are unlikely to convert, keeping your ads as relevant as possible. Excluding irrelevant keywords is a quick and effective way to improve the quality of your results.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new keywords" : "a new keyword"
      } to be tagged as irrelevant not excluded from your Google Ads account.`,
    linkUrl: "https://support.google.com/google-ads/answer/7102995",
    linkText: "Learn about adding negative keywords",
  },
  GOOGLE_ADS_SHARED_NEGATIVE_LIST: {
    title: "Apply shared negative keyword list to your search campaigns",
    tag: RecommendationTag.KEYWORDS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Negative keywords play a crucial role in maximizing the ROI of a Google Ads search campaign. It can improve the relevance of your ad groups, increase conversion rates, improve your clickthrough rates, save your business money and improve your return on ad spend. Using a shared negative list is good Google Ads practice, and the list should consist of generic words you would never want to trigger any of your ads.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new search campaigns" : "a new search campaign"
      } without a shared negative keyword list.`,
    linkUrl: "https://support.google.com/google-ads/answer/7519927?hl=en",
    linkText: "Learn about negative keyword lists",
  },
  GOOGLE_ADS_LIMITED_BY_BUDGET: {
    title: "Ensure that your top converting campaigns have enough budget",
    tag: RecommendationTag.BUDGETS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Paid search is all about being visible when customers are looking for your product or service. Some campaigns will generate more conversions simply because people are more interested in the product/service advertised through the campaign; we call these ‘top converting campaigns’. If your top converting campaigns are limited by budget you are missing out on “easy” conversions.",
    feedCardText: (count) =>
      `Found ${
        count > 1
          ? count + " new 'top converting' campaigns"
          : "a new 'top converting' campaign"
      } to be limited by budget.`,
  },
  GOOGLE_ADS_BRANDED_GENERIC_KEYWORD_MIX: {
    title:
      "Keep branded and generic keywords separate from one another in your campaigns",
    tag: RecommendationTag.KEYWORDS,
    channels: [RecommendationChannel.GOOGLE_ADS],
    description:
      "Your brand campaign(s) will perform quite differently from your non-brand campaigns because these users are already familiar with your company, products or services at some level. You’ll want to be able to budget, manage and report on brand separately from non-brand.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new campaigns" : "a new campaign"
      } to have a mix of branded and generic keyword.`,
    linkUrl: "https://support.google.com/analytics/answer/2531578?hl=en",
    linkText: "Learn more about balancing generic and branded keywords",
  },
  GOOGLE_ANALYTICS_4_TRACKING_CODE: {
    title: "Install Google Analytics 4 Tracking Code on your website",
    tag: RecommendationTag.TRACKING,
    channels: [RecommendationChannel.GOOGLE_ANALYTICS_4],
    description:
      "To accurately gather data about your website, you should implement Google Analytics Tracking Code. This will enable you to get insight and analyze your website traffic. Additional interactions like form completions, product purchases and more can also be tracked, making it possible to track conversions. This requires adding code to your website.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new accounts" : "a new account"
      } missing website data.`,
    linkUrl: "https://support.google.com/analytics/answer/1008080",
    linkText: "Learn more about setting up tracking code",
  },
  FACEBOOK_AD_SET_SIZE: {
    title: "Use 2 to 4 ads per ad set",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.FACEBOOK_ADS],
    description:
      "For Facebook's ad-matching algorithms to work optimally, they recommend using at least 2, but no more than 4 ads per ad set. If you need to create some more ads, use slightly different headlines, creatives and ad texts, in addition to the ones you're already using. This lets you see what message resonates best with your target audience. If you should lower your ad count, get rid of some low-performing ads.",
    feedCardText: (count) =>
      `Identified ${
        count > 1 ? count + " new ad sets" : "a new ad set"
      } with less than two or more than four ads.`,
    linkUrl: "https://www.facebook.com/business/help/2720085414702598",
    linkText: "Learn more about managing ad volume",
  },
  FACEBOOK_AD_FREQUENCY: {
    title: "Keep ad frequency below 3.4",
    tag: RecommendationTag.AUDIENCE,
    channels: [RecommendationChannel.FACEBOOK_ADS],
    description:
      "The cost for your Meta ads start to increase significantly around ad frequency 1.8. According to research; once the frequency reaches 3.4, ads reach a tipping point in effectiveness - and the costs start to climb faster than the returns. Usually, this happens due to having a narrow audience relative to the campaign's budget. It is then considered best practice to pause the ad set. To continue running the ads, duplicate the paused ad set and follow precautionary steps to ensure it doesn't keep happening to your new ad sets.",
    feedCardText: (count) =>
      `Identified ${
        count > 1 ? count + " new ad sets" : "an ad set"
      } with an ad frequency above 3.4.`,
    linkUrl: "https://www.facebook.com/business/help/265593457478252",
    linkText: "Create a rule to pause ad sets at a certain frequency",
  },
  FACEBOOK_MISSING_PIXEL_INSTALLATION: {
    title: "Install a Meta Pixel on your website",
    tag: RecommendationTag.TRACKING,
    channels: [RecommendationChannel.FACEBOOK_ADS],
    description:
      "To accurately gather data about your paid marketing from Facebook and Instagram, you should implement a Meta Pixel. This will enable you to get insight on the domain you're sending traffic to. Additional interactions like form completions, product purchases and more can also be tracked, making it possible to track conversions. This requires adding code to your website.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " Meta Pixels" : "a Meta Pixel"
      } missing page view data.`,
    linkUrl: "https://www.facebook.com/business/help/952192354843755",
    linkText: "How to create and install a Meta Pixel",
  },
  FACEBOOK_MISSING_UTM_TRACKING: {
    title: "Use tracking parameters in ad URLs",
    tag: RecommendationTag.TRACKING,
    channels: [RecommendationChannel.FACEBOOK_ADS],
    description:
      "By not utilizing tracking parameters, paid traffic from Facebook will appear as referral traffic, making it impossible to discover how many page views your ads are accountable for. Apply tracking parameters to enable more comprehensive analysis of the website traffic generated from your paid activities.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ads" : "a new ad"
      } missing tracking parameters.`,
    linkUrl: "https://www.facebook.com/business/help/1016122818401732",
    linkText: "How to add tracking parameters in your ads",
  },
  FACEBOOK_AD_TEXT_LENGTH: {
    title: "Shorten ad text to avoid truncating",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.FACEBOOK_ADS],
    description:
      "Facebook allocates a set number of characters to each text field in an ad. If the text is over these limits the text is truncated, and the user is either not able to read the full ad or has to click a separate button to reveal the rest of the ad. The ad's headline should be less then 40 characters and the primary text should be shorter than 125 characters. The description should generally be under 40 characters, unless its a carousel ad, then it should be under 20.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ads" : "a new ad"
      } with potentially truncated text.`,
    linkUrl: "https://www.facebook.com/business/ads-guide/image/facebook-feed",
    linkText: "Facebook's ad guide",
  },
  LINKED_IN_ADS_CAMPAIGN_SIZE: {
    title: "Ensure that you have at least 3 ads in your campaign",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.LINKED_IN_ADS],
    description:
      "We recommend creating 3-4 ad variations in a campaign to discover which creatives perform best.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new campaigns" : "a new campaign"
      } with less than 3 ads.`,
    linkUrl:
      "https://business.linkedin.com/marketing-solutions/success/best-practices/text-ads-tips",
    linkText: "LinkedIn ad creatives - Best Practices",
  },
  LINKED_IN_ADS_TARGETING_FOR_SMALL_AUDIENCE: {
    title: "Change campaign objective to Brand Awareness",
    tag: RecommendationTag.CAMPAIGN_SETTINGS,
    channels: [RecommendationChannel.LINKED_IN_ADS],
    description:
      'Use the campaign objective "Brand Awareness" for small target groups (less than 30000 people) to maximise views.  Even though you will be charged on a CPM-basis (cost per thousand impression), you tend to see a lower cost per click using this campaign objective for small target groups. Please note that you can\'t change the objective for a campaign that is already launched. Pause and duplicate the existing campaign to start using Brand Awareness as the campaign objective.',
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new campaigns" : "a new campaign"
      } with a too small audience.`,
    linkUrl:
      "https://business.linkedin.com/marketing-solutions/success/best-practices/choose-your-objective",
    linkText: "Learn more about campaign objectives",
  },
  LINKED_IN_ADS_URL_IN_AD_TEXT: {
    title: "Include URL in ad copy to boost CTR",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.LINKED_IN_ADS],
    description:
      "Adding a clickable URL to the ad copy for your sponsored content or message ads tends to improve an ad's CTR. Test by adding your landing page to your ad text. Worried about how your ad will look? LinkedIn will automatically shorten your URL, making it look short and sweet, and easy to read for your target audience.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ads" : "a new ad"
      } without a URL in the ad copy.`,
  },
  LINKED_IN_ADS_TEXT_LENGTH: {
    title: "Shorten ad text to avoid truncating",
    tag: RecommendationTag.ADS,
    channels: [RecommendationChannel.LINKED_IN_ADS],
    description:
      "LinkedIn allocates a set number of characters to each text field in an ad. If the text is over these limits the text is truncated, and the user is either not able to read the full ad or has to click a separate button to reveal the rest of the ad. The ad's headline should be less then 70 characters and the introductory text should be shorter than 150 characters.",
    feedCardText: (count) =>
      `Identified ${
        count > 1 ? count + " new ads" : "a new ad"
      } with potentially truncated text.`,
    linkUrl:
      "https://www.linkedin.com/help/lms/answer/a426534/single-image-ads-advertising-specifications?lang=en#:~:text=Description%3A%20Use%20approximately%20100%20characters,desktop%20rarely%20shows%20description%20text",
    linkText: "Advertising specifications for Single image Ads",
  },
  LINKED_IN_ADS_CAMPAIGN_CONVERSIONS: {
    title: "Add conversion actions to your LinkedIn campaigns.",
    tag: RecommendationTag.CAMPAIGN_SETTINGS,
    channels: [RecommendationChannel.LINKED_IN_ADS],
    description:
      "Configuring conversions actions to your campaigns will help you measure the efficiency of your campaigns, enabling you to be more data driven in your approach. Conversions are actions that you want your target audience to do once they land on your website, for example downloading a file, filling out a form, or purchasing a product.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new campaigns" : "a new campaign"
      } without conversion actions.`,
    linkUrl:
      "https://business.linkedin.com/marketing-solutions/conversion-tracking",
    linkText: "Learn about conversion tracking",
  },
  SEO_TITLE_ISSUE: {
    title: "Add or fix title tags",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "The title tag is the headline of the website, and is displayed in the website's tab in your browser. Title tags also appear on search result pages, and should be optimized to give a clear indication of what the page's content is about. The recommended length for a title tag is between 30 and 60 characters.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with missing or duplicate title tags.`,
    linkUrl:
      "https://developers.google.com/search/docs/beginner/seo-starter-guide#uniquepagetitles",
    linkText: "Learn more about title tags",
  },
  SEO_DESCRIPTION_ISSUE: {
    title: "Add or improve meta descriptions",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "The meta description is your opportunity to provide a brief summary of a page’s content. This text will be displayed on search result pages, but not directly influence how high you rank for a specific keyword. It may however influence users to click through to your website, so it is important to use a description text that sparks user’s interest for your website. The recommended length of a description is between 70 and 155 characters.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with missing or duplicate meta descriptions.`,
    linkUrl:
      "https://developers.google.com/search/docs/beginner/seo-starter-guide#descriptionmeta",
    linkText: "Learn more about meta description tags",
  },
  SEO_HEADING_ISSUE: {
    title: "Structure page headings",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "Heading tags come in sizes h1 to h6, and work as headlines on your website. The sizes you choose should depend on your website's text structure, rather than its design. Heading tags are not only important for the user, but search engines and screen readers interpret these tags and emphasize them accordingly. For that reason it's important to have one unique h1-tag per page. Please note that this recommendation only includes tasks related to the h1-tag as of 08.11.2022.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with missing or duplicate h1-tags.`,
    linkUrl:
      "https://developers.google.com/search/docs/beginner/seo-starter-guide#headingtags",
    linkText: "Learn more about heading tags",
  },
  SEO_INDEXABILITY_ISSUE: {
    title: "Improve page indexability",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "Make sure that you only include the noindex content attribute in meta tags on pages you don't want to appear in search results. If one was mistakenly added to a page, remove it, and the next time a search engine crawls your page, it will pick up on the change.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with noindex meta tags.`,
  },
  SEO_OPEN_GRAPH_ISSUE: {
    title: "Add Open Graph data",
    tag: RecommendationTag.SOCIAL_MEDIA,
    channels: [RecommendationChannel.SEO],
    description:
      "Open Graph data ensures that your content is properly displayed when links are shared in Facebook's channels, such as on facebook.com or in the Messenger app.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with missing Open Graph data.`,
  },
  SEO_IMAGE_ISSUE: {
    title: "Improve image accessibility",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "Images are a crucial part of websites. They can be used to illustrate concepts, or convince a user to buy a certain product. To make sure screen readers and search engines can get information out of images, add descriptive alt attributes to all images used for content. If an image is included just to be decorative, add the alt attribute, but leave it empty. This will ensure that screen readers skip the image.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with missing image alt attributes.`,
    linkUrl:
      "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#accessibility_concerns",
    linkText: "Learn more about image alt attributes",
  },
  SEO_REDIRECT_ISSUE: {
    title: "Optimize redirect URLs",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "Redirecting URLs is the process of passing traffic meant for one destination through to another. This is typically done if content has been moved to a new URL. The main reasons for using redirects are to give your visitors a better user experience by sending them to the content's new destination seamlessly, and to help search engines better understand your site by telling them where content has moved, and whether the move is permanent or temporary. For internal links it's recommended to avoid redirects, and to update your links to point to the correct final destination URLs.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with internal redirect URLs.`,
  },
  SEO_LINK_ISSUE: {
    title: "Fix or remove broken links",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "Ensuring your website's links are working is important. Users encountering broken links are prone to become frustrated and leave your website, while search engines can give up on indexing sites with too many errors, thus reducing their chances of showing up on the search results page.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with broken links.`,
  },
  SEO_CANONICAL_ISSUE: {
    title: "Fix internal links to non-canonical URLs",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      'Google uses a variation of factors to determine what the canonical URL should be, not only the "rel canonical" tag. One of these factors is internal links. For that reason, you should avoid internal links to the non-canonical variant of a page, since this might effect which URL Google chooses as the canonical version.',
    feedCardText: (count) =>
      `Found ${count > 1 ? count + " new pages" : "a new page"} with ${
        count > 1 ? " " : "a "
      }canonical URL issue${count > 1 ? "s" : ""}.`,
  },
  SEO_TEXT_ISSUE: {
    title: "Keep word count above 200 words",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "Generally speaking, the higher the word count, the better the opportunity to answer the user's search intent. Pages with less than 200 words are considered pages with low word count, and you should try to keep the word count above 200. But bear in mind; if you can't add to the page with quality content, it's better to leave the page as is and dismiss this recommendation.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new pages" : "a new page"
      } with low word count.`,
  },
  ACTIVE_SOCIAL_MEDIA_ACCOUNT: {
    title: "Maintain an active presence on social media",
    tag: RecommendationTag.SOCIAL_MEDIA,
    channels: [
      RecommendationChannel.FACEBOOK_ORGANIC,
      RecommendationChannel.LINKED_IN_ORGANIC,
      RecommendationChannel.INSTAGRAM_ORGANIC,
      RecommendationChannel.YOUTUBE,
      RecommendationChannel.TIK_TOK_ORGANIC,
    ],
    description:
      "Consider publishing some content on your social media accounts to keep your business page active. Organic posts do not cost anything to publish, and can help you maintain an active following.",
    feedCardText: (count) =>
      `Activity should be increased in <span class="italic">${count}</span> of your social media channels.`,
  },
  DEAD_LINK_CHECKER: {
    title: "Pause ads using landing pages that don't work",
    tag: RecommendationTag.ADS,
    channels: [
      RecommendationChannel.GOOGLE_ADS,
      RecommendationChannel.FACEBOOK_ADS,
      RecommendationChannel.LINKED_IN_ADS,
      RecommendationChannel.XANDR,
      RecommendationChannel.SNAPCHAT_ADS,
      RecommendationChannel.BING_ADS,
      RecommendationChannel.TIK_TOK_ADS,
    ],
    description:
      "Using landing pages that don't work in your ads will lead to a poor user experience, limit your chances of converting the user into a sale and waste your marketing budget. Landing pages should have the HTTP 200 OK success status response code.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " new ads" : "a new ad"
      } with broken landing pages.`,
  },
  RENEW_SSL_CERTIFICATE: {
    title: "Renew SSL certificate",
    tag: RecommendationTag.WEBSITE,
    channels: [RecommendationChannel.SEO],
    description:
      "SSL, also referred to as TLS, verifies your website to web browsers enabling encryption of traffic between the browser and server. This certificate is the reason why your website is on the HTTPs protocol, and not the unsecured HTTP. If your certificate is not renewed your website will be flagged as not secure by the major web browsers and might lead to users being warned about entering the site.  ",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " domains" : "a domain"
      } with a SSL certificates about to expire.`,
  },
  SOFT_404: {
    title: "Add proper error pages",
    tag: RecommendationTag.SEO,
    channels: [RecommendationChannel.SEO],
    description:
      "A soft 404 error happens when a page that doesn't exist (has been removed or redirected) displays a ‘page not found’ message to the user, but fails to return an HTTP 404 status code. Soft 404s also happen when pages are redirected to non-relevant pages, for example a page redirecting to the home page instead of returning a 404 status code or redirecting to a relevant page.",
    feedCardText: (count) =>
      `Found ${
        count > 1 ? count + " domains" : "a domain"
      } with soft 404 errors.`,
  },
  ADDRESS_POLICY_VIOLATIONS: {
    title: "Address policy violations",
    tag: RecommendationTag.ACCOUNT_STRUCTURE,
    channels: [
      RecommendationChannel.GOOGLE_ADS,
      // RecommendationChannel.BING_ADS,
      // RecommendationChannel.FACEBOOK_ADS,
      // RecommendationChannel.SNAPCHAT_ADS,
      // RecommendationChannel.LINKED_IN_ADS,
    ],
    description:
      "Some of your campaigns are breaching policies. You should either remove your campaign, edit and resubmit your campaign or appeal the decision if you believe your campaign has been disapproved by mistake.",
    feedCardText: (count) =>
      `Found ${count} instances of disapproved keywords, extensions or ads in your running campaigns.`,
  },
};

export function getRecommendationTypeConfigurationWarnings(
  organization: Organization,
  recommendationType: RecommendationType,
): string[] {
  const warnings = [];
  if (
    organization.scrapingIgnoreQueryParams &&
    recommendationType === RecommendationType.SEO_LINK_ISSUE
  ) {
    warnings.push(
      "Your sites are being scraped ignoring query parameters. We are unable to analyze links with query parameters for link issues.",
    );
  }
  return warnings;
}

export const iconByRecommendationChannel = {
  [RecommendationChannel.FACEBOOK_ADS]: {
    name: "facebook",
    color: "text-facebook-blue",
  },
  [RecommendationChannel.GOOGLE_ADS]: { name: "google-ads", color: "" },
  [RecommendationChannel.GOOGLE_ANALYTICS]: {
    name: "google-analytics",
    color: "",
  },
  [RecommendationChannel.GOOGLE_ANALYTICS_4]: {
    name: "google-analytics",
    color: "",
  },
  [RecommendationChannel.GOOGLE_SEARCH]: {
    name: "google-search-console",
    color: "",
  },
  [RecommendationChannel.YOUTUBE]: {
    name: "youtube",
    color: "text-youtube-red",
  },
  [RecommendationChannel.LINKED_IN_ADS]: {
    name: "linked-in",
    color: "text-linkedin-blue",
  },
  [RecommendationChannel.SEO]: { name: "browser", color: "" },
  [RecommendationChannel.FACEBOOK_ORGANIC]: {
    name: "facebook",
    color: "text-facebook-blue",
  },
  [RecommendationChannel.LINKED_IN_ORGANIC]: {
    name: "linked-in",
    color: "text-linkedin-blue",
  },
  [RecommendationChannel.INSTAGRAM_ORGANIC]: { name: "instagram", color: "" },
  [RecommendationChannel.XANDR]: { name: "xandr", color: "text-xandr-red" },
  [RecommendationChannel.SNAPCHAT_ADS]: { name: "snapchat-ghost", color: "" },
  [RecommendationChannel.BING_ADS]: { name: "bing", color: "text-bing-teal" },
  [RecommendationChannel.TIK_TOK_ADS]: {
    name: "tik-tok",
    color: "text-black",
  },
  [RecommendationChannel.TIK_TOK_ORGANIC]: {
    name: "tik-tok",
    color: "text-black",
  },
} as const;

export const iconByRecommendationTag = {
  [RecommendationTag.ACCOUNT_STRUCTURE]: "align-left",
  [RecommendationTag.CAMPAIGN_SETTINGS]: "edit",
  [RecommendationTag.BIDS]: "money-bill-wave",
  [RecommendationTag.BUDGETS]: "chart-pie",
  [RecommendationTag.KEYWORDS]: "tag",
  [RecommendationTag.ADS]: "ad-solid",
  [RecommendationTag.AD_EXTENSIONS]: "file-alt",
  [RecommendationTag.AUTOMATION]: "cogs",
  [RecommendationTag.TRACKING]: "route",
  [RecommendationTag.AUDIENCE]: "users",
  [RecommendationTag.SEO]: "search-dollar",
  [RecommendationTag.SOCIAL_MEDIA]: "comment-dots",
  [RecommendationTag.WEBSITE]: "browser",
} as const;

export const colorByRecommendationTag: Record<RecommendationTag, Color> = {
  [RecommendationTag.ACCOUNT_STRUCTURE]: Color.ORANGE,
  [RecommendationTag.CAMPAIGN_SETTINGS]: Color.ORANGE,
  [RecommendationTag.BIDS]: Color.ORANGE,
  [RecommendationTag.BUDGETS]: Color.ORANGE,
  [RecommendationTag.KEYWORDS]: Color.ORANGE,
  [RecommendationTag.ADS]: Color.ORANGE,
  [RecommendationTag.AD_EXTENSIONS]: Color.ORANGE,
  [RecommendationTag.AUTOMATION]: Color.BLUE,
  [RecommendationTag.TRACKING]: Color.PURPLE,
  [RecommendationTag.AUDIENCE]: Color.ORANGE,
  [RecommendationTag.SEO]: Color.TURQUOISE,
  [RecommendationTag.SOCIAL_MEDIA]: Color.FUCHSIA,
  [RecommendationTag.WEBSITE]: Color.TURQUOISE,
};

export const resourceChannelByRecommendationChannel: Record<
  RecommendationChannel,
  ResourceChannel | null
> = {
  [RecommendationChannel.GOOGLE_ADS]: ResourceChannel.GOOGLE_ADS,
  [RecommendationChannel.FACEBOOK_ADS]: ResourceChannel.FACEBOOK_ADS,
  [RecommendationChannel.GOOGLE_SEARCH]: ResourceChannel.GOOGLE_SEARCH_CONSOLE,
  [RecommendationChannel.GOOGLE_ANALYTICS]: ResourceChannel.GOOGLE_ANALYTICS,
  [RecommendationChannel.GOOGLE_ANALYTICS_4]:
    ResourceChannel.GOOGLE_ANALYTICS_4,
  [RecommendationChannel.YOUTUBE]: ResourceChannel.YOUTUBE,
  [RecommendationChannel.LINKED_IN_ADS]: ResourceChannel.LINKED_IN_ADS,
  [RecommendationChannel.SEO]: null,
  [RecommendationChannel.FACEBOOK_ORGANIC]: ResourceChannel.FACEBOOK_ORGANIC,
  [RecommendationChannel.LINKED_IN_ORGANIC]: ResourceChannel.LINKED_IN_ORGANIC,
  [RecommendationChannel.INSTAGRAM_ORGANIC]: ResourceChannel.INSTAGRAM_ORGANIC,
  [RecommendationChannel.XANDR]: ResourceChannel.XANDR,
  [RecommendationChannel.SNAPCHAT_ADS]: ResourceChannel.SNAPCHAT_ADS,
  [RecommendationChannel.BING_ADS]: ResourceChannel.BING_ADS,
  [RecommendationChannel.TIK_TOK_ADS]: ResourceChannel.TIK_TOK_ADS,
  [RecommendationChannel.TIK_TOK_ORGANIC]: ResourceChannel.TIK_TOK_ORGANIC,
};
