<template>
  <Popover class="relative">
    <PopoverButton
      class="rounded-full border-2 border-transparent p-1 text-purple-100 hover:text-white focus:bg-burgundy-700 focus:text-gray-200 focus:outline-none"
      aria-label="Notifications"
    >
      <Icon name="bell" class="w-5" />
      <div
        v-if="hasUnreadNotifications"
        class="absolute right-0.5 top-0.5 size-2 rounded-full bg-red-600"
      />
      <div
        v-if="hasUnreadNotifications"
        class="absolute right-0.5 top-0.5 size-2 animate-ping rounded-full bg-red-600"
      />
      <span v-if="hasUnreadNotifications" class="sr-only"
        >Unread notifications</span
      >
    </PopoverButton>
    <Transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute left-0 top-0 z-10 mt-12 flex min-w-120 flex-col rounded border border-gray-300 bg-white shadow-lg md:left-auto md:right-0"
      >
        <h1 class="border-b-2 border-gray-200 px-4 py-2 text-lg font-bold">
          Notifications
        </h1>
        <NotificationWrapper
          v-for="notification in notifications.data?.slice(0, 5)"
          :key="notification.pk"
          :notification="notification"
          @close="close"
        />
        <div v-if="notifications.data?.length === 0" class="p-4 text-gray-700">
          <p class="font-semibold">No new notifications</p>
          <p>You're all caught up!</p>
        </div>
        <div class="border-t-2 border-gray-200 text-center">
          <button
            class="size-full rounded-b-md py-3 text-sm leading-none text-purple-600 transition duration-300 hover:bg-purple-100 hover:text-purple-700 focus:bg-purple-100 focus:text-purple-700 focus:outline-none"
            @click="navigateToNotifications(close)"
          >
            See all notifications
            <span v-if="unreadNotifications?.length">
              ({{ unreadNotifications.length }} unread)
            </span>
          </button>
        </div>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverPanel, PopoverButton } from "@headlessui/vue";
import Icon from "@/components/Core/Icon.vue";
import NotificationWrapper from "@/components/Core/Notifications/NotificationWrapper.vue";
import { useRouter } from "vue-router";
import { useNotificationsQuery } from "@/services/notifications";
import { computed } from "vue";

const router = useRouter();
const notifications = useNotificationsQuery();

const hasUnreadNotifications = computed(() => {
  return notifications.data?.some((n) => n.readStatus === "UNREAD") ?? false;
});
const unreadNotifications = computed(() => {
  return notifications.data?.filter((n) => n.readStatus === "UNREAD");
});
function navigateToNotifications(close: () => void): void {
  router.push({ name: "notifications" });
  close();
}
</script>
