import { MarketingMetric } from "@/services/marketing";

export const MARKETING_METRIC_SET_NAMES = [
  "performance",
  "results",
  "social",
  // "keyword",
] as const;
export type MarketingMetricSetName =
  (typeof MARKETING_METRIC_SET_NAMES)[number];

export const MARKETING_METRIC_SETS = {
  performance: [
    "impressions",
    "clicks",
    "clickthroughRate",
    "costPerClick",
    "conversions",
    "cost",
    "costPerConversion",
    "costPerMilli",
    "totalClicks",
    "value",
    "returnOnAdSpend",
    "returnOnAdSpendRatio",
  ],
  results: [
    // "revenue",
    "returnOnAdSpend",
    "returnOnAdSpendRatio",
    // "roi",
    "conversions",
    "costPerMilli",
    "costPerConversion",
    // "transactions",
  ],
  // website: [
  //   "avgSessionDuration",
  //   "bounceRate",
  //   "pagesPerSession",
  //   "revenue",
  //   "transactions",
  // ],
  social: [
    "shares",
    "comments",
    "engagement",
    "impressions",
    "clicks",
    "totalClicks",
    "clickthroughRate",
  ],
  // keyword: [
  //   "clicks",
  //   "impressions",
  //   "clickthroughRate",
  //   "maxCostPerClick",
  //   "estimatedFirstPositionBid",
  //   "conversions",
  //   "conversionRate",
  //   "costPerConversion",
  //   "expectedClickthroughRate",
  //   "landingPageExperience",
  //   "qualityScore",
  //   "impressions",
  // ],
} as const satisfies Record<MarketingMetricSetName, MarketingMetric[]>;
