import numeral from "numeral";

export function formatDuration(
  value: number | null | undefined,
  valueFormat: "ms" | "s" | "m" | "h" | "d" = "s",
  nullCase = "-",
): string {
  if (value === undefined) return "0s";
  if (value === null) return nullCase;

  const valueInSeconds = {
    ms: value / 1000,
    s: value,
    m: value * 60,
    h: value * 3600,
    d: value * 3600 * 24,
  }[valueFormat];

  const [hours, minutes, seconds] = numeral(valueInSeconds)
    .format("00:00:00")
    .split(":");

  // dont show "00h 00m"
  const h = Number(hours) ? hours + "h" : "";
  const m = Number(minutes) ? minutes + "m" : "";
  const s = Number(seconds) ? seconds + "s" : "0s";
  return `${h} ${m} ${s}`;
}
