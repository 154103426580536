import { TimeUnit } from "@/services/data_filters";
import { useIntervalFn } from "@vueuse/core";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { Ref, ref } from "vue";

export const DATE_MED_SHORT = Object.assign({}, DateTime.DATE_MED);
delete DATE_MED_SHORT.year;

export const DATE_MED_SHORT_WITH_WEEKDAY = Object.assign(
  {},
  DateTime.DATE_MED_WITH_WEEKDAY,
);
delete DATE_MED_SHORT_WITH_WEEKDAY.year;

export function isThisYear(date: Date): boolean {
  return date.getFullYear() === new Date().getFullYear();
}
export function isThisMonth(date: Date): boolean {
  return isThisYear(date) && date.getMonth() === new Date().getMonth();
}

export function isSameDate(
  a: Date | number,
  b: Date | number,
  year?: number,
  month?: number,
): boolean {
  let aDate: Date;
  let bDate: Date;

  if (typeof a === "number" && typeof b === "number") {
    if (year && month) {
      aDate = new Date(year, month, a);
      bDate = new Date(year, month, b);
    } else {
      throw Error(
        "If input dates are numbers, you must also supply year and month.",
      );
    }
  } else {
    aDate = a as Date;
    bDate = b as Date;
  }

  return (
    aDate.getFullYear() === bDate.getFullYear() &&
    aDate.getMonth() === bDate.getMonth() &&
    aDate.getDate() === bDate.getDate()
  );
}
export function isoStringDayDifference(start: string, end: string): number {
  const startDt = DateTime.fromISO(start);
  const endDt = DateTime.fromISO(end);
  const diff = endDt.diff(startDt, "days").days + 1;
  return diff;
}

export function isToday(
  year: number,
  month: number,
  date: Date | number,
): boolean {
  const today = new Date();
  if (typeof date === "number") {
    return isSameDate(today, new Date(year, month, date));
  }
  return isSameDate(today, date);
}

function getTime(date: DateTime): string {
  return date.toLocaleString(DateTime.TIME_SIMPLE);
}

export function getTimeSince(
  date: string,
  maxHours = 12,
  useMinutes = false,
): string {
  const dt = DateTime.fromISO(date);
  const dtNow = DateTime.local();
  if (useMinutes) {
    const minutesSince = Math.abs(dt.diff(dtNow, "minutes").minutes);
    if (minutesSince <= 1) return "Now";
    if (minutesSince <= 5) return "A few minutes ago";
    if (minutesSince < 60) return `${Math.trunc(minutesSince)} minutes ago`;
  }

  const hoursSince = Math.abs(dt.diff(dtNow, "hours").hours);
  if (hoursSince < 1) return "Less than an hour ago";
  if (hoursSince <= maxHours && dtNow.hasSame(dt, "day")) {
    const roundedHours = Math.round(hoursSince);
    return roundedHours === 1 ? "An hour ago" : `${roundedHours} hours ago`;
  }

  const daysSince = Math.abs(dt.diff(dtNow, "days").days);
  if (daysSince <= 2) {
    if (dtNow.hasSame(dt, "day")) return `Today, ${getTime(dt)}`;
    if (dtNow.minus({ days: 1 }).hasSame(dt, "day")) {
      return `Yesterday, ${getTime(dt)}`;
    }
  }
  if (Math.round(daysSince) <= 6) {
    return `${dt.setLocale("en-us").weekdayLong}, ${getTime(dt)}`;
  }

  return dt.toLocaleString(DateTime.DATETIME_MED);
}

// Note: Argument changes to ISO date string instead of Date because getTimeSince converts it to a DateTime
// But you can update that function to support taking in a DateTime instead

export function useDynamicTimeSince(date: Ref<string>, updateMs = 10000) {
  const timeSince = ref("");

  useIntervalFn(() => {
    if (date.value) {
      timeSince.value = getTimeSince(date.value, 24, true);
    }
  }, updateMs);

  return timeSince;
}

export function getFormattedDate(
  d: DateTime | string,
  timeUnit: TimeUnit = TimeUnit.DAY,
  includeYear?: boolean,
): string {
  if (timeUnit === TimeUnit.WEEKDAY && typeof d === "string") {
    return d;
  } else if (typeof d === "string") {
    d = DateTime.fromISO(d);
  }

  let format: DateTimeFormatOptions | string = DATE_MED_SHORT_WITH_WEEKDAY;
  if (includeYear) {
    switch (timeUnit) {
      case TimeUnit.DAY:
        format = DateTime.DATE_MED_WITH_WEEKDAY;
        break;
      case TimeUnit.WEEK:
        format = "'Week' W, yyyy";
        break;
      case TimeUnit.MONTH:
        format = "LLL. yyyy";
        break;
      case TimeUnit.WEEKDAY:
        format = { weekday: "long" };
        break;
      default:
        format = DateTime.DATE_MED_WITH_WEEKDAY;
        break;
    }
  } else {
    switch (timeUnit) {
      case TimeUnit.DAY:
        format = DATE_MED_SHORT_WITH_WEEKDAY;
        break;
      case TimeUnit.WEEK:
        format = "'Week' W";
        break;
      case TimeUnit.MONTH:
        format = "LLL. yyyy";
        break;
      case TimeUnit.WEEKDAY:
        format = { weekday: "long" };
        break;
      default:
        format = DATE_MED_SHORT_WITH_WEEKDAY;
        break;
    }
  }

  if (typeof format === "string") {
    return d.toFormat(format);
  }
  return d.toLocaleString(format);
}
