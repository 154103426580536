import { CompetitorPost } from "@/services/competitor_monitoring";
import { Post } from "@/services/social_media";

export function isOrganizationPost(post: Post | CompetitorPost): post is Post {
  return "resource" in post;
}
export function isCompetitorPost(
  post: Post | CompetitorPost,
): post is CompetitorPost {
  return "competitor" in post;
}
