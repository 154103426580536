import { Tier } from "@/services/organization";
import { RouteRecordRaw } from "vue-router";
import { RecommendationType } from "@/services/recommendations/recommendations";
import { toURLParam } from "@/utils/recommendations";

const validRecommendationTypeParams = Object.values(RecommendationType).map(
  (recommendationType) => toURLParam(recommendationType),
);

export const recommendationTypeRoute: RouteRecordRaw = {
  path: `recommendation/:type(${validRecommendationTypeParams.join("|")})`,
  name: "recommendation-details",
  meta: { title: "Recommendation Details", text: "Recommendations" },
  component: () => import("@/views/Recommendations/RecommendationTaskPage.vue"),
};

export const insightsRoutes: RouteRecordRaw[] = [
  {
    path: "recommendations",
    name: "recommendations",
    redirect: { name: "all-recommendations" },
    meta: {
      title: "Recommendations",
      text: "Recommendations",
      updated: "2022-01-01",
      icon: "lightbulb-on",
      description:
        "Discover actionable improvements in your digital marketing. Employ Fyr to make the necessary changes",
    },
    component: () => import("@/views/Recommendations/Overview.vue"),
    children: [
      {
        path: " ",
        name: "all-recommendations",
        meta: { title: "Recommendations" },
        component: () =>
          import("@/views/Recommendations/RecommendationsList.vue"),
      },
      {
        path: "automations",
        name: "automations",
        meta: { title: "Automations" },
        component: () => import("@/views/Recommendations/AutomationsList.vue"),
      },
    ],
  },
  {
    path: "competitor-monitor",
    name: "competitor-monitor",
    redirect: { name: "competitor-overview" },
    meta: {
      title: "Competitor Monitoring",
      requiredTier: Tier.LARGE,
      gtmEventCategory: "Reporting - Competitor monitor",
      text: "Competitor Monitoring",
      description: "Spy on what your competitors are doing on social media",
      updated: "2023-06-14",
      icon: "user-secret",
    },
    component: () =>
      import("@/views/CompetitorMonitor/CompetitorMonitorBase.vue"),
    children: [
      {
        path: "overview",
        name: "competitor-overview",
        meta: { title: "Competitor Overview" },
        component: () =>
          import("@/views/CompetitorMonitor/CompetitorOverviewTab.vue"),
      },
      {
        path: "posts",
        children: [
          {
            path: "",
            name: "competitor-posts",
            meta: { title: "Competitor Posts" },
            component: () =>
              import("@/views/CompetitorMonitor/CompetitorPostsTab.vue"),
          },
          {
            path: ":id",
            name: "competitor-post",
            meta: { title: "Competitor Post" },
            component: () =>
              import("@/views/CompetitorMonitor/CompetitorSinglePostView.vue"),
          },
        ],
      },

      {
        path: "competitors",
        name: "competitors",
        meta: { title: "Competitors" },
        component: () => import("@/views/CompetitorMonitor/CompetitorsTab.vue"),
      },
      {
        path: "share-of-search",
        name: "share-of-search",
        meta: {
          title: "Share of Search",
        },
        component: () =>
          import("@/views/CompetitorMonitor/ShareOfSearchTab.vue"),
      },
    ],
  },
];
