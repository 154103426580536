import { TimeUnit } from "@/services/data_filters";
import { DateTime } from "luxon";
import { defineStore } from "pinia";

export const useAnnotationsStore = defineStore("annotations", {
  state: () => ({
    clickedDate: "",
    timeUnit: null as TimeUnit | null,
  }),
  actions: {
    setDate(date: DateTime | null, timeUnit: TimeUnit | null): void {
      // "YYYY-MM-DD" format - called by graph node click function
      this.clickedDate = date?.toISODate() ?? "";
      this.timeUnit = timeUnit;
    },
  },
});
