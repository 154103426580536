import { MARKETING_RESOURCE_CHANNELS } from "@/services/marketing";
import { ResourceChannel } from "@/services/resources";
import { SOCIAL_MEDIA_RESOURCE_CHANNELS } from "@/services/social_media";
import { RouteRecordRaw } from "vue-router";

export const marketingRoutes: RouteRecordRaw[] = [
  {
    path: "goals",
    name: "goals",
    redirect: { name: "goals-overview" },
    meta: {
      title: "Goals",
      text: "Goals",
      description: "Keep track of progress towards your business goals",
      added: "2021-03-15",
      icon: "bullseye-arrow",
      requiredResources: [ResourceChannel.GOOGLE_ANALYTICS_4],
    },
    component: () => import("@/views/Goals/GoalsBase.vue"),
    children: [
      {
        path: "overview",
        name: "goals-overview",
        meta: { title: "Goals overview" },
        component: () => import("@/views/Goals/Goals.vue"),
      },
      {
        path: ":pk",
        name: "goal-details",
        meta: { title: "Goal Details" },
        component: () => import("@/views/Goals/GoalDetails.vue"),
      },
    ],
  },
  {
    path: "ad-performance",
    name: "ad-performance-base",
    redirect: { name: "ad-performance-overview" },
    meta: {
      title: "Ad Performance",
      text: "Ad Performance",
      description: "Stay on top of all your digital marketing campaigns",
      icon: "ad-solid",
      updated: "2024-05-24",
      requiredResources: MARKETING_RESOURCE_CHANNELS,
      helpDeskPath: "marketing-performance",
    },
    component: () =>
      import("@/views/MarketingPerformance/MarketingPerformanceBase.vue"),
    children: [
      {
        path: "overview",
        name: "ad-performance-overview",
        meta: { title: "Ad Performance" },
        component: () =>
          import(
            "@/views/MarketingPerformance/MarketingPerformanceOverviewTab.vue"
          ),
      },
      {
        path: "ads",
        name: "marketing-performance-ads",
        meta: { title: "Ads" },
        component: () =>
          import("@/views/MarketingPerformance/MarketingPerformanceAdsTab.vue"),
      },
    ],
  },
  {
    path: "website-analytics",
    name: "website-analytics",
    redirect: { name: "website-analytics-acquisition-tab" },
    meta: {
      title: "Website Analytics Report",
      updated: "2022-12-16",
      text: "Website Analytics",
      description:
        "Unlock comprehensive website analytics, including SEO and traffic, all in one place",
      icon: "chart-box",
      helpDeskPath: "website-analytics",
    },
    component: () =>
      import("@/views/WebsiteAnalytics/WebsiteAnalyticsReportView.vue"),
    children: [
      {
        path: "acquisition",
        name: "website-analytics-acquisition-tab",
        meta: { title: "Acquisition | Website Analytics" },
        component: () =>
          import("@/views/WebsiteAnalytics/WebsiteAnalyticsAcquisitionTab.vue"),
      },
      {
        path: "pages",
        name: "website-analytics-pages-tab",
        meta: { title: "Pages | Website Analytics" },
        component: () =>
          import("@/views/WebsiteAnalytics/WebsiteAnalyticsPagesTab.vue"),
      },
      {
        path: "conversions",
        name: "website-analytics-conversions-tab",
        meta: { title: "Conversions | Website Analytics" },
        component: () =>
          import("@/views/WebsiteAnalytics/WebsiteAnalyticsConversionsTab.vue"),
      },
      {
        path: "conversions/:pk",
        name: "website-analytics-conversion-details",
        meta: { title: "Conversion Details | Website Analytics" },
        component: () =>
          import(
            "@/views/WebsiteAnalytics/WebsiteAnalyticsConversionDetails.vue"
          ),
      },
    ],
  },
  {
    path: "ecommerce",
    name: "ecommerce",
    meta: {
      title: "E-commerce report",
      text: "E-commerce",
      description:
        "Track your webshop activity and get a better understanding of what drives your sales",
      icon: "shopping-cart",
      added: "2021-12-16",
      requiredResources: [ResourceChannel.GOOGLE_ANALYTICS_4],
    },
    component: () => import("@/views/ECommerce/ECommerceReport.vue"),
  },
  {
    path: "social-media",
    name: "social-media",
    redirect: { name: "social-media-account-summary" },
    meta: {
      title: "Social Media",
      text: "Social Media",
      gtmEventCategory: "Reporting - Social media",
      icon: "thumbs-up",
      description: "Get on top of your social media efforts",
      added: "2021-06-10",
      requiredResources: SOCIAL_MEDIA_RESOURCE_CHANNELS,
      helpDeskPath: "social-media",
    },
    component: () => import("@/views/SocialMedia/SocialMediaReport.vue"),
    children: [
      {
        path: "account-summary",
        name: "social-media-account-summary",
        meta: { title: "Social Media Account Summary" },
        component: () =>
          import("@/views/SocialMedia/SocialMediaAccountTab.vue"),
      },
      {
        path: "posts",
        name: "posts",
        children: [
          {
            path: "",
            name: "social-media-posts",
            meta: { title: "Social Media Posts" },
            component: () =>
              import("@/views/SocialMedia/SocialMediaPostsTab.vue"),
          },
          {
            path: ":id",
            name: "social-media-post",
            meta: { title: "Social Media Post" },
            component: () =>
              import("@/views/SocialMedia/SocialMediaSinglePostView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "search-term-report",
    name: "search-term-report-base",
    redirect: { name: "search-term-report" },
    meta: {
      title: "Search Term Report",
      text: "Search Term Report",
      icon: "file-search",
      description: "Discover keywords people use to find your business online",
      updated: "2021-04-20",
      helpDeskPath: "search-term-report",
    },
    children: [
      {
        path: "",
        name: "search-term-report",
        component: () =>
          import("@/views/SearchTermReport/SearchTermReport.vue"),
      },
      {
        path: ":id",
        name: "search-term-detail",
        meta: {
          title: "Search Term Details",
          requiredResources: [
            ResourceChannel.GOOGLE_ADS,
            ResourceChannel.BING_ADS,
            ResourceChannel.GOOGLE_SEARCH_CONSOLE,
          ],
        },
        component: () =>
          import("@/views/SearchTermReport/SearchTermDetailReport.vue"),
      },
    ],
  },
];
