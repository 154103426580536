<template>
  <BasePopupMessage :show="!dismissed">
    <div class="size-full p-4">
      <div class="flex">
        <Icon
          :name="
            notice.level === Level.WARNING
              ? 'exclamation-circle-solid'
              : 'info-circle-solid'
          "
          class="inline-flex size-10 shrink-0 items-start"
          :class="{
            'text-blue-500': notice.level === Level.INFO,
            'text-red-600': notice.level === Level.WARNING,
          }"
        />
        <div class="flex-1 items-start">
          <h2 class="ml-3 mt-1 text-sm font-bold text-gray-700">
            {{ notice.title }}
          </h2>

          <p class="ml-3 mt-1 text-sm text-gray-700">
            {{ notice.content }}
          </p>
        </div>
        <div class="ml-4 shrink-0">
          <button
            class="text-gray-400 hover:text-gray-500"
            @click="dismissed = true"
          >
            <span class="sr-only">Close</span>
            <Icon name="times" class="size-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </BasePopupMessage>
</template>

<script setup lang="ts">
import { Level } from "@/services/notices";
import Icon from "@/components/Core/Icon.vue";
import BasePopupMessage from "@/components/Core/PopupMessages/BasePopupMessage.vue";
import { PropType, ref } from "vue";
import { Notice } from "@/services/notices";

defineProps({
  notice: {
    type: Object as PropType<Notice>,
    required: true,
  },
});

const dismissed = ref(false);
</script>
