import api from "@/services/api";
import { ResourceChannel } from "@/services/resources";
import {
  TemporalDataFilter,
  ResourcesDatafilter,
  TimeUnit,
  AdNetworkFilter,
  TagsDataFilter,
  MarketingHierarchyTagFilter,
  SeparateResourcesFilter,
  MarketingHierarchySelectedEntityFilter,
  payloadToQueryParams,
  DateRangeSelection,
  PKFilter,
  ConversionFilter,
  TemporalPeriodsType,
} from "@/services/data_filters";
import {
  useCalculatedResources,
  useConversionGroups,
  useConversions,
  useDataFiltersStore,
  useDetachedTemporalFilter,
  useDataFilters,
  useDetachedTagFilter,
} from "@/store/data_filters";
import { computed, reactive, Ref, ref, toRefs } from "vue";
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import { useResetPage } from "@/services/utils";
import { ResponseTimeSeriesDatasets } from "@/utils/datasets";
import { QueryFunctionContext } from "@/utils/typing";
import { reactiveComputed } from "@vueuse/core";
import {
  AscendingOrDescending,
  orderingKey,
  TableSortDirection,
} from "@/components/DataPresentation";
import { useAdsQuery } from "@/services/ads";
import {
  PublicDashboardWidgetFilters,
  usePublicDashboardWidgetQueryFilters,
} from "@/services/public_dashboard";
import { ComparisonKey, MetricFilterSet, MetricKey } from "@/services/metrics";
import { filterCriteriaFormatter } from "@/utils/filterCriteriaFormatter";
import { Tag } from "./tags";
import { CalculatedMetricKey } from "@/services/calculated_metrics";

export const MARKETING_RESOURCE_CHANNELS = [
  ResourceChannel.GOOGLE_ADS,
  ResourceChannel.FACEBOOK_ADS,
  ResourceChannel.SNAPCHAT_ADS,
  ResourceChannel.LINKED_IN_ADS,
  ResourceChannel.XANDR,
  ResourceChannel.BING_ADS,
  ResourceChannel.C_ALT,
  ResourceChannel.TIK_TOK_ADS,
  ResourceChannel.KOBLER,
  ResourceChannel.DV_360,
  ResourceChannel.ADFORM,
] as const;

export type MarketingResourceChannel =
  (typeof MARKETING_RESOURCE_CHANNELS)[number];

export const BASE_MARKETING_METRICS = [
  "impressions",
  "clicks",
  "clickthroughRate",
  "costPerClick",
  "conversions",
  "cost",
  "costPerConversion",
  "costPerMilli",
  "totalClicks",
  "shares",
  "comments",
  "engagement",
  "value",
  "returnOnAdSpend",
  "returnOnAdSpendRatio",
  "initialVideoViews",
  "videoViews25Pct",
  "videoViews50Pct",
  "videoViews75Pct",
  "videoViews100Pct",
  "metaThruplays",
  "youtubeViews",
  "averageVideoWatchTime",
  "costPerInitialVideoView",
  "costPerMetaThruplay",
  "youtubeViewRate",
] as const satisfies MetricKey[];

export const MARKETING_CONVERSION_METRICS = [
  "conversions",
  "value",
  "returnOnAdSpend",
  "returnOnAdSpendRatio",
  "costPerConversion",
] as const satisfies MetricKey[];

export type MarketingMetric =
  | (typeof BASE_MARKETING_METRICS)[number]
  | CalculatedMetricKey;
export type MarketingTableMetricKey = MarketingMetric | "budget";
export type MarketingConversionMetric =
  (typeof MARKETING_CONVERSION_METRICS)[number];

export enum MarketingMetricsByComparisonLabel {
  CURRENT = "current",
  PREVIOUS = "previous",
}
export type MarketingMetrics = Record<MarketingMetric, number | null>;

export type ComparisonMarketingMetrics = Record<
  ComparisonKey<MarketingMetric>,
  number | null
>;

export interface MarketingMetricsByComparisonPeriod extends MarketingMetrics {
  label: "current" | "previous";
}

export interface MarketingMetricsByComparison {
  currentPeriod: MarketingMetricsByComparisonPeriod;
  previousPeriod: MarketingMetricsByComparisonPeriod;
}

/**
 * Some marketing performance endpoints allow filtering by the tags
 * of all the various levels of marketing entities. This function
 * is used to create a filter with the correct parameters
 * for the current level,
 * e.g. `useMarketingHierarchyLevelSpecificTags(ref(MarketingHierarchyLevel.CAMPAIGN), ref([1, 2, 3]))`
 * would give you a filter returning data for the campaigns with tags 1, 2 and 3.
 * If `tags` is undefined and `useGlobalFilters` is true it uses the global tag filter.
 */
function useMarketingHierarchyLevelSpecificTags(
  level: Ref<MarketingHierarchyLevel | undefined> | undefined,
  includedTags: Ref<number[]> | undefined,
  useGlobalFilters = true,
) {
  const tagFilters = useDetachedTagFilter(useGlobalFilters, includedTags);
  return toRefs(
    reactiveComputed(() => {
      const defaultFilters = {
        account_tags__in: null,
        not__account_tags__in: null,
        campaign_tags__in: null,
        not__campaign_tags__in: null,
        ad_group_tags__in: null,
        not__ad_group_tags__in: null,
        ad_tags__in: null,
        not__ad_tags__in: null,
        keyword_tags__in: null,
        not__keyword_tags__in: null,
        query_tags__in: null,
        not__query_tags__in: null,
      };
      if (!level?.value) {
        return defaultFilters;
      }
      const levelFilterKey = {
        [MarketingHierarchyLevel.ACCOUNT]: "account",
        [MarketingHierarchyLevel.CAMPAIGN]: "campaign",
        [MarketingHierarchyLevel.ADGROUP]: "ad_group",
        [MarketingHierarchyLevel.AD]: "ad",
        [MarketingHierarchyLevel.KEYWORD]: "keyword",
        [MarketingHierarchyLevel.SEARCH_TERM]: "query",
      }[level.value];
      return {
        ...defaultFilters,
        [`${levelFilterKey}_tags__in`]: tagFilters.tags__in,
        [`not__${levelFilterKey}_tags__in`]: tagFilters.not__tags__in,
      };
    }),
  );
}

/**
 * Some marketing performance endpoints allow filtering by the PK
 * of all the various levels of marketing entities. This function
 * is used to create a filter with the correct parameters
 * for the current level,
 * e.g. `useSelectedEntities(ref(MarketingHierarchyLevel.CAMPAIGN), ref([1, 2, 3]))`
 * would give you a filter returning data for the campaigns with PK 1, 2 and 3.
 */
export function useSelectedEntities(
  level: Ref<MarketingHierarchyLevel | undefined> | undefined,
  selectedEntities: Ref<number[] | undefined> | undefined,
) {
  return toRefs(
    reactiveComputed(() => {
      const defaultFilters = {
        account_pk__in: null,
        campaign_pk__in: null,
        ad_group_pk__in: null,
        ad_pk__in: null,
        ad_group_keyword_pk__in: null,
        ad_group_query_pk__in: null,
      };
      let activeFilters;
      const orderedSelectedEntities = selectedEntities?.value
        ? [...selectedEntities.value].sort()
        : undefined;
      const parameter = (payloadToQueryParams(orderedSelectedEntities) ??
        null) as number[] | null;
      switch (level?.value) {
        case MarketingHierarchyLevel.ACCOUNT:
          activeFilters = {
            account_pk__in: parameter,
          };
          break;
        case MarketingHierarchyLevel.CAMPAIGN:
          activeFilters = {
            campaign_pk__in: parameter,
          };
          break;
        case MarketingHierarchyLevel.ADGROUP:
          activeFilters = {
            ad_group_pk__in: parameter,
          };
          break;
        case MarketingHierarchyLevel.AD:
          activeFilters = { ad_pk__in: parameter };
          break;
        case MarketingHierarchyLevel.KEYWORD:
          activeFilters = {
            ad_group_keyword_pk__in: parameter,
          };
          break;
        case MarketingHierarchyLevel.SEARCH_TERM:
          activeFilters = {
            ad_group_query_pk__in: parameter,
          };
          break;
        default:
          activeFilters = {};
      }
      return { ...defaultFilters, ...activeFilters };
    }),
  );
}

function listMarketingMetricsByComparison({
  queryKey: [, params],
}: QueryFunctionContext<
  TemporalDataFilter &
    ResourcesDatafilter &
    AdNetworkFilter &
    ConversionFilter &
    MarketingHierarchyTagFilter &
    MarketingHierarchySelectedEntityFilter &
    PublicDashboardWidgetFilters
>): Promise<MarketingMetricsByComparison> {
  return api
    .get("/resources/marketing/metrics_compared_to_previous_period/", {
      params,
    })
    .then((response) => response.data);
}

export function useMarketingMetricsByComparisonQuery({
  selectedResourceIds,
  temporalFilter,
  enabled,
  hierarchyLevel,
  tagHierarchyLevel,
  selectedTags,
  selectedEntities,
  conversionGroups,
  conversions,
  useGlobalFilters = true,
}: {
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  enabled?: Ref<boolean>;
  hierarchyLevel?: Ref<MarketingHierarchyLevel | undefined>;
  tagHierarchyLevel?: Ref<MarketingHierarchyLevel | undefined>;
  selectedTags?: Ref<number[]>;
  selectedEntities?: Ref<number[] | undefined>;
  conversionGroups?: Ref<number[]>;
  conversions?: Ref<number[]>;
  useGlobalFilters?: boolean;
} = {}) {
  tagHierarchyLevel ??= hierarchyLevel;
  const queryKey = [
    "marketingMetricsByComparison",
    {
      ...useDataFilters(["subcategory"], useGlobalFilters),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useMarketingHierarchyLevelSpecificTags(
        tagHierarchyLevel,
        selectedTags,
        useGlobalFilters,
      ),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useSelectedEntities(hierarchyLevel, selectedEntities),
      ...useConversionGroups(conversionGroups, useGlobalFilters),
      ...useConversions(conversions, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
    },
  ] as const;
  return reactive(
    useQuery({ queryKey, queryFn: listMarketingMetricsByComparison, enabled }),
  );
}
export const useMarketingMetricsByComparisonQueryWithMetrics = {
  query: useMarketingMetricsByComparisonQuery,
  metrics: BASE_MARKETING_METRICS,
};

export type AggregatedMarketingMetrics = MarketingMetrics &
  ComparisonMarketingMetrics;

export interface MarketingMetricsByAdGroupData extends MarketingMetrics {
  resource: number;
  platform: MarketingResourceChannel;
  campaignName: string;
  campaignId: string;
  adGroupId: string;
  adGroupName: string;
  device: string;
  subcategory: string;
}

export interface MarketingMetricsByAdGroup {
  labels: string[];
  data: MarketingMetricsByAdGroupData[];
}

export interface MarketingMetricByDateAndPlatformDataset {
  label: string;
  channel: MarketingResourceChannel;
  resource: string | null;
  data: (number | null)[];
}

export interface MarketingMetricByDateAndPlatform {
  labels: string[];
  datasets: MarketingMetricByDateAndPlatformDataset[];
}

export type MarketingMetricsByDateAndPlatform = Record<
  MarketingMetric,
  MarketingMetricByDateAndPlatform
>;

export interface MarketingMetricsByDateAndPlatformWithExtraDetails
  extends MarketingMetricsByDateAndPlatform {
  latestDate: string | null;
  resourceName: string | null;
  resourceChannel: ResourceChannel | null;
}
export type TemporalMarketingMetricsByDateAndPlatform = Record<
  TemporalPeriodsType,
  MarketingMetricsByDateAndPlatformWithExtraDetails
>;

function listMarketingMetricsByDateAndPlatform({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    timeUnit: TimeUnit;
    filterCriteria?: MetricFilterSet[];
  } & SeparateResourcesFilter &
    TemporalDataFilter &
    ResourcesDatafilter &
    AdNetworkFilter &
    ConversionFilter &
    MarketingHierarchyTagFilter &
    MarketingHierarchySelectedEntityFilter &
    PublicDashboardWidgetFilters
>): Promise<TemporalMarketingMetricsByDateAndPlatform> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);
  return api
    .get("/resources/marketing/metrics_by_date_and_platform/", {
      params: { ...params, ...filterCriteria },
    })
    .then((response) => response.data);
}

export function useMarketingMetricsByDateAndPlatformQuery({
  selectedResourceIds,
  conversionGroups,
  conversions,
  timeUnit = ref(TimeUnit.DAY),
  hierarchyLevel,
  tagHierarchyLevel,
  selectedTags,
  enabled,
  selectedEntities,
  temporalFilter,
  useGlobalFilters = true,
  filterCriteria,
}: {
  selectedResourceIds?: Ref<number[]>;
  conversionGroups?: Ref<number[]>;
  conversions?: Ref<number[]>;
  timeUnit: Ref<TimeUnit>;
  hierarchyLevel?: Ref<MarketingHierarchyLevel | undefined>;
  tagHierarchyLevel?: Ref<MarketingHierarchyLevel | undefined>;
  selectedTags?: Ref<number[]>;
  enabled?: Ref<boolean>;
  selectedEntities?: Ref<number[] | undefined>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  filterCriteria?: Ref<MetricFilterSet[]>;
}) {
  tagHierarchyLevel ??= hierarchyLevel;
  const queryKey = [
    "marketingMetricsByDateAndPlatform",
    {
      ...useDataFilters(["subcategory", "separateResources"], useGlobalFilters),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useMarketingHierarchyLevelSpecificTags(
        tagHierarchyLevel,
        selectedTags,
        useGlobalFilters,
      ),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useConversionGroups(conversionGroups, useGlobalFilters),
      ...useConversions(conversions, useGlobalFilters),
      ...useSelectedEntities(hierarchyLevel, selectedEntities),
      ...usePublicDashboardWidgetQueryFilters(),
      timeUnit,
      filterCriteria,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listMarketingMetricsByDateAndPlatform,
      enabled,
    }),
  );
}
export const useMarketingMetricsByDateAndPlatformQueryWithMetrics = {
  query: useMarketingMetricsByDateAndPlatformQuery,
  metrics: BASE_MARKETING_METRICS,
};
export type MarketingMetricsByDate = Record<
  MarketingMetric,
  ResponseTimeSeriesDatasets
>;

export interface MarketingMetricsByDateWithExtraDetails
  extends MarketingMetricsByDate {
  latestDate: string | null;
}

export type TemporalMarketingMetricsByDate = Record<
  TemporalPeriodsType,
  MarketingMetricsByDateWithExtraDetails
>;

export interface CurrentMonthDailySpend {
  selection: {
    labels: string[];
    datasets: [
      {
        label: "actual_cumulative_spend";
        data: (number | null)[];
        period: TemporalPeriodsType;
      },
      {
        label: "projected_cumulative_spend";
        data: (number | null)[];
        period: TemporalPeriodsType;
      },
    ];
  };
  comparison?: null;
}

function listCurrentMonthDailySpend({
  queryKey: [, params],
}: QueryFunctionContext<ResourcesDatafilter>): Promise<CurrentMonthDailySpend> {
  return api
    .get("/resources/marketing/current_month_daily_spend/", { params })
    .then((response) => response.data);
}

export function useCurrentMonthDailySpendQuery() {
  const queryKey = [
    "currentMonthDailySpend",
    useDataFilters(["includedResources"]),
  ] as const;
  return reactive(useQuery({ queryKey, queryFn: listCurrentMonthDailySpend }));
}

export interface MonthlySpendByChannelDataset {
  label: string;
  channel: MarketingResourceChannel;
  resource: string | null;
  data: (number | null)[];
  period: TemporalPeriodsType;
}

export interface MonthlySpendByChannel {
  selection: {
    labels: string[];
    datasets: MonthlySpendByChannelDataset[];
  };
  comparison?: null;
}

function listMonthlySpendByChannel({
  queryKey: [, params],
}: QueryFunctionContext<
  SeparateResourcesFilter & ResourcesDatafilter
>): Promise<MonthlySpendByChannel> {
  return api
    .get("/resources/marketing/monthly_spend_by_channel/", { params })
    .then((response) => response.data);
}

export function useMonthlySpendByChannelQuery() {
  const queryKey = [
    "monthlySpendByChannel",
    useDataFilters(["includedResources", "separateResources"]),
  ] as const;
  return reactive(useQuery({ queryKey, queryFn: listMonthlySpendByChannel }));
}

export enum MarketingEntityRunningStatus {
  ENABLED = "enabled",
  PAUSED = "paused",
  ARCHIVED = "archived",
}

export enum MarketingHierarchyLevel {
  ACCOUNT = "account",
  CAMPAIGN = "campaign",
  ADGROUP = "adGroup",
  AD = "ad",
  KEYWORD = "keyword",
  SEARCH_TERM = "searchTerm",
}

export interface MarketingHierarchyEntityBase
  extends MarketingMetrics,
    ComparisonMarketingMetrics {
  pk: number;
  name: string;
  parentLevelName: string;
  channel: ResourceChannel;
  resource: number;
  tags: number[];
  status: MarketingEntityRunningStatus | null;
}

export interface MarketingHierarchyEntityResponse<
  E extends MarketingHierarchyEntity,
> {
  count: number;
  next: string | null;
  previous: string | null;
  results: E[];
  pageTotals: AggregatedMarketingMetrics;
  overallTotals: AggregatedMarketingMetrics;
}

export interface Account extends MarketingHierarchyEntityBase {
  hierarchyLevel: MarketingHierarchyLevel.ACCOUNT;
  hasCampaignBudget: boolean;
  hasAdGroupBudget: boolean;
}

export type AccountWithoutMetrics = Omit<
  Account,
  keyof AggregatedMarketingMetrics
>;

export enum BudgetType {
  DAILY = "DAILY",
  LIFETIME = "LIFETIME",
}

export enum BudgetLevel {
  AD_GROUP = "AD_GROUP",
  CAMPAIGN = "CAMPAIGN",
}

export interface Budget {
  amount: number;
  type: BudgetType;
  isShared: boolean;
  shareCount: number;
  name: string;
  currency: string;
}

export interface Campaign extends MarketingHierarchyEntityBase {
  hierarchyLevel: MarketingHierarchyLevel.CAMPAIGN;
  type: string;
  start: string | null;
  end: string | null;
  budget: Budget | null;
  hasAdGroupBudget: boolean;
  isHighlighted: boolean;
}

export type CampaignWithoutMetrics = Omit<
  Campaign,
  keyof AggregatedMarketingMetrics
>;

export interface AdGroup extends MarketingHierarchyEntityBase {
  hierarchyLevel: MarketingHierarchyLevel.ADGROUP;
  campaignType: string;
  campaign: number;
  start: string | null;
  end: string | null;
  budget: Budget | null;
  hasCampaignBudget: boolean;
  isHighlighted: boolean;
}

export type AdGroupWithoutMetrics = Omit<
  AdGroup,
  keyof AggregatedMarketingMetrics
>;

export interface AdGroupKeyword extends MarketingHierarchyEntityBase {
  hierarchyLevel: MarketingHierarchyLevel.KEYWORD;
  matchType: string;
  keywordId: number;
}

export type AdGroupKeywordWithoutMetrics = Omit<
  AdGroupKeyword,
  keyof AggregatedMarketingMetrics
>;

export interface AdGroupSearchTerm extends MarketingHierarchyEntityBase {
  hierarchyLevel: MarketingHierarchyLevel.SEARCH_TERM;
  queryId: number;
  adGroupKeywordId: number | null;
}

export type AdGroupSearchTermWithoutMetrics = Omit<
  AdGroupSearchTerm,
  keyof AggregatedMarketingMetrics
>;

export type MarketingHierarchyEntity =
  | Account
  | Campaign
  | AdGroup
  | AdGroupKeyword
  | AdGroupSearchTerm;

function getAdGroup({
  queryKey: [, { pk }],
}: QueryFunctionContext<{
  pk: number;
}>): Promise<AdGroupWithoutMetrics> {
  return api
    .get(`/resources/marketing/ad_groups/${pk}/`)
    .then((response) => response.data);
}

export function useAdGroupQuery({
  pk,
  enabled,
}: {
  pk: Ref<number | undefined>;
  enabled?: Ref<boolean>;
}) {
  const queryKey = ["adGroup", { pk: pk as Ref<number> }] as const;
  const queryClient = useQueryClient();
  return reactive(
    useQuery({
      queryKey,
      queryFn: getAdGroup,
      initialData: () => {
        return queryClient
          .getQueriesData<
            MarketingHierarchyEntityResponse<AdGroup> | undefined
          >({ queryKey: ["adGroups"] })
          .flatMap(([_, adGroupsResponse]) => adGroupsResponse?.results ?? [])
          .find((adGroup) => adGroup.pk === pk.value);
      },
      enabled: computed(
        () => pk.value !== undefined && (enabled?.value ?? true),
      ),
    }),
  );
}

export type AdGroupOrdering =
  | "name"
  | "start"
  | "end"
  | "budget__amount"
  | MarketingMetric;

type AdGroupsParams = {
  page?: number;
  ordering: AscendingOrDescending<AdGroupOrdering>[];
  search: string;
  pageSize: number;
  includeTotals?: boolean;
  campaign: number | undefined;
  filterCriteria?: MetricFilterSet[];
  highlightedAdGroups: number[] | undefined;
  highlightedCampaigns: number[] | undefined;
  highlightedResources: number[] | undefined;
} & TemporalDataFilter &
  ResourcesDatafilter &
  AdNetworkFilter &
  ConversionFilter &
  TagsDataFilter &
  PKFilter;

function listAdGroups({
  queryKey: [, params],
  ...context
}:
  | QueryFunctionContext<AdGroupsParams, number | undefined>
  | QueryFunctionContext<AdGroupsParams>): Promise<
  MarketingHierarchyEntityResponse<AdGroup>
> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);

  return api
    .get("/resources/marketing/ad_groups/", {
      params: {
        page: "pageParam" in context ? context.pageParam : params.page,
        ...params,
        ...filterCriteria,
      },
    })
    .then((response) => response.data);
}

export function useAdGroupsQuery({
  page,
  ordering,
  search,
  pageSize,
  campaign,
  pks,
  enabled,
  filterCriteria,
  selectedResourceIds,
  conversionGroups,
  conversions,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
  highlightedAdGroups,
  highlightedCampaigns,
  highlightedResources,
}: {
  page: Ref<number>;
  ordering: Ref<AscendingOrDescending<AdGroupOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  campaign: Ref<number | undefined>;
  pks?: Ref<number[]>;
  enabled?: Ref<boolean>;
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  conversionGroups?: Ref<number[]>;
  conversions?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  highlightedAdGroups?: Ref<number[]>;
  highlightedCampaigns?: Ref<number[]>;
  highlightedResources?: Ref<number[]>;
}) {
  const queryKey = [
    "adGroups",
    {
      page,
      ordering,
      search,
      pageSize,
      campaign,
      pks,
      filterCriteria,
      highlightedAdGroups,
      highlightedCampaigns,
      highlightedResources,
      ...useDataFilters(["subcategory"], useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      ...useConversionGroups(conversionGroups, useGlobalFilters),
      ...useConversions(conversions, useGlobalFilters),
    },
  ] as const;
  useResetPage(page, queryKey, [
    "highlightedAdGroups",
    "highlightedCampaigns",
    "highlightedResources",
  ]);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listAdGroups,
      enabled: computed(() => pageSize.value > 0 && (enabled?.value ?? true)),
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
    }),
  );
}

export const useAdGroupsQueryWithMetrics = {
  query: useAdGroupsQuery,
  metrics: BASE_MARKETING_METRICS,
};

export function useAdGroupsInfiniteQuery({
  ordering,
  search,
  pageSize,
  includeTotals,
  campaign,
  enabled,
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
  highlightedAdGroups,
  highlightedCampaigns,
  highlightedResources,
}: {
  ordering: Ref<AscendingOrDescending<AdGroupOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  includeTotals: Ref<boolean>;
  campaign: Ref<number | undefined>;
  enabled?: Ref<boolean>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  highlightedAdGroups?: Ref<number[]>;
  highlightedCampaigns?: Ref<number[]>;
  highlightedResources?: Ref<number[]>;
}) {
  const queryKey = [
    "infiniteAdGroups",
    {
      ordering,
      search,
      pageSize,
      includeTotals,
      campaign,
      highlightedAdGroups,
      highlightedCampaigns,
      highlightedResources,
      ...useDataFilters(
        ["subcategory", "conversionGroup", "conversion"],
        useGlobalFilters,
      ),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
    },
  ] as const;
  return reactive(
    useInfiniteQuery({
      queryKey,
      queryFn: listAdGroups,
      enabled,
      placeholderData: keepPreviousData,
      initialPageParam: undefined,
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length + 1 : undefined,
    }),
  );
}

function getCampaign({
  queryKey: [, { pk }],
}: QueryFunctionContext<{
  pk: number;
}>): Promise<CampaignWithoutMetrics> {
  return api
    .get(`/resources/marketing/campaigns/${pk}/`)
    .then((response) => response.data);
}

export function useCampaignQuery({
  pk,
  enabled,
}: {
  pk: Ref<number | undefined>;
  enabled?: Ref<boolean>;
}) {
  const queryKey = ["campaign", { pk: pk as Ref<number> }] as const;
  const queryClient = useQueryClient();
  return reactive(
    useQuery({
      queryKey,
      queryFn: getCampaign,
      initialData: () => {
        return queryClient
          .getQueriesData<
            MarketingHierarchyEntityResponse<Campaign> | undefined
          >({ queryKey: ["campaigns"] })
          .flatMap(([_, campaignsResponse]) => campaignsResponse?.results ?? [])
          .find((campaign) => campaign.pk === pk.value);
      },
      enabled: computed(
        () => pk.value !== undefined && (enabled?.value ?? true),
      ),
    }),
  );
}
export const useCampaignQueryWithMetrics = {
  query: useCampaignQuery,
  metrics: BASE_MARKETING_METRICS,
};

export type CampaignOrdering =
  | "name"
  | "start"
  | "end"
  | "budget__amount"
  | MarketingMetric;

type CampaignsParams = {
  page?: number;
  ordering: AscendingOrDescending<CampaignOrdering>[];
  search: string;
  pageSize: number;
  includeTotals?: boolean;
  filterCriteria?: MetricFilterSet[];
  highlightedCampaigns: number[] | undefined;
  highlightedResources: number[] | undefined;
} & TemporalDataFilter &
  ResourcesDatafilter &
  AdNetworkFilter &
  ConversionFilter &
  TagsDataFilter &
  PKFilter;

function listCampaigns({
  queryKey: [, params],
  ...context
}:
  | QueryFunctionContext<CampaignsParams, number | undefined>
  | QueryFunctionContext<CampaignsParams>): Promise<
  MarketingHierarchyEntityResponse<Campaign>
> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);

  return api
    .get("/resources/marketing/campaigns/", {
      params: {
        page: "pageParam" in context ? context.pageParam : params.page,
        ...params,
        ...filterCriteria,
      },
    })
    .then((response) => response.data);
}

export function useCampaignsQuery({
  page,
  ordering,
  search,
  pageSize,
  pks,
  enabled,
  filterCriteria,
  selectedResourceIds,
  conversionGroups,
  conversions,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
  highlightedCampaigns,
  highlightedResources,
}: {
  page: Ref<number>;
  ordering: Ref<AscendingOrDescending<CampaignOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  pks?: Ref<number[]>;
  enabled?: Ref<boolean>;
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  conversionGroups?: Ref<number[]>;
  conversions?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  highlightedCampaigns?: Ref<number[]>;
  highlightedResources?: Ref<number[]>;
}) {
  const queryKey = [
    "campaigns",
    {
      page,
      ordering,
      search,
      pageSize,
      pks,
      filterCriteria,
      highlightedCampaigns,
      highlightedResources,
      ...useDataFilters(["subcategory"], useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      ...useConversionGroups(conversionGroups, useGlobalFilters),
      ...useConversions(conversions, useGlobalFilters),
    },
  ] as const;
  useResetPage(page, queryKey, [
    "highlightedCampaigns",
    "highlightedResources",
  ]);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCampaigns,
      enabled: computed(() => pageSize.value > 0 && (enabled?.value ?? true)),
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
    }),
  );
}
export const useCampaignsQueryWithMetrics = {
  query: useCampaignsQuery,
  metrics: BASE_MARKETING_METRICS,
};

export function useCampaignsInfiniteQuery({
  ordering,
  search,
  pageSize,
  includeTotals,
  enabled,
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
  highlightedCampaigns,
  highlightedResources,
}: {
  ordering: Ref<AscendingOrDescending<CampaignOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  includeTotals: Ref<boolean>;
  enabled?: Ref<boolean>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  highlightedCampaigns?: Ref<number[]>;
  highlightedResources?: Ref<number[]>;
}) {
  const queryKey = [
    "infiniteCampaigns",
    {
      ordering,
      search,
      pageSize,
      includeTotals,
      highlightedCampaigns,
      highlightedResources,
      ...useDataFilters(
        ["subcategory", "conversionGroup", "conversion"],
        useGlobalFilters,
      ),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
    },
  ] as const;
  return reactive(
    useInfiniteQuery({
      queryKey,
      queryFn: listCampaigns,
      enabled,
      placeholderData: keepPreviousData,
      initialPageParam: undefined,
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length + 1 : undefined,
    }),
  );
}

function getAccount({
  queryKey: [, { pk }],
}: QueryFunctionContext<{
  pk: number;
}>): Promise<AdGroupWithoutMetrics> {
  return api
    .get(`/resources/marketing/accounts/${pk}/`)
    .then((response) => response.data);
}

export function useAccountQuery({
  pk,
  enabled,
}: {
  pk: Ref<number | undefined>;
  enabled?: Ref<boolean>;
}) {
  const queryKey = ["account", { pk: pk as Ref<number> }] as const;
  const queryClient = useQueryClient();
  return reactive(
    useQuery({
      queryKey,
      queryFn: getAccount,
      initialData: () => {
        return queryClient
          .getQueriesData<
            MarketingHierarchyEntityResponse<AdGroup> | undefined
          >({ queryKey: ["accounts"] })
          .flatMap(([_, accountsResponse]) => accountsResponse?.results ?? [])
          .find((account) => account.pk === pk.value);
      },
      enabled: computed(
        () => pk.value !== undefined && (enabled?.value ?? true),
      ),
    }),
  );
}

export type AccountOrdering = "name" | MarketingMetric;

function listAccounts({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    page: number;
    ordering: AscendingOrDescending<AccountOrdering>[];
    search: string;
    pageSize: number;
    includeTotals?: boolean;
    filterCriteria?: MetricFilterSet[];
  } & TemporalDataFilter &
    ResourcesDatafilter &
    AdNetworkFilter &
    ConversionFilter &
    TagsDataFilter &
    PKFilter
>): Promise<MarketingHierarchyEntityResponse<Account>> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);

  return api
    .get("/resources/marketing/accounts/", {
      params: { ...params, ...filterCriteria },
    })
    .then((response) => response.data);
}

export function useAccountsQuery({
  page,
  ordering,
  search,
  pageSize,
  pks,
  enabled,
  filterCriteria,
  conversionGroups,
  conversions,
  temporalFilter,
  useGlobalFilters = true,
}: {
  page: Ref<number>;
  ordering: Ref<AscendingOrDescending<AccountOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  pks?: Ref<number[]>;
  enabled?: Ref<boolean>;
  filterCriteria?: Ref<MetricFilterSet[]>;
  conversionGroups?: Ref<number[]>;
  conversions?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
}) {
  const queryKey = [
    "accounts",
    {
      page,
      ordering,
      search,
      pageSize,
      pks,
      filterCriteria,

      ...useDataFilters(
        ["includedResources", "subcategory", "includedTags", "excludedTags"],
        useGlobalFilters,
      ),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      ...useConversionGroups(conversionGroups, useGlobalFilters),
      ...useConversions(conversions, useGlobalFilters),
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listAccounts,
      enabled: computed(() => pageSize.value > 0 && (enabled?.value ?? true)),
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
    }),
  );
}
export const useAccountsQueryWithMetrics = {
  query: useAccountsQuery,
  metrics: BASE_MARKETING_METRICS,
};

function getAdGroupKeyword({
  queryKey: [, { pk }],
}: QueryFunctionContext<{
  pk: number;
}>): Promise<AdGroupKeyword> {
  return api
    .get(`/resources/marketing/keywords/${pk}/`)
    .then((response) => response.data);
}

export function useAdGroupKeywordQuery({
  pk,
  enabled,
}: {
  pk: Ref<number | undefined>;
  enabled?: Ref<boolean>;
}) {
  const queryKey = ["adGroupKeyword", { pk: pk as Ref<number> }] as const;
  const queryClient = useQueryClient();
  return reactive(
    useQuery({
      queryKey,
      queryFn: getAdGroupKeyword,
      initialData: () => {
        return queryClient
          .getQueriesData<
            MarketingHierarchyEntityResponse<AdGroupKeyword> | undefined
          >({ queryKey: ["adGroupKeywords"] })
          .flatMap(([_, keywordsResponse]) => keywordsResponse?.results ?? [])
          .find((keyword) => keyword.pk === pk.value);
      },
      enabled: computed(
        () => pk.value !== undefined && (enabled?.value ?? true),
      ),
    }),
  );
}

export type AdGroupKeywordOrdering = "name" | MarketingMetric;

type AdGroupKeywordsParams = {
  page?: number;
  ordering: AscendingOrDescending<AdGroupKeywordOrdering>[];
  search: string;
  pageSize: number;
  includeTotals?: boolean;
  adGroup: number | undefined;
  campaign: number | undefined;
  filterCriteria?: MetricFilterSet[];
} & TemporalDataFilter &
  ResourcesDatafilter &
  ConversionFilter &
  TagsDataFilter &
  PKFilter;

function listAdGroupKeywords({
  queryKey: [, params],
  ...context
}:
  | QueryFunctionContext<AdGroupKeywordsParams, number | undefined>
  | QueryFunctionContext<AdGroupKeywordsParams>): Promise<
  MarketingHierarchyEntityResponse<AdGroupKeyword>
> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);
  return api
    .get("/resources/marketing/keywords/", {
      params: {
        page: "pageParam" in context ? context.pageParam : params.page,
        ...params,
        ...filterCriteria,
      },
    })
    .then((response) => response.data);
}

export function useAdGroupKeywordsQuery({
  page,
  ordering,
  search,
  pageSize,
  adGroup,
  campaign,
  pks,
  enabled,
  filterCriteria,
  selectedResourceIds,
  conversionGroups,
  conversions,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
}: {
  page: Ref<number>;
  ordering: Ref<AscendingOrDescending<AdGroupKeywordOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  adGroup: Ref<number | undefined>;
  campaign: Ref<number | undefined>;
  pks?: Ref<number[]>;
  enabled?: Ref<boolean>;
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  conversionGroups?: Ref<number[]>;
  conversions?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
}) {
  const queryKey = [
    "adGroupKeywords",
    {
      page,
      ordering,
      search,
      pageSize,
      adGroup,
      campaign,
      pks,
      filterCriteria,
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...usePublicDashboardWidgetQueryFilters(),
      ...useConversionGroups(conversionGroups, useGlobalFilters),
      ...useConversions(conversions, useGlobalFilters),
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listAdGroupKeywords,
      enabled: computed(() => pageSize.value > 0 && (enabled?.value ?? true)),
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
    }),
  );
}

export const useAdGroupKeywordQueryWithMetrics = {
  query: useAdGroupKeywordsQuery,
  metrics: BASE_MARKETING_METRICS,
};

export function useAdGroupKeywordsInfiniteQuery({
  ordering,
  search,
  pageSize,
  includeTotals,
  adGroup,
  campaign,
  enabled,
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
}: {
  ordering: Ref<AscendingOrDescending<AdGroupKeywordOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  includeTotals: Ref<boolean>;
  adGroup: Ref<number | undefined>;
  campaign: Ref<number | undefined>;
  enabled?: Ref<boolean>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
}) {
  const queryKey = [
    "infiniteAdGroupKeywords",
    {
      ordering,
      search,
      pageSize,
      includeTotals,
      adGroup,
      campaign,
      ...useDataFilters(["conversionGroup", "conversion"], useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
    },
  ] as const;

  return reactive(
    useInfiniteQuery({
      queryKey,
      queryFn: listAdGroupKeywords,
      enabled,
      placeholderData: keepPreviousData,
      initialPageParam: undefined,
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length + 1 : undefined,
    }),
  );
}

export type AdGroupSearchTermOrdering = "name" | MarketingMetric;

type AdGroupSearchTermParams = {
  page?: number;
  ordering: AscendingOrDescending<AdGroupSearchTermOrdering>[];
  search: string;
  pageSize: number;
  includeTotals?: boolean;
  adGroupKeyword: number | undefined;
  adGroup: number | undefined;
  campaign: number | undefined;
  filterCriteria?: MetricFilterSet[];
} & TemporalDataFilter &
  ResourcesDatafilter &
  ConversionFilter &
  TagsDataFilter &
  PKFilter;

function listAdGroupSearchTerms({
  queryKey: [, params],
}:
  | QueryFunctionContext<AdGroupSearchTermParams, number | undefined>
  | QueryFunctionContext<AdGroupSearchTermParams>): Promise<
  MarketingHierarchyEntityResponse<AdGroupSearchTerm>
> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);
  return api
    .get("/resources/marketing/queries/", {
      params: { ...params, ...filterCriteria },
    })
    .then((response) => response.data);
}

export function useAdGroupSearchTermsQuery({
  page,
  ordering,
  search,
  pageSize,
  adGroupKeyword,
  adGroup,
  campaign,
  pks,
  enabled,
  filterCriteria,
  selectedResourceIds,
  conversionGroups,
  conversions,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
}: {
  page: Ref<number>;
  ordering: Ref<AscendingOrDescending<AdGroupSearchTermOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  adGroupKeyword: Ref<number | undefined>;
  adGroup: Ref<number | undefined>;
  campaign: Ref<number | undefined>;
  pks?: Ref<number[]>;
  enabled?: Ref<boolean>;
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  conversionGroups?: Ref<number[]>;
  conversions?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
}) {
  const queryKey = [
    "adGroupSearchTerms",
    {
      page,
      ordering,
      search,
      pageSize,
      adGroupKeyword,
      adGroup,
      campaign,
      pks,
      filterCriteria,
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...usePublicDashboardWidgetQueryFilters(),
      ...useConversionGroups(conversionGroups, useGlobalFilters),
      ...useConversions(conversions, useGlobalFilters),
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listAdGroupSearchTerms,
      enabled: computed(() => pageSize.value > 0 && (enabled?.value ?? true)),
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
    }),
  );
}

export const useAdGroupSearchTermsQueryWithMetrics = {
  query: useAdGroupSearchTermsQuery,
  metrics: BASE_MARKETING_METRICS,
};

export function useAdGroupSearchTermsInfiniteQuery({
  ordering,
  search,
  pageSize,
  includeTotals,
  adGroupKeyword,
  adGroup,
  campaign,
  pks,
  enabled,
  filterCriteria,
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
}: {
  ordering: Ref<AscendingOrDescending<AdGroupSearchTermOrdering>[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  includeTotals: Ref<boolean>;
  adGroupKeyword: Ref<number | undefined>;
  adGroup: Ref<number | undefined>;
  campaign: Ref<number | undefined>;
  pks?: Ref<number[]>;
  enabled?: Ref<boolean>;
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
}) {
  const queryKey = [
    "infiniteAdGroupSearchTerms",
    {
      ordering,
      search,
      pageSize,
      includeTotals,
      adGroupKeyword,
      adGroup,
      campaign,
      pks,
      filterCriteria,
      ...useDataFilters(["conversionGroup", "conversion"], useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
    },
  ] as const;
  return reactive(
    useInfiniteQuery({
      queryKey,
      queryFn: listAdGroupSearchTerms,
      enabled,
      placeholderData: keepPreviousData,
      initialPageParam: undefined,
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length + 1 : undefined,
    }),
  );
}

export type MarketingOrdering =
  | MarketingMetric
  | "name"
  | "start"
  | "end"
  | "budget";

export function useMarketingTableDataQuery(
  currentRouteHierarchyLevel: Ref<MarketingHierarchyLevel | undefined>,
  selectedTableHierarchyLevel: Ref<MarketingHierarchyLevel>,
  entityId: Ref<number | undefined>,
  pageSize: Ref<number>,
  page: Ref<number>,
  search: Ref<string>,
  filterCriteria: Ref<MetricFilterSet[]>,
  sortedByColumn: Ref<MarketingOrdering>,
  sortedDirection: Ref<TableSortDirection>,
  timeUnit: Ref<TimeUnit>,
  pks?: Ref<number[]>,
  highlightedAdGroups?: Ref<number[]>,
  highlightedCampaigns?: Ref<number[]>,
  highlightedResources?: Ref<number[]>,
) {
  const dataFiltersStore = useDataFiltersStore();
  const ordering = computed(() => {
    const attribute =
      sortedByColumn.value === "budget"
        ? "budget__amount"
        : sortedByColumn.value;
    const ordering = [orderingKey(attribute, sortedDirection.value)];
    if (sortedByColumn.value === "conversions") {
      ordering.push(orderingKey("impressions", TableSortDirection.DESC));
    }
    return ordering;
  });
  const campaign = computed(() =>
    currentRouteHierarchyLevel.value === "campaign"
      ? entityId.value
      : undefined,
  );
  const adGroup = computed(() =>
    currentRouteHierarchyLevel.value === "adGroup" ? entityId.value : undefined,
  );
  const selectedResourceIds = computed(() => {
    if (currentRouteHierarchyLevel.value === MarketingHierarchyLevel.ACCOUNT) {
      return [entityId.value as number];
    } else {
      return dataFiltersStore.includedResources;
    }
  });

  const accounts = useAccountsQuery({
    page,
    ordering: ordering as Ref<AscendingOrDescending<AccountOrdering>[]>,
    search,
    pageSize,
    pks,
    filterCriteria,
    enabled: computed(
      () =>
        selectedTableHierarchyLevel.value === MarketingHierarchyLevel.ACCOUNT,
    ),
  });
  const campaigns = useCampaignsQuery({
    page,
    ordering,
    search,
    pageSize,
    pks,
    filterCriteria,
    enabled: computed(
      () =>
        selectedTableHierarchyLevel.value === MarketingHierarchyLevel.CAMPAIGN,
    ),
    selectedResourceIds,
    highlightedCampaigns,
    highlightedResources,
  });
  const adGroups = useAdGroupsQuery({
    page,
    ordering,
    search,
    pageSize,
    campaign,
    pks,
    filterCriteria,
    enabled: computed(
      () =>
        selectedTableHierarchyLevel.value === MarketingHierarchyLevel.ADGROUP,
    ),
    selectedResourceIds,
    highlightedAdGroups,
    highlightedCampaigns,
    highlightedResources,
  });
  const ads = useAdsQuery({
    page,
    ordering: ordering as Ref<AscendingOrDescending<AccountOrdering>[]>,
    search,
    pageSize,
    includeTotals: ref(true),
    includeMetricsByDate: ref(false),
    adGroup,
    campaign,
    pks,
    filterCriteria,
    enabled: computed(
      () => selectedTableHierarchyLevel.value === MarketingHierarchyLevel.AD,
    ),
    timeUnit,
    selectedResourceIds,
  });
  const keywords = useAdGroupKeywordsQuery({
    page,
    ordering: ordering as Ref<AscendingOrDescending<AccountOrdering>[]>,
    search,
    pageSize,
    adGroup,
    campaign,
    filterCriteria,
    enabled: computed(
      () =>
        selectedTableHierarchyLevel.value === MarketingHierarchyLevel.KEYWORD,
    ),
  });
  const searchTerms = useAdGroupSearchTermsQuery({
    page,
    ordering: ordering as Ref<AscendingOrDescending<AccountOrdering>[]>,
    search,
    pageSize,
    adGroupKeyword: computed(() =>
      currentRouteHierarchyLevel.value === "keyword"
        ? entityId.value
        : undefined,
    ),
    adGroup,
    campaign,
    filterCriteria,
    enabled: computed(
      () =>
        selectedTableHierarchyLevel.value ===
        MarketingHierarchyLevel.SEARCH_TERM,
    ),
  });

  return computed(() => {
    return {
      [MarketingHierarchyLevel.ACCOUNT]: accounts,
      [MarketingHierarchyLevel.CAMPAIGN]: campaigns,
      [MarketingHierarchyLevel.ADGROUP]: adGroups,
      [MarketingHierarchyLevel.AD]: ads,
      [MarketingHierarchyLevel.KEYWORD]: keywords,
      [MarketingHierarchyLevel.SEARCH_TERM]: searchTerms,
    }[selectedTableHierarchyLevel.value];
  });
}

export function useMarketingChartDataQuery(
  currentRouteHierarchyLevel: Ref<MarketingHierarchyLevel | undefined>,
  hierarchyLevel: Ref<MarketingHierarchyLevel>,
  pk: Ref<number | undefined>,
  selectedEntities: Ref<number[]>,
  timeUnit: Ref<TimeUnit>,
) {
  const dataFiltersStore = useDataFiltersStore();
  const selectedResourceIds = computed(() => {
    if (currentRouteHierarchyLevel.value === MarketingHierarchyLevel.ACCOUNT) {
      return [pk.value as number];
    } else {
      return dataFiltersStore.includedResources;
    }
  });

  return useMarketingMetricsByDateAndPlatformQuery({
    // If we're not comparing, but we're on the page for a specific entity,
    // we use this to filter the data by that entity.
    // If we're comparing we use it to filter by the compared entities instead.
    hierarchyLevel: computed(() =>
      selectedEntities.value.length === 0 && pk.value
        ? currentRouteHierarchyLevel.value
        : hierarchyLevel.value,
    ),
    tagHierarchyLevel: hierarchyLevel,
    selectedEntities: computed(() =>
      selectedEntities.value.length === 0 && pk.value
        ? [pk.value]
        : selectedEntities.value,
    ),
    selectedResourceIds,
    timeUnit,
  });
}

export function useMarketingGridDataQuery(
  currentRouteHierarchyLevel: Ref<MarketingHierarchyLevel | undefined>,
  hierarchyLevel: Ref<MarketingHierarchyLevel>,
  pk: Ref<number | undefined>,
  selectedEntities: Ref<number[]>,
  temporalFilter?: Ref<DateRangeSelection>,
) {
  const dataFiltersStore = useDataFiltersStore();
  const selectedResourceIds = computed(() => {
    if (currentRouteHierarchyLevel.value === MarketingHierarchyLevel.ACCOUNT) {
      return [pk.value as number];
    } else {
      return dataFiltersStore.includedResources;
    }
  });

  return useMarketingMetricsByComparisonQuery({
    // If we're not comparing, but we're on the page for a specific entity,
    // we use this to filter the data by that entity.
    // If we're comparing we use it to filter by the compared entities instead.
    hierarchyLevel: computed(() =>
      selectedEntities.value.length === 0 && pk.value
        ? currentRouteHierarchyLevel.value
        : hierarchyLevel.value,
    ),
    tagHierarchyLevel: hierarchyLevel,
    selectedEntities: computed(() =>
      selectedEntities.value.length === 0 && pk.value
        ? [pk.value]
        : selectedEntities.value,
    ),
    selectedResourceIds,
    // For making stat cards always take in a comparison period
    temporalFilter,
  });
}

export function useMarketingEntity(
  currentRouteHierarchyLevel: Ref<MarketingHierarchyLevel | undefined>,
  pk: Ref<number | undefined>,
) {
  const account = useAccountQuery({
    pk,
    enabled: computed(
      () =>
        currentRouteHierarchyLevel.value === MarketingHierarchyLevel.ACCOUNT,
    ),
  });
  const keyword = useAdGroupKeywordQuery({
    pk,
    enabled: computed(
      () =>
        currentRouteHierarchyLevel.value === MarketingHierarchyLevel.KEYWORD,
    ),
  });
  const adGroup = useAdGroupQuery({
    pk,
    enabled: computed(
      () =>
        currentRouteHierarchyLevel.value === MarketingHierarchyLevel.ADGROUP,
    ),
  });
  const campaign = useCampaignQuery({
    pk,
    enabled: computed(
      () =>
        currentRouteHierarchyLevel.value === MarketingHierarchyLevel.CAMPAIGN,
    ),
  });
  return computed(() => {
    if (currentRouteHierarchyLevel.value === MarketingHierarchyLevel.ACCOUNT) {
      return account;
    } else if (
      currentRouteHierarchyLevel.value === MarketingHierarchyLevel.CAMPAIGN
    ) {
      return campaign;
    } else if (
      currentRouteHierarchyLevel.value === MarketingHierarchyLevel.ADGROUP
    ) {
      return adGroup;
    } else if (
      currentRouteHierarchyLevel.value === MarketingHierarchyLevel.KEYWORD
    ) {
      return keyword;
    } else {
      return undefined;
    }
  });
}

export function useBulkEditMarketingPerformanceTagsMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: bulkEditMarketingPerformanceTags,
      onSettled: (_, __, { tableHierarchyLevel }) => {
        if (tableHierarchyLevel === MarketingHierarchyLevel.AD) {
          queryClient.invalidateQueries({
            queryKey: ["ads"],
          });
        } else if (tableHierarchyLevel === MarketingHierarchyLevel.ADGROUP) {
          queryClient.invalidateQueries({
            queryKey: ["adGroups"],
          });
        } else if (tableHierarchyLevel === MarketingHierarchyLevel.CAMPAIGN) {
          queryClient.invalidateQueries({
            queryKey: ["campaigns"],
          });
        } else if (tableHierarchyLevel === MarketingHierarchyLevel.ACCOUNT) {
          queryClient.invalidateQueries({
            queryKey: ["accounts"],
          });
        }
      },
    }),
  );
}

interface BulkEditParams {
  dataFilters: TemporalDataFilter & TagsDataFilter & AdNetworkFilter;
  includedResources: number[];
  entityId: number | undefined;
  routeHierarchyLevel?: MarketingHierarchyLevel;
  tableHierarchyLevel: MarketingHierarchyLevel;
  tagsToAdd: Tag[];
  tagsToRemove: Tag[];
  search?: string;
  filterCriteria?: MetricFilterSet[];
}

function bulkEditMarketingPerformanceTags({
  dataFilters,
  includedResources,
  entityId,
  routeHierarchyLevel,
  tableHierarchyLevel,
  tagsToAdd,
  tagsToRemove,
  search,
  filterCriteria,
}: BulkEditParams): Promise<void> {
  const formattedFilterCriteria = filterCriteriaFormatter(filterCriteria);
  let hierarchyEndpoint: string;
  if (tableHierarchyLevel === MarketingHierarchyLevel.AD) {
    hierarchyEndpoint = "ads";
  } else if (tableHierarchyLevel === MarketingHierarchyLevel.ADGROUP) {
    hierarchyEndpoint = "ad_groups";
  } else if (tableHierarchyLevel === MarketingHierarchyLevel.ACCOUNT) {
    hierarchyEndpoint = "accounts";
  } else {
    hierarchyEndpoint = "campaigns";
  }

  const campaign =
    routeHierarchyLevel === MarketingHierarchyLevel.CAMPAIGN
      ? entityId
      : undefined;
  const adGroup =
    routeHierarchyLevel === MarketingHierarchyLevel.ADGROUP
      ? entityId
      : undefined;

  const selectedResourceIds =
    routeHierarchyLevel === MarketingHierarchyLevel.ACCOUNT
      ? [entityId as number]
      : includedResources;

  return api
    .post(
      `resources/marketing/${hierarchyEndpoint}/bulk_edit_tags/`,
      {
        tagsToAdd: tagsToAdd.map((tag) => tag.pk),
        tagsToRemove: tagsToRemove.map((tag) => tag.pk),
      },
      {
        params: {
          search,
          ...formattedFilterCriteria,
          ...dataFilters,
          campaign,
          adGroup,
          selectedResourceIds,
        },
      },
    )
    .then((response) => response.data);
}

export interface UpdateGoogleAdsRSADetails {
  pk: number;
  existingUrl: string;
  newUrl?: string | null;
  existingHeadlines: string[];
  newHeadlines?: string[] | null;
  existingDescriptions: string[];
  newDescriptions?: string[] | null;
}

export function useUpdateGoogleAdsRSAContentMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: updateGoogleAdsRSAContent,
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["ads"],
        });
      },
    }),
  );
}

export function useChangeCampaignBudgetMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: changeCampaignBudget,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["campaigns"] });
        queryClient.invalidateQueries({ queryKey: ["campaign"] });
        queryClient.invalidateQueries({
          queryKey: ["otherCampaignsWithSameBudget"],
        });
      },
    }),
  );
}

function updateGoogleAdsRSAContent(
  payload: UpdateGoogleAdsRSADetails,
): Promise<void> {
  return api
    .post(
      `resources/marketing/ads/${payload.pk}/update_google_ads_rsa_content/`,
      payload,
    )
    .then((response) => response.data);
}

function changeCampaignBudget(payload: {
  campaignId: number;
  newAmount: number;
  existingAmount: number;
  existingType: BudgetType;
  existingCurrency: string;
  existingIsShared: boolean;
  existingOtherCampaignsWithSameBudget: string[];
  force: boolean;
}) {
  return api.post(
    `resources/marketing/campaigns/${payload.campaignId}/change_budget/`,
    payload,
  );
}

export function useChangeAdGroupBudgetMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: changeAdGroupBudget,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["adGroups"] });
        queryClient.invalidateQueries({ queryKey: ["adGroup"] });
      },
    }),
  );
}

function changeAdGroupBudget(payload: {
  adGroupId: number;
  newAmount: number;
  existingAmount: number;
  existingType: BudgetType;
  existingCurrency: string;
  force: boolean;
}) {
  return api.post(
    `resources/marketing/ad_groups/${payload.adGroupId}/change_budget/`,
    payload,
  );
}

function listOtherCampaignsWithSameBudget({
  queryKey: [, { pk }],
}: QueryFunctionContext<{
  pk: number;
}>): Promise<{ pk: number; name: string; externalId: string }[]> {
  return api
    .get(
      `/resources/marketing/campaigns/${pk}/other_campaigns_with_same_budget/`,
    )
    .then((response) => response.data);
}

export function useOtherCampaignsWithSameBudgetQuery({
  pk,
  enabled,
}: {
  pk: Ref<number | undefined>;
  enabled?: Ref<boolean>;
}) {
  const queryKey = [
    "otherCampaignsWithSameBudget",
    { pk: pk as Ref<number> },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listOtherCampaignsWithSameBudget,
      enabled: computed(
        () => pk.value !== undefined && (enabled?.value ?? true),
      ),
    }),
  );
}
