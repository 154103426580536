function getFractionDigits(value?: number): number {
  // Formats currencies 1000, 100, 10.0, 1.00, 0.00
  const v = Math.abs(value ?? 0);
  return v >= 100 ? 0 : v >= 10 ? 1 : 2;
}

export function formatCurrency(
  value: number | null | undefined,
  currencyCode?: string,
  fractionDigits?: number,
): string {
  if (value === null || value === undefined) return "-";
  return new Intl.NumberFormat([...navigator.languages, "no-NB"], {
    style: "currency",
    currency: currencyCode ?? "NOK",
    minimumFractionDigits: fractionDigits ?? getFractionDigits(value),
    maximumFractionDigits: fractionDigits ?? getFractionDigits(value),
  }).format(value);
}

export function formatCurrencyCompact(
  value: number | null | undefined,
  currencyCode?: string,
  options = {},
): string {
  if (value === null || value === undefined) return "-";
  return new Intl.NumberFormat([...navigator.languages, "no-NB"], {
    style: "currency",
    notation: "compact",
    maximumSignificantDigits: 3,
    currency: currencyCode ?? "NOK",
    ...options,
  }).format(value);
}
