<template>
  <button
    class="w-full border-b border-gray-200 p-4 text-left hover:bg-gray-100 focus:outline-none focus:ring focus:ring-inset"
    :class="notification.readStatus === 'READ' ? 'bg-white' : 'bg-gray-50'"
    aria-label="Go to notification"
    @click="onClickNotification"
  >
    <div class="flex w-full flex-row justify-start font-sans">
      <div class="w-full">
        <component
          :is="typeToComponentName && loadedComponents[typeToComponentName]"
          :notification="notification"
        />
      </div>
      <div
        v-if="notification.readStatus !== 'READ'"
        class="place-self-center justify-self-end pl-4"
      >
        <Icon name="circle-solid" class="size-3 text-blue-600" />
        <span class="sr-only">Unread</span>
      </div>
    </div>
  </button>
</template>

<script lang="ts">
import { defineAsyncComponent } from "vue";
import type { Component } from "vue";
import { pathToFileName } from "@/utils/path";
import { useUpdateSelectedOrganization } from "@/services/user";
import { pascalCase } from "@/utils/strings";

function loadNotificationComponents(): Record<string, Component> {
  const components = import.meta.glob<{ default: Component }>(
    "/src/components/Core/Notifications/*.vue",
  );
  return Object.fromEntries(
    Object.entries(components).map(([path, component]) => {
      return [pathToFileName(path), defineAsyncComponent(component)];
    }),
  );
}

const loadedComponents = loadNotificationComponents();
</script>

<script setup lang="ts">
import Icon from "@/components/Core/Icon.vue";
import { computed, PropType, onMounted } from "vue";
import {
  getNotificationCtaLink,
  Notification,
  useUpdateNotificationMutation,
  NotificationType,
} from "@/services/notifications";
import { useRouter } from "vue-router";

const props = defineProps({
  notification: {
    type: Object as PropType<Notification>,
    required: true,
  },
});
const emit = defineEmits({
  close: () => true,
});
const router = useRouter();
function hasComponent(name: string): boolean {
  return (
    loadedComponents[name] !== undefined && loadedComponents[name] !== null
  );
}

const typeToComponentName = computed(() => {
  if (hasComponent(`${pascalCase(props.notification.type)}Notification`)) {
    return `${pascalCase(props.notification.type)}Notification`;
  } else {
    console.warn(
      `Warning! Couldn't find a notification-component for Notification ${props.notification.type}`,
    );
    return undefined;
  }
});

const updateSelectedOrganization = useUpdateSelectedOrganization();

function onClickNotification(): void {
  const copiedNotification = Object.assign({}, props.notification);
  copiedNotification.readStatus = "READ";
  updateNotificationMutation.mutate(copiedNotification);
  updateSelectedOrganization(props.notification.organization).then(() => {
    if (props.notification.type === NotificationType.NEW_FEATURE_TOKEN) {
      window.location.href = getNotificationCtaLink(
        props.notification,
      ) as string;
    } else {
      router.push(getNotificationCtaLink(props.notification));
    }

    emit("close");
  });
}

const updateNotificationMutation = useUpdateNotificationMutation();
onMounted(() => {
  if (props.notification.readStatus === "UNREAD") {
    updateNotificationMutation.mutate({
      pk: props.notification.pk,
      readStatus: "PARTIALLY_READ",
    });
  }
});
</script>
