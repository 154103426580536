import { Dashboard } from "@/services/dashboard";
import { computed, defineComponent, inject, provide, Ref } from "vue";

export interface PublicDashboardFilters {
  dashboard: number | undefined;
  token: string | undefined;
  password: string | null | undefined;
}

export type PublicDashboardWidgetFilters = PublicDashboardFilters & {
  widgetIndex: number | undefined;
};

export interface PublicDashboardQueryFilters {
  dashboard?: Ref<number | undefined>;
  token?: Ref<string>;
  password?: Ref<string | null>;
}

export type PublicDashboardWidgetQueryFilters = PublicDashboardQueryFilters & {
  widgetIndex?: Ref<number>;
};

export function providePublicDashboardQueryFilters(
  dashboard: Ref<number | undefined>,
  token: Ref<string>,
  password: Ref<string | null>,
): void {
  provide("publicDashboardQueryFilters", {
    dashboard,
    token,
    password,
  });
}

export function usePublicDashboardQueryFilters(): {
  [K in keyof Required<PublicDashboardQueryFilters>]:
    | PublicDashboardQueryFilters[K]
    | undefined;
} {
  return inject("publicDashboardQueryFilters", {
    dashboard: undefined,
    token: undefined,
    password: undefined,
  });
}

export function providePublicDashboard(
  dashboard: Ref<Dashboard | undefined>,
): void {
  provide("publicDashboard", dashboard);
}

export function usePublicDashboard(): Ref<Dashboard | undefined> | undefined {
  return inject("publicDashboard", undefined);
}

export function provideWidgetIndex(widgetIndex: Ref<number>): void {
  provide("publicDashboardWidgetIndex", widgetIndex);
}

export function usePublicDashboardWidgetQueryFilters(): {
  [K in keyof Required<PublicDashboardWidgetQueryFilters>]:
    | PublicDashboardWidgetQueryFilters[K]
    | undefined;
} {
  return {
    ...usePublicDashboardQueryFilters(),
    widgetIndex: inject("publicDashboardWidgetIndex", undefined),
  };
}

export const PublicDashboardWidgetIndexProvider = defineComponent({
  props: {
    widgetIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props, { slots }) {
    provideWidgetIndex(computed(() => props.widgetIndex));
    return () => slots.default?.();
  },
});
