import { isURL } from "@/utils/url";
import { Chunk, ChunkType } from "@/services/strings";
import { camelCase, capitalize, startCase, upperFirst } from "lodash-es";

export const alphanumericRegex = /^[\p{L}\p{N}]*$/u;
export const emojiPresentationRegex = /\p{Emoji_Presentation}/gu;
export const symbolRegex = /\p{Symbol}/gu;

export function hasAlphaNumerics(value: string): boolean {
  const match = value.match(alphanumericRegex);
  return (match && match.length > 0) || false;
}

export function hasEmojis(value: string): boolean {
  const match = value.match(emojiPresentationRegex);
  return (match && match.length > 0) || false;
}

export function hasSymbols(value: string): boolean {
  const match = value.match(symbolRegex);
  return (match && match.length > 0) || false;
}

/**
 * Returns the text chunked as an array with URLs being their own elements. `NOTE:` Removes newlines
 *
 * `chunkByUrlAndNewLine("Hello https://www.example.com world I \n am so happy")`
 *
 * => `["Hello", "https://www.example.com", "world I", "am so happy"]`
 */
export function chunkByUrlAndNewLine(value: string): string[] {
  const splitByNewLines = value.split(/\r?\n/);
  const result: string[] = [];
  const hashtag = /#[a-zA-Z0-9]*/gm;

  splitByNewLines.forEach((paragraph, _idx) => {
    const splitByWhitespace = paragraph.split(/\s+/);
    const out: string[] = [];
    let temp = "";
    splitByWhitespace.forEach((word, idx) => {
      if (isURL(word)) {
        if (temp.length > 0) {
          out.push(temp);
        }
        out.push(word);
        temp = "";
      } else if (hashtag.exec(word)) {
        out.push(`<button>${word}</button>`);
      } else {
        temp += temp.length === 0 ? `${word}` : ` ${word}`;
      }
      if (idx === splitByWhitespace.length - 1 && temp.length > 0) {
        out.push(temp.trim());
      }
    });

    result.push(...out);
  });

  return result;
}

export function structureString(value: string): Chunk[][] {
  const splitByNewLines = value.split(/\r?\n/);
  const output = [] as Chunk[][];
  const hashtag = /#\w*\b/;

  splitByNewLines.forEach((paragraph) => {
    const splitByWhitespace = paragraph.split(/\s+/);
    let text = "";
    const paragraphArray = [] as Chunk[];
    splitByWhitespace.forEach((word, _idx) => {
      if (isURL(word)) {
        if (text.length > 0) {
          paragraphArray.push({ type: ChunkType.Text, value: text });
          text = "";
        }
        paragraphArray.push({ type: ChunkType.Url, value: word });
      } else if (hashtag.exec(word)) {
        if (text.length > 0) {
          paragraphArray.push({ type: ChunkType.Text, value: text });
          text = "";
        }
        paragraphArray.push({ type: ChunkType.Hashtag, value: word });
      } else {
        text += text.length === 0 ? `${word}` : ` ${word}`;
      }
    });
    if (text.length > 0) {
      paragraphArray.push({ type: ChunkType.Text, value: text });
    }
    if (paragraphArray.length > 0) {
      output.push(paragraphArray);
    }
  });

  return output;
}

export function pluralize(
  word: string,
  count: number,
  plural = word + "s",
): string {
  let p;
  if (plural) p = plural;
  if (word[word.length - 1] === "y") p = word.slice(0, word.length - 1) + "ies";
  return count !== 1 ? `${p}` : word;
}

export function indefiniteArticle(word: string, ignoreSilentH = false): string {
  if (word.length <= 2) {
    return "";
  }
  const vowels = ["a", "e", "i", "o", "u", "w", "y"];
  const first = word[0];
  const second = word[1];

  // If the word starts with an "H", look at the second letter.
  // Note: Not bullet proof, i.e. "a horse" vs. "an hour"

  if (
    vowels.includes(first.toLowerCase()) ||
    (!ignoreSilentH &&
      first.toLowerCase() === "h" &&
      vowels.includes(second.toLowerCase()))
  ) {
    return "an";
  }
  return "a";
}

export function pascalCase(val: string): string {
  return upperFirst(camelCase(val));
}

export function sentenceCase(val: string): string {
  // "adGroup" => "Ad group"
  return capitalize(startCase(val));
}

export function normalCase(val: string): string {
  // "adGroup" => "ad group"
  return startCase(val).toLowerCase();
}

// ["foo", "bar", "baz"] => "foo, bar and baz"
export function joinWithTrailingAnd(strings: string[]) {
  let joined = "";
  strings.forEach((string, index) => {
    joined += string;
    if (index < strings.length - 2) {
      joined += ", ";
    } else if (index === strings.length - 2) {
      joined += " and ";
    }
  });
  return joined;
}
