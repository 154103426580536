export enum ChunkType {
  Text = "text",
  Url = "url",
  Hashtag = "hashtag",
}

export interface Chunk {
  type: ChunkType;
  value: string;
}
