import { MARKETING_RESOURCE_CHANNELS } from "@/services/marketing";
import { RouteRecordRaw } from "vue-router";
export const salesRoutes: RouteRecordRaw[] = [
  {
    path: "spending-report",
    name: "spending-report",
    meta: {
      title: "Spending Report",
      text: "Spending Report",
      description: "See how your spending compares to your budget",
      added: "2021-03-23",
      icon: "money-bill-wave",
      requiredResources: MARKETING_RESOURCE_CHANNELS,
      helpDeskPath: "spending-report",
    },
    component: () => import("@/views/SpendingReport/SpendingReport.vue"),
  },
];
