import { logout } from "@/services/auth";
import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    isAuthed: useLocalStorage("userIsLoggedIn", false),
  }),
  actions: {
    setLoggedIn(): void {
      this.isAuthed = true;
    },
    setLoggedOut(): void {
      this.isAuthed = false;
    },
    logout(): Promise<void> {
      return logout().then(() => {
        this.setLoggedOut();
      });
    },
  },
});
