export function formatNumber(
  value: number | undefined | null,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 2 },
  nullCase = "-",
): string {
  if (value === null) return nullCase;
  return new Intl.NumberFormat(
    [...navigator.languages, "en-US"],
    options,
  ).format(value ?? 0);
}

export function formatNumberCompact(
  value: number | undefined | null,
  options: Intl.NumberFormatOptions = {},
  nullCase = "-",
): string {
  if (value === null) return nullCase;
  return new Intl.NumberFormat([...navigator.languages, "en-US"], {
    notation: "compact",
    maximumSignificantDigits: 3,
    ...options,
  }).format(value ?? 0);
}
