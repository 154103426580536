export interface GuidedMenu {
  id: string;
  title: string;
  description?: string;
}

import slide1 from "@/assets/guided_tour/slide1.png";
import slide2 from "@/assets/guided_tour/slide2.png";
import slide3 from "@/assets/guided_tour/slide3.png";
import slide4 from "@/assets/guided_tour/slide4.png";
import slide5 from "@/assets/guided_tour/slide5.png";
import slide6 from "@/assets/guided_tour/slide6.png";

export const images = [slide1, slide2, slide3, slide4, slide5, slide6];

export const GuidedMenu: GuidedMenu[] = [
  {
    id: "feed",
    title: "Here's the Feed now",
    description:
      "<p class='mb-5'>Where you can stay on top of the latest updates regarding your sales and marketing.</p><p><strong>You will get an overview of what's happening around your pages.</strong></p>",
  },
  {
    id: "Analytics",
    title: "Analytics",
    description:
      "<p class='mb-5'>Analyse what's happening across your digital  channels and <strong>take instant action</strong>.</p><p>Set goals, stay on top of your marketing campaigns,  unlock comprehensive website analytics, track your  website activity and discover keywords.</p>",
  },
  {
    id: "Insights",
    title: "Insights",
    description:
      "<p>Discover actionable improvements in your digital  marketing <strong>applying recommendations</strong> and <strong>automations</strong>, and spy on what your <strong>competitors</strong>  are doing on social media.</p>",
  },
  {
    id: "Content lab",
    title: "Content lab",
    description:
      "<p>Use Fyr's advanced machine learning algorithms to  help you <strong>create new content with AI</strong>, and get  insight about where users are coming from and what  engages users with the <strong>UTM URL Builder</strong>.</p>",
  },
  {
    id: "Budget",
    title: "Budget",
    description:
      "<p>See how your spending compares to your budget.  <strong>Ensure optimal budget allocation across all  your channels</strong> and adjust them directly in Fyr.</p>",
  },
  {
    id: "Reporting",
    title: "Reporting",
    description:
      "<p>Dynamically <strong>create custom dashboards</strong>  that display your current statistics, and  write and make <strong>reports for specific date  ranges</strong>.</p>",
  },
];
