export interface GuidedTourStep {
  attachTo: {
    /* CSS selector of the element that you want to highlight and appear next to */
    element: string;
  };
  content: {
    title: string;
    description?: string;
  };
  popperOptions?: {
    placement: string;
  };
}

export const GUIDED_TOURS: Record<string, GuidedTourStep[]> = {
  feed: [
    {
      attachTo: { element: "[id^='action-bar']" },
      content: {
        title: "We call this the actionbar!",
        description:
          "This element will appear through out the app and contains filters and actions for the current page.",
      },
    },
    {
      attachTo: { element: "#new-post-button" },
      content: {
        title: "Add new post to your organizations feed!",
        description:
          "The feed is a great way of sharing information with your organization. Share links, images and text.",
      },
    },
    {
      attachTo: { element: "[data-index]" },
      content: {
        title: "This is an example of content in your feed.",
        description:
          "It will contain automatically generated insights from Fyr to keep you updated on your digital marketing, or posts from other people in your organization.",
      },
    },
    {
      attachTo: { element: "#recommendation-summary" },
      content: {
        title:
          "Here you can see all open recommendations for your organization!",
        description:
          "Fyr will generate recommendations for you based on your organizations data. Here you can see a summary of all recommendations.",
      },
    },
    {
      attachTo: { element: "#pinned-goals" },
      content: {
        title: "Pin your goals!",
        description:
          "Easily monitor your Goal progress from the feed and dashboards by pinning your Goals.",
      },
    },
    {
      attachTo: { element: "#sitescore-summary" },
      content: {
        title: "From the sidebar you can also see your site score results.",
        description: "Easily see how your site is scoring.",
      },
    },
    {
      attachTo: { element: "#feed-tips" },
      content: {
        title: "Want to get more out of Fyr?",
        description:
          "Fyr has a lot of features, here are some tips on some of them to get you started.",
      },
    },
  ],
  "reporting-overview": [
    {
      attachTo: { element: "#create-new-report" },
      content: {
        title: "Create a new report!",
        description:
          "Reports help you communicate your marketing performance to your organization. You can create as many as you want, and share them with your organization.",
      },
    },
  ],
  "dashboard-overview": [
    {
      attachTo: { element: "#create-new-dashboard" },
      content: {
        title: "Create a new dashboard!",
        description:
          "Dashboards are a great way of visualizing your data. Share them with your organization or external stakeholders.",
      },
    },
    {
      attachTo: { element: "[id^='dashboard-list-element-']" },
      content: {
        title: "Your organizations dashboards will appear here.",
        description:
          "From here you can either view, edit, delete or share the dashboard.",
      },
    },
  ],
  "ad-performance-overview": [
    {
      attachTo: { element: "[id^='action-bar']" },
      content: {
        title: "We call this the action bar!",
        description:
          "This element will appear through out the app, and contains filters and actions for the current page.",
      },
    },
    {
      attachTo: { element: "#stat-card-radio-button-group" },
      content: {
        title: "This is a birdseye view of your ad performance.",
        description:
          "Clicking these will update the chart and sort the table below, allowing you to dig deeper into your data.",
      },
    },
    {
      attachTo: { element: "#compare-metrics" },
      content: {
        title: "Compare metrics",
        description:
          "Toggle this and click 2 of the cards above to compare metrics in the graph.",
      },
    },
    {
      attachTo: { element: "#hierarchy-level-radio-group" },
      content: {
        title: "Change the granularity of the data in the table.",
      },
    },
    {
      attachTo: { element: "#marketing-performance-table-metric-filter" },
      content: {
        title: "Want to slice and dice your data?",
        description:
          "Use the metric filter to find campaigns based on metric filters, such as cost per click, or click through rate.",
      },
    },
    {
      attachTo: { element: "#marketing-performance-table" },
      content: {
        title:
          "Your accounts, campaigns, ad groups and ads will be listed alongside useful metrics.",
        description:
          "You can click on a specific item name to get more detailed information, or use the filter to find the specific insights you're looking for. You can also sort by clicking any of the metrics.",
      },
      popperOptions: {
        placement: "right",
      },
    },
  ],
  "spending-report": [
    {
      attachTo: { element: "#spending-report-total-budget-left" },
      content: {
        title:
          "Based on your current budget, you have this much left this year.",
      },
    },
    {
      attachTo: { element: "#spending-report-monthly-budget-left" },
      content: {
        title:
          "This is how much you have left this month based on your budget.",
      },
    },
    {
      attachTo: { element: "#spending-report-average-daily-spend" },
      content: {
        title: "This is how much you spend on average this month.",
      },
    },
    {
      attachTo: { element: "#spend-this-year-graph" },
      content: {
        title: "Here is a breakdown of your spend this year, by month.",
        description:
          "The gray line represents your budget, and the bar graph represents your spend.",
      },
      popperOptions: {
        placement: "right",
      },
    },
    {
      attachTo: { element: "#budget-burndown-rate" },
      content: {
        title:
          "Based on your current spend, this is your budget burndown rate.",
        description:
          "The blue line is the ideal burndown rate, while the gray points are spend per day. The doted line is your projected burndown, based on your current spend rate.",
      },
      popperOptions: {
        placement: "right",
      },
    },
    {
      attachTo: { element: "#total-spend-current-year-chart" },
      content: {
        title: "This is your total spend this year.",
      },
      popperOptions: {
        placement: "left",
      },
    },
  ],
  "search-term-report": [
    {
      attachTo: { element: "#stat-card-radio-button-group" },
      content: {
        title: "This is a birdseye view of your marketing performance.",
        description:
          "Clicking these will update the chart and table, allowing you to dig deeper into your data.",
      },
      popperOptions: {
        placement: "bottom",
      },
    },
    {
      attachTo: { element: "#search-term-compare-metrics" },
      content: {
        title: "Compare metrics",
        description:
          "Toggle this and click 2 of the cards above to compare metrics in the graph.",
      },
    },
    {
      attachTo: { element: "#search-term-report-table" },
      content: {
        title: "This is a list of all search terms.",
        description:
          "You can sort and filter the table to find the search terms you are looking for.",
      },
      popperOptions: {
        placement: "right",
      },
    },
    {
      attachTo: { element: "#search-term-table-metric-filter" },
      content: {
        title: "Want to slice and dice your data?",
        description:
          "Use the metric filter to find campaigns based on metric filters, such as cost per click, or click through rate.",
      },
    },
  ],
  "social-media-account-summary": [
    {
      attachTo: { element: "#stat-card-radio-button-group" },
      content: {
        title: "This is a birdseye view of your marketing performance.",
        description:
          "Clicking these will update the chart and table, allowing you to dig deeper into your data.",
      },
    },
  ],
  "website-analytics-acquisition-tab": [
    {
      attachTo: { element: "#stat-card-radio-button-group" },
      content: {
        title: "This is a birdseye view of your marketing performance.",
        description:
          "Clicking these will update the chart and table, allowing you to dig deeper into your data.",
      },
    },
  ],
  "website-analytics-conversions-tab": [
    {
      attachTo: { element: "#stat-card-sparkline-container" },
      content: {
        title: "Each card represent a conversion action from Google Anlaytics.",
        description:
          "The graph gives you a quick overview of the progression. You can click on the card to view the conversion action in more detail.",
      },
    },
    {
      attachTo: { element: "#breakdown-radio-button" },
      content: {
        title: "Change how to break down the data",
        description:
          "You can view each conversion on aggregate, separately, or combined into categories. Categories are configured in each conversion.",
      },
    },
    {
      attachTo: { element: "#conversion-heatmap" },
      content: {
        title: "This is a heatmap of your conversions.",
        description:
          "The darker the color, the more conversions. You can hover over each cell to see the exact number.",
      },
      popperOptions: {
        placement: "left",
      },
    },
    {
      attachTo: { element: "#conversions-table" },
      content: {
        title: "Here you can see the source of your conversions.",
        description:
          "You can sort and filter the table to find the conversions you are looking for.",
      },
    },
  ],
  "website-analytics-conversion-details": [
    {
      attachTo: { element: "#conversion-category" },
      content: {
        title: "This is the category of the conversion.",
        description:
          "You can change the category in the conversion settings. Categories are used to group conversions together.",
      },
    },
    {
      attachTo: { element: "#conversion-settings-button" },
      content: {
        title: "Here you can change the conversion settings.",
        description:
          "You can change the category, name, and goal value of the conversion.",
      },
    },
    {
      attachTo: { element: "#conversion-details-chart" },
      content: {
        title: "Follow the progression of your conversion.",
      },
    },
    {
      attachTo: { element: "#conversion-details-heatmap" },
      content: {
        title: "With a heatmap, you can see when the conversion happens.",
        description:
          "Use this information to optimize your marketing campaigns.",
      },
    },
    {
      attachTo: { element: "#conversion-details-table" },
      content: {
        title: "Here you can see the source of your conversions.",
        description:
          "You can sort and filter the table to find the conversions you are looking for.",
      },
    },
    {
      attachTo: { element: "#goal-container" },
      content: {
        title: "Setup a goal to keep tabs on your progress.",
      },
    },
  ],
  "site-score": [
    {
      attachTo: { element: "#performance-gauge" },
      content: {
        title:
          "This an average of how well your site is performing in terms of loading time and performance.",
      },
    },
    {
      attachTo: { element: "#best-practice-gauge" },
      content: {
        title:
          "Best practice is a calculated score to determine if your pages follow best practices within web development.",
        description:
          "The higher the score, the better your site is performing.",
      },
    },
    {
      attachTo: { element: "#accessibility-gauge" },
      content: {
        title:
          "Accessibility is a calculated score to determine if your site is accessible.",
        description:
          "The higher the score, the better your site is performing.",
      },
    },
    {
      attachTo: { element: "#seo-gauge" },
      content: {
        title:
          "While the SEO score is showing you how well your site is optimized for search engines.",
        description:
          "The higher the score, the better your site is performing. ",
      },
    },
    {
      attachTo: { element: "#site-score-graph" },
      content: {
        title: "This is a graph of your site average score over time.",
        description:
          "This will help you determine if your site is improving or not.",
      },
      popperOptions: {
        placement: "right",
      },
    },
  ],
  "page-insights-search": [
    {
      attachTo: { element: "#pages-table" },
      content: {
        title: "This is a list of all your pages.",
        description:
          "You can sort and filter the table to find the pages you are looking for.",
      },
      popperOptions: {
        placement: "right",
      },
    },
    {
      attachTo: { element: "#search-input-container" },
      content: {
        title: "Search for a page to get started.",
      },
      popperOptions: {
        placement: "bottom",
      },
    },
  ],
  ecommerce: [
    {
      attachTo: { element: "#e-commerce-report-charts" },
      content: {
        title: "Get an overview of your latest sales.",
        description:
          "See revenue, transactions, average order value, and conversion rate. Use the filters to break down the data on tags, brands or product categories.",
      },
      popperOptions: {
        placement: "top",
      },
    },
    {
      attachTo: { element: "#product-table-container" },
      content: {
        title: "Here you can see your top selling products.",
        description:
          "You can sort and filter the table to find the products you are looking for. Use tags to further categorize your products.",
      },
      popperOptions: {
        placement: "right",
      },
    },
    {
      attachTo: { element: "#daily-breakdown-metric" },
      content: {
        title: "Choose which metric you're looking at.",
      },
    },
    {
      attachTo: { element: "#daily-breakdown-chart" },
      content: {
        title: "This shows the data broken down by day of the week.",
      },
    },
    {
      attachTo: { element: "#daily-breakdown-heatmap" },
      content: {
        title: "This shows the data broken down by hour of the day.",
      },
    },
    {
      attachTo: { element: "#source-medium-graph" },
      content: {
        title: "This shows the source of your sales.",
      },
    },
    {
      attachTo: { element: "#source-medium-table" },
      content: {
        title: "This shows the source of your sales.",
      },
      popperOptions: {
        placement: "right",
      },
    },
  ],
  goals: [
    {
      attachTo: { element: "#goal-list" },
      content: {
        title: "This is a list of all your goals.",
        description: "Clicking one will expand to show more details.",
      },
    },
    {
      attachTo: { element: "#create-goal-button" },
      content: {
        title: "Create a new goal.",
        description:
          "Goals are used to track progress of your Google Analytics 4 conversions. You can set monthly, quarterly or yearly targets.",
      },
    },
  ],
  "all-recommendations": [
    {
      attachTo: { element: "#recommendations-container" },
      content: {
        title: "This is a list of all your recommendations.",
        description:
          "Recommendations are automatically created based on the accounts you've connected. Clicking one will expand to show more details.",
      },
    },
    {
      attachTo: { element: "[id^=recommendation-card]" },
      content: {
        title: "This is a recommendation.",
        description:
          "Every recommendation has a title, a description and a priority. The priority is based on the impact the recommendation will have on your marketing.",
      },
    },
    {
      attachTo: { element: "#assistance-card" },
      content: {
        title: "If you ever need help, click here and we'll reach out.",
      },
    },
  ],
  "competitor-overview": [
    {
      attachTo: { element: "#tab-navigation-overview" },
      content: {
        title: "This is a list of all your competitors.",
      },
    },
    {
      attachTo: { element: "#tab-navigation-posts" },
      content: {
        title: "View your competitors social media posts.",
      },
    },
    {
      attachTo: { element: "#tab-navigation-share-of-search" },
      content: {
        title: "Get on top of your Share of Search",
        description:
          "Share of Search will help you meassure the impact of your marketing efforts and compare it with your competitors.",
      },
    },
    {
      attachTo: { element: "#tab-navigation-competitors" },
      content: {
        title: "Configure competitors to monitor",
      },
    },
    {
      attachTo: { element: "#followers-chart-container" },
      content: {
        title:
          "Here you can see the number of followers per competitor over time.",
      },
    },
    {
      attachTo: { element: "#competitor-stat-posts-per-day" },
      content: {
        title:
          "This shows the average number of posts per day your competitor has posted.",
        description: "You can also compare this to your own average.",
      },
    },
    {
      attachTo: { element: "#competitor-stat-number-of-posts" },
      content: {
        title:
          "This shows the total number of posts your competitor has posted in the selected period.",
        description: "You can also compare this to your own total.",
      },
    },
    {
      attachTo: { element: "#competitor-stat-follower-growth" },
      content: {
        title: "This shows the follower growth of your competitor.",
        description: "You can also compare this to your own growth.",
      },
    },
  ],
};
