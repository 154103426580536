import { RouteRecordRaw } from "vue-router";
import { createOrganizationRoute, profileRoute, settingsRoute } from "@/router";
import { addResourceRoute } from "@/router/organization_settings_routes";

export const settingRoutes: RouteRecordRaw[] = [
  profileRoute,
  settingsRoute,
  addResourceRoute,
  createOrganizationRoute,
];
