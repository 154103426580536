import * as Sentry from "@sentry/vue";
import { useRoute } from "vue-router";
import axios, { Method } from "axios";
import qs from "qs";
import { useAuthStore } from "@/store/auth";

export const baseUrl = `${location.protocol}//${location.host}`;

const apiOptions = {
  baseURL: `${baseUrl}/api/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  paramsSerializer: {
    serialize: (params: Record<string, string | number | null>): string => {
      return qs.stringify(params, { arrayFormat: "comma", skipNulls: true });
    },
  },
};
const api = axios.create(apiOptions);

export function useLogoutInterceptor(): void {
  const authStore = useAuthStore();
  const route = useRoute();
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        !error.request?.responseURL.includes("/api/oauth/xandr/authorize/") &&
        !error.request?.responseURL.includes("/api/oauth/kobler/authorize/") &&
        !error.request?.responseURL.includes("/api/oauth/c_alt/authorize/") &&
        !error.request?.responseURL.includes("/api/auth/invites/") &&
        route.meta?.requiresAuth &&
        error.response?.status === 403 &&
        authStore.isAuthed
      ) {
        authStore.setLoggedOut();
      } else if (error.response?.status === 403 && !route.meta?.requiresAuth) {
        Sentry.captureException({
          error: error,
          message: "403 Encountered in a non-authed route",
          route: route.path,
        });
      }
      return Promise.reject(error);
    },
  );
}

export default api;

export interface APIError {
  message: string;
  code: string;
}

export async function shortenUrl(
  url: string,
  slashtag?: string,
): Promise<string> {
  const rebrandlyHeaders = {
    "Content-Type": "application/json",
    // TODO: Figure out how to .env these without vite (dotenv error Module 'path' has been externalized due to browser compatibility)
    apikey: "", // import.meta.env.VITE_REBRANDLY_API_KEY as string,
    workspace: "", // import.meta.env.VITE_REBRANDLY_WORKSPACE_ID as string,
  };

  const endpoint = "https://api.rebrandly.com/v1/links";

  const linkRequest = {
    destination: url,
    domain: { fullName: "rebrand.ly" },
    slashtag: slashtag ?? undefined,
    //, title: "Rebrandly YouTube channel"
  };
  const apiCall = {
    method: "post" as Method,
    url: endpoint,
    data: linkRequest,
    headers: rebrandlyHeaders,
  };
  const apiResponse = await axios(apiCall);
  const link = apiResponse.data;
  return link.shortUrl;
}
