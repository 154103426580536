import {
  Recommendation,
  RecommendationStatus,
  RecommendationType,
} from "@/services/recommendations/recommendations";
import { DeepReadonly } from "vue";
export function recommendationHasStatus(
  r: DeepReadonly<Recommendation>,
): RecommendationStatus | undefined {
  if (r.todoCount > 0 || r.inProgressCount > 0) {
    return RecommendationStatus.OPEN;
  }
  if (r.todoCount === 0 && r.inProgressCount === 0 && r.doneCount > 0) {
    return RecommendationStatus.DONE;
  }
  if (r.taskCount > 0 && r.dismissedCount === r.taskCount) {
    return RecommendationStatus.DISMISSED;
  }
}

export function toURLParam(recommendationType: RecommendationType): string {
  return recommendationType.replace(/_/g, "-").toLowerCase();
}
