<template>
  <div
    v-if="user?.id === SystemUser.id"
    class="size-10 rounded-full bg-burgundy-900 p-2"
  >
    <img src="@/assets/Logomark.png" alt="" />
  </div>
  <div
    v-else
    class="flex items-center justify-center rounded-full font-medium text-gray-800 ring-1 ring-purple-200"
    :class="[avatarColor, size, fontSize]"
  >
    {{ initials.join("") }}
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { SystemUser } from "@/services/user";
import { sum } from "lodash-es";

const props = defineProps({
  user: {
    type: Object as PropType<{ id: number; name: string } | null>,
    default: null,
  },
  size: { type: String, default: "size-10" },
  fontSize: { type: String, default: "text-sm" },
});

const initials = computed(() => {
  if (!props.user?.name) {
    return [];
  }
  const names = props.user.name.split(" ");
  const initials = [names[0][0].toUpperCase()];
  if (names.length > 1 && names[names.length - 1]) {
    initials.push(names[names.length - 1][0].toUpperCase());
  }
  return initials;
});

const avatarColor = computed(() => {
  const colors = [
    "bg-peach-200",
    "bg-purple-500",
    "bg-purple-200",
    "bg-purple-600",
  ];
  return colors[
    sum(initials.value.map((c) => c.charCodeAt(0))) % colors.length
  ];
});
</script>
