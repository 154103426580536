export interface CountryType {
  name: string;
  alpha3: string;
}

// Exported with backend command
// poetry run task manage export_countries_json --for-frontend
// Flag available with CountryFlag component from vue-country-flag-next
// using alpha3 for lookup
export const ALLOWED_COUNTRIES = [
  { alpha3: "abw", name: "Aruba" },
  { alpha3: "afg", name: "Afghanistan" },
  { alpha3: "ago", name: "Angola" },
  { alpha3: "aia", name: "Anguilla" },
  { alpha3: "ala", name: "\u00c5land Islands" },
  { alpha3: "alb", name: "Albania" },
  { alpha3: "and", name: "Andorra" },
  { alpha3: "are", name: "United Arab Emirates" },
  { alpha3: "arg", name: "Argentina" },
  { alpha3: "arm", name: "Armenia" },
  { alpha3: "asm", name: "American Samoa" },
  { alpha3: "ata", name: "Antarctica" },
  { alpha3: "atf", name: "French Southern Territories" },
  { alpha3: "atg", name: "Antigua and Barbuda" },
  { alpha3: "aus", name: "Australia" },
  { alpha3: "aut", name: "Austria" },
  { alpha3: "aze", name: "Azerbaijan" },
  { alpha3: "bdi", name: "Burundi" },
  { alpha3: "bel", name: "Belgium" },
  { alpha3: "ben", name: "Benin" },
  { alpha3: "bes", name: "Bonaire, Sint Eustatius and Saba" },
  { alpha3: "bfa", name: "Burkina Faso" },
  { alpha3: "bgd", name: "Bangladesh" },
  { alpha3: "bgr", name: "Bulgaria" },
  { alpha3: "bhr", name: "Bahrain" },
  { alpha3: "bhs", name: "Bahamas" },
  { alpha3: "bih", name: "Bosnia and Herzegovina" },
  { alpha3: "blm", name: "Saint Barth\u00e9lemy" },
  { alpha3: "blr", name: "Belarus" },
  { alpha3: "blz", name: "Belize" },
  { alpha3: "bmu", name: "Bermuda" },
  { alpha3: "bol", name: "Bolivia" },
  { alpha3: "bra", name: "Brazil" },
  { alpha3: "brb", name: "Barbados" },
  { alpha3: "brn", name: "Brunei Darussalam" },
  { alpha3: "btn", name: "Bhutan" },
  { alpha3: "bvt", name: "Bouvet Island" },
  { alpha3: "bwa", name: "Botswana" },
  { alpha3: "caf", name: "Central African Republic" },
  { alpha3: "can", name: "Canada" },
  { alpha3: "cck", name: "Cocos (Keeling) Islands" },
  { alpha3: "che", name: "Switzerland" },
  { alpha3: "chl", name: "Chile" },
  { alpha3: "chn", name: "China" },
  { alpha3: "civ", name: "C\u00f4te d'Ivoire" },
  { alpha3: "cmr", name: "Cameroon" },
  { alpha3: "cod", name: "Congo, The Democratic Republic of the" },
  { alpha3: "cog", name: "Congo" },
  { alpha3: "cok", name: "Cook Islands" },
  { alpha3: "col", name: "Colombia" },
  { alpha3: "com", name: "Comoros" },
  { alpha3: "cpv", name: "Cabo Verde" },
  { alpha3: "cri", name: "Costa Rica" },
  { alpha3: "cub", name: "Cuba" },
  { alpha3: "cuw", name: "Cura\u00e7ao" },
  { alpha3: "cxr", name: "Christmas Island" },
  { alpha3: "cym", name: "Cayman Islands" },
  { alpha3: "cyp", name: "Cyprus" },
  { alpha3: "cze", name: "Czechia" },
  { alpha3: "deu", name: "Germany" },
  { alpha3: "dji", name: "Djibouti" },
  { alpha3: "dma", name: "Dominica" },
  { alpha3: "dnk", name: "Denmark" },
  { alpha3: "dom", name: "Dominican Republic" },
  { alpha3: "dza", name: "Algeria" },
  { alpha3: "ecu", name: "Ecuador" },
  { alpha3: "egy", name: "Egypt" },
  { alpha3: "eri", name: "Eritrea" },
  { alpha3: "esh", name: "Western Sahara" },
  { alpha3: "esp", name: "Spain" },
  { alpha3: "est", name: "Estonia" },
  { alpha3: "eth", name: "Ethiopia" },
  { alpha3: "fin", name: "Finland" },
  { alpha3: "fji", name: "Fiji" },
  { alpha3: "flk", name: "Falkland Islands (Malvinas)" },
  { alpha3: "fra", name: "France" },
  { alpha3: "fro", name: "Faroe Islands" },
  { alpha3: "fsm", name: "Micronesia, Federated States of" },
  { alpha3: "gab", name: "Gabon" },
  { alpha3: "gbr", name: "United Kingdom" },
  { alpha3: "geo", name: "Georgia" },
  { alpha3: "ggy", name: "Guernsey" },
  { alpha3: "gha", name: "Ghana" },
  { alpha3: "gib", name: "Gibraltar" },
  { alpha3: "gin", name: "Guinea" },
  { alpha3: "glp", name: "Guadeloupe" },
  { alpha3: "gmb", name: "Gambia" },
  { alpha3: "gnb", name: "Guinea-Bissau" },
  { alpha3: "gnq", name: "Equatorial Guinea" },
  { alpha3: "grc", name: "Greece" },
  { alpha3: "grd", name: "Grenada" },
  { alpha3: "grl", name: "Greenland" },
  { alpha3: "gtm", name: "Guatemala" },
  { alpha3: "guf", name: "French Guiana" },
  { alpha3: "gum", name: "Guam" },
  { alpha3: "guy", name: "Guyana" },
  { alpha3: "hkg", name: "Hong Kong" },
  { alpha3: "hmd", name: "Heard Island and McDonald Islands" },
  { alpha3: "hnd", name: "Honduras" },
  { alpha3: "hrv", name: "Croatia" },
  { alpha3: "hti", name: "Haiti" },
  { alpha3: "hun", name: "Hungary" },
  { alpha3: "idn", name: "Indonesia" },
  { alpha3: "imn", name: "Isle of Man" },
  { alpha3: "ind", name: "India" },
  { alpha3: "iot", name: "British Indian Ocean Territory" },
  { alpha3: "irl", name: "Ireland" },
  { alpha3: "irn", name: "Iran, Islamic Republic of" },
  { alpha3: "irq", name: "Iraq" },
  { alpha3: "isl", name: "Iceland" },
  { alpha3: "isr", name: "Israel" },
  { alpha3: "ita", name: "Italy" },
  { alpha3: "jam", name: "Jamaica" },
  { alpha3: "jey", name: "Jersey" },
  { alpha3: "jor", name: "Jordan" },
  { alpha3: "jpn", name: "Japan" },
  { alpha3: "kaz", name: "Kazakhstan" },
  { alpha3: "ken", name: "Kenya" },
  { alpha3: "kgz", name: "Kyrgyzstan" },
  { alpha3: "khm", name: "Cambodia" },
  { alpha3: "kir", name: "Kiribati" },
  { alpha3: "kna", name: "Saint Kitts and Nevis" },
  { alpha3: "kor", name: "South Korea" },
  { alpha3: "kwt", name: "Kuwait" },
  { alpha3: "lao", name: "Lao People's Democratic Republic" },
  { alpha3: "lbn", name: "Lebanon" },
  { alpha3: "lbr", name: "Liberia" },
  { alpha3: "lby", name: "Libya" },
  { alpha3: "lca", name: "Saint Lucia" },
  { alpha3: "lie", name: "Liechtenstein" },
  { alpha3: "lka", name: "Sri Lanka" },
  { alpha3: "lso", name: "Lesotho" },
  { alpha3: "ltu", name: "Lithuania" },
  { alpha3: "lux", name: "Luxembourg" },
  { alpha3: "lva", name: "Latvia" },
  { alpha3: "mac", name: "Macao" },
  { alpha3: "maf", name: "Saint Martin (French part)" },
  { alpha3: "mar", name: "Morocco" },
  { alpha3: "mco", name: "Monaco" },
  { alpha3: "mda", name: "Moldova" },
  { alpha3: "mdg", name: "Madagascar" },
  { alpha3: "mdv", name: "Maldives" },
  { alpha3: "mex", name: "Mexico" },
  { alpha3: "mhl", name: "Marshall Islands" },
  { alpha3: "mkd", name: "North Macedonia" },
  { alpha3: "mli", name: "Mali" },
  { alpha3: "mlt", name: "Malta" },
  { alpha3: "mmr", name: "Myanmar" },
  { alpha3: "mne", name: "Montenegro" },
  { alpha3: "mng", name: "Mongolia" },
  { alpha3: "mnp", name: "Northern Mariana Islands" },
  { alpha3: "moz", name: "Mozambique" },
  { alpha3: "mrt", name: "Mauritania" },
  { alpha3: "msr", name: "Montserrat" },
  { alpha3: "mtq", name: "Martinique" },
  { alpha3: "mus", name: "Mauritius" },
  { alpha3: "mwi", name: "Malawi" },
  { alpha3: "mys", name: "Malaysia" },
  { alpha3: "myt", name: "Mayotte" },
  { alpha3: "nam", name: "Namibia" },
  { alpha3: "ncl", name: "New Caledonia" },
  { alpha3: "ner", name: "Niger" },
  { alpha3: "nfk", name: "Norfolk Island" },
  { alpha3: "nga", name: "Nigeria" },
  { alpha3: "nic", name: "Nicaragua" },
  { alpha3: "niu", name: "Niue" },
  { alpha3: "nld", name: "Netherlands" },
  { alpha3: "nor", name: "Norway" },
  { alpha3: "npl", name: "Nepal" },
  { alpha3: "nru", name: "Nauru" },
  { alpha3: "nzl", name: "New Zealand" },
  { alpha3: "omn", name: "Oman" },
  { alpha3: "pak", name: "Pakistan" },
  { alpha3: "pan", name: "Panama" },
  { alpha3: "pcn", name: "Pitcairn" },
  { alpha3: "per", name: "Peru" },
  { alpha3: "phl", name: "Philippines" },
  { alpha3: "plw", name: "Palau" },
  { alpha3: "png", name: "Papua New Guinea" },
  { alpha3: "pol", name: "Poland" },
  { alpha3: "pri", name: "Puerto Rico" },
  { alpha3: "prk", name: "North Korea" },
  { alpha3: "prt", name: "Portugal" },
  { alpha3: "pry", name: "Paraguay" },
  { alpha3: "pse", name: "Palestine, State of" },
  { alpha3: "pyf", name: "French Polynesia" },
  { alpha3: "qat", name: "Qatar" },
  { alpha3: "reu", name: "R\u00e9union" },
  { alpha3: "rou", name: "Romania" },
  { alpha3: "rus", name: "Russian Federation" },
  { alpha3: "rwa", name: "Rwanda" },
  { alpha3: "sau", name: "Saudi Arabia" },
  { alpha3: "sdn", name: "Sudan" },
  { alpha3: "sen", name: "Senegal" },
  { alpha3: "sgp", name: "Singapore" },
  { alpha3: "sgs", name: "South Georgia and the South Sandwich Islands" },
  { alpha3: "shn", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { alpha3: "sjm", name: "Svalbard and Jan Mayen" },
  { alpha3: "slb", name: "Solomon Islands" },
  { alpha3: "sle", name: "Sierra Leone" },
  { alpha3: "slv", name: "El Salvador" },
  { alpha3: "smr", name: "San Marino" },
  { alpha3: "som", name: "Somalia" },
  { alpha3: "spm", name: "Saint Pierre and Miquelon" },
  { alpha3: "srb", name: "Serbia" },
  { alpha3: "ssd", name: "South Sudan" },
  { alpha3: "stp", name: "Sao Tome and Principe" },
  { alpha3: "sur", name: "Suriname" },
  { alpha3: "svk", name: "Slovakia" },
  { alpha3: "svn", name: "Slovenia" },
  { alpha3: "swe", name: "Sweden" },
  { alpha3: "swz", name: "Eswatini" },
  { alpha3: "sxm", name: "Sint Maarten (Dutch part)" },
  { alpha3: "syc", name: "Seychelles" },
  { alpha3: "syr", name: "Syrian Arab Republic" },
  { alpha3: "tca", name: "Turks and Caicos Islands" },
  { alpha3: "tcd", name: "Chad" },
  { alpha3: "tgo", name: "Togo" },
  { alpha3: "tha", name: "Thailand" },
  { alpha3: "tjk", name: "Tajikistan" },
  { alpha3: "tkl", name: "Tokelau" },
  { alpha3: "tkm", name: "Turkmenistan" },
  { alpha3: "tls", name: "Timor-Leste" },
  { alpha3: "ton", name: "Tonga" },
  { alpha3: "tto", name: "Trinidad and Tobago" },
  { alpha3: "tun", name: "Tunisia" },
  { alpha3: "tur", name: "Turkey" },
  { alpha3: "tuv", name: "Tuvalu" },
  { alpha3: "twn", name: "Taiwan" },
  { alpha3: "tza", name: "Tanzania" },
  { alpha3: "uga", name: "Uganda" },
  { alpha3: "ukr", name: "Ukraine" },
  { alpha3: "umi", name: "United States Minor Outlying Islands" },
  { alpha3: "ury", name: "Uruguay" },
  { alpha3: "usa", name: "United States" },
  { alpha3: "uzb", name: "Uzbekistan" },
  { alpha3: "vat", name: "Holy See (Vatican City State)" },
  { alpha3: "vct", name: "Saint Vincent and the Grenadines" },
  { alpha3: "ven", name: "Venezuela" },
  { alpha3: "vgb", name: "Virgin Islands, British" },
  { alpha3: "vir", name: "Virgin Islands, U.S." },
  { alpha3: "vnm", name: "Vietnam" },
  { alpha3: "vut", name: "Vanuatu" },
  { alpha3: "wlf", name: "Wallis and Futuna" },
  { alpha3: "wsm", name: "Samoa" },
  { alpha3: "yem", name: "Yemen" },
  { alpha3: "zaf", name: "South Africa" },
  { alpha3: "zmb", name: "Zambia" },
  { alpha3: "zwe", name: "Zimbabwe" },
] as const satisfies CountryType[];

export function getCountryByAlpha3(alpha3: string): CountryType | undefined {
  const countryObj = ALLOWED_COUNTRIES.find((c) => c.alpha3 === alpha3);
  return countryObj;
}
