import { Ref, watch } from "vue";
import * as lodash from "lodash-es";
import { watchPausable } from "@vueuse/core";

export function useResetPage<T extends { page: Ref<number> }>(
  page: Ref<number>,
  queryKey: readonly [string, T],
  ignoredKeys: readonly (keyof T)[] = [],
  enabled?: Ref<boolean>,
): void {
  const { pause, resume } = watchPausable(
    () => lodash.omit(queryKey[1], ["page", ...ignoredKeys]),
    () => {
      page.value = 1;
    },
    { deep: true },
  );
  watch(
    () => enabled?.value ?? true,
    (enabled) => {
      if (enabled) {
        resume();
      } else {
        pause();
      }
    },
    { immediate: true },
  );
}

export function circumventAdBlocker(s: string): string {
  const replacements = {
    google_ads: "gads",
    google_analytics: "ganalytics",
  };
  return Object.entries(replacements).reduce(
    (s, [from, to]) =>
      s.replace(from, to).replace(from.toUpperCase(), to.toUpperCase()),
    s,
  );
}

export interface PaginatedResponse<T> {
  next: string | null;
  previous: string | null;
  results: T[];
  count?: number;
}
