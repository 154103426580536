<template>
  <div
    v-if="showPopup"
    class="fixed inset-0 z-50 flex items-center justify-center font-artex"
  >
    <div class="fixed inset-0 bg-black opacity-50"></div>
    <BasePopupMessage
      :show="showPopup"
      class="relative z-50 max-w-lg bg-white p-4 shadow-2xl sm:rounded-lg"
    >
      <div class="size-full">
        <div class="flex items-center justify-between">
          <span
            class="inline-flex rounded-full border-2 border-violet-500 px-2 py-0.5 text-center text-xs font-bold leading-none text-violet-500"
          >
            NEW
          </span>
          <div class="">
            <button
              class="text-gray-400 hover:text-gray-500"
              @click="dismissPopup"
            >
              <span class="sr-only">Close</span>
              <Icon
                name="times"
                class="size-4 text-gray-600"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        <div v-if="hasMultiplePages" class="mt-1 text-xs text-gray-600">
          Update {{ currentPageIndex + 1 }} of {{ feature.pages.length }}
        </div>

        <div class="mt-2 flex items-start justify-between">
          <div class="flex-1">
            <div v-if="currentPage.videoUrl" class="min-h-[270]">
              <VideoPlayer :video-url="currentPage.videoUrl" />
            </div>
            <div v-if="currentPage.imageUrl" class="min-h-[270]">
              <img :src="currentPage.imageUrl" class="w-full" />
            </div>
            <h2 class="mt-5 whitespace-pre-line font-bold text-gray-700">
              {{ currentPage.title }}
            </h2>
            <div class="mt-1 text-gray-700">
              <p>{{ currentPage.description }}</p>
            </div>
          </div>
        </div>

        <div class="mt-4 flex items-center font-sans">
          <button
            v-if="currentPageIndex > 0"
            class="btn mr-auto text-sm text-gray-700 hover:text-gray-900"
            @click="previousPage"
          >
            Previous
          </button>
          <button
            class="btn-primary ml-auto text-sm text-white hover:text-white"
            :aria-label="
              currentPageIndex === (feature.pages?.length ?? 0) - 1
                ? 'Accept'
                : 'Next'
            "
            @click="handleNextButtonClick"
          >
            {{
              currentPageIndex === (feature.pages?.length ?? 0) - 1
                ? "Accept"
                : "Next"
            }}
          </button>
        </div>
      </div>
    </BasePopupMessage>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import BasePopupMessage from "@/components/Core/PopupMessages/BasePopupMessage.vue";
import Icon from "@/components/Core/Icon.vue";
import VideoPlayer from "@/components/Forms/VideoPlayer.vue";
import { NEW_FEATURE_POP_UP } from "@/utils/newFeature";

const props = defineProps({
  featureKey: {
    type: String,
    required: true,
  },
});

const currentPageIndex = ref(0);
const showPopup = ref(false);

const feature = computed(() => NEW_FEATURE_POP_UP[props.featureKey] ?? {});
const currentPage = computed(
  () =>
    feature.value.pages?.[currentPageIndex.value] ?? {
      title: "",
      description: "",
      videoUrl: "",
    },
);
const hasMultiplePages = computed(() => feature.value.pages?.length > 1);

const route = useRoute();

watch(
  () => props.featureKey,
  (newFeatureKey) => {
    const feature = NEW_FEATURE_POP_UP[newFeatureKey];
    if (!feature) return;

    showPopup.value =
      localStorage.getItem(`dismissedFeaturePopup-${feature.id}`) !== "true" &&
      localStorage.getItem("dismissedSelectedPopups") !== "true";
  },
  { immediate: true },
);

watch(
  () => route.name,
  () => {
    currentPageIndex.value = 0;
  },
);

function nextPage() {
  if (currentPageIndex.value < (feature.value.pages?.length ?? 0) - 1) {
    currentPageIndex.value++;
  }
}

function previousPage() {
  if (currentPageIndex.value > 0) {
    currentPageIndex.value--;
  }
}

function dismissPopup() {
  showPopup.value = false;
  localStorage.setItem(`dismissedFeaturePopup-${feature.value.id}`, "true");
}

function handleNextButtonClick() {
  if (currentPageIndex.value === (feature.value.pages?.length ?? 0) - 1) {
    dismissPopup();
  } else {
    nextPage();
  }
}
</script>
