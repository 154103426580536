<template>
  <span
    v-if="text"
    class="inline-flex items-center rounded-full text-center font-sans text-xs leading-none"
    :class="[
      color,
      compact ? 'p-0 font-semibold' : 'border-2 px-2 py-0.5 font-bold',
    ]"
  >
    {{ text }}
  </span>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { RouteRecordRaw } from "vue-router";
import { routeDisabledDueToAccessTier } from "@/utils/tiers";
import { DateTime } from "luxon";
import * as lodash from "lodash-es";
import { useOrganizationQuery } from "@/services/organization";

const props = defineProps({
  route: { type: Object as PropType<RouteRecordRaw>, required: true },
  darkMode: { type: Boolean, default: false }, // On dark backgrounds, use lighter colors
  compact: { type: Boolean, default: false },
});

const organization = useOrganizationQuery();

const color = computed(() =>
  (props.route.meta?.requiredTier &&
    routeDisabledDueToAccessTier(props.route, organization.data)) ||
  props.route.meta?.public === false
    ? props.darkMode
      ? "border-blue-300 text-blue-300"
      : "border-blue-600 text-blue-600"
    : isRecent(props.route.meta?.added)
    ? props.darkMode
      ? "border-orange-300 text-orange-300"
      : "border-orange-500 text-orange-500"
    : props.darkMode
    ? "border-green-300 text-green-300"
    : "border-green-600 text-green-700",
);

const text = computed(() => {
  if (props.route.meta?.public === false) {
    return "Hidden";
  }
  return props.route.meta?.requiredTier &&
    routeDisabledDueToAccessTier(props.route, organization.data)
    ? `Fyr ${lodash.capitalize(props.route.meta?.requiredTier)}`
    : isRecent(props.route.meta?.added)
    ? "New"
    : isRecent(props.route.meta?.updated)
    ? "Updated"
    : "";
});

function isRecent(addedDate?: string): boolean {
  if (!addedDate) {
    return false;
  }
  return (
    DateTime.local().diff(DateTime.fromSQL(addedDate), "days").as("days") <= 21
  );
}
</script>
