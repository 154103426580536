import { Ref, ShallowRef, shallowRef, watch } from "vue";
import Fuse, { IFuseOptions } from "fuse.js";

export function arrayEquals(a: unknown[], b: unknown[]): boolean {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function useFuse<T>(
  items: Ref<readonly T[]>,
  search: Ref<string>,
  options?: IFuseOptions<T>,
): ShallowRef<T[]> {
  const fuse = new Fuse(items.value, options);
  const filteredItems = shallowRef<T[]>([...items.value]);
  watch(
    [items, search],
    () => {
      if (search.value) {
        fuse.setCollection(items.value);
        filteredItems.value = fuse
          .search(search.value)
          .map((result) => result.item);
      } else {
        filteredItems.value = [...items.value];
      }
    },
    { deep: true },
  );
  return filteredItems;
}

export function includesValues(array: string[], values: string[]): boolean {
  return values.every((val) => {
    return array.includes(val);
  });
}
