<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-40 flex items-end px-4 py-6 sm:items-start sm:p-6"
    :class="marginTop"
  >
    <!-- <div
      v-if="showRefreshPopup"
      id="popup-message-target"
      class="hidden w-full sm:flex flex-col items-center space-y-4 sm:items-end"
    >
    </div> -->
    <div
      id="popup-message-target"
      class="flex w-full flex-col items-center space-y-4 sm:items-end"
    >
      <NewVersionPopup
        class="hidden sm:block"
        :show="showRefreshPopup"
        @refresh-page="$emit('refresh-page')"
      />
      <MissingResourcePopupMessage
        :required-resources="routeRequiredResources"
        :can-show="shouldShowMissingResourcePopup && hasRequiredData"
      />
      <NoticePopup
        v-for="notice in notices.data"
        :key="notice.pk"
        :notice="notice"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRef, watch } from "vue";
import { RouteLocationNormalized, useRouter } from "vue-router";
import MissingResourcePopupMessage from "@/components/Core/PopupMessages/MissingResourcePopupMessage.vue";
import { useOrganizationStore } from "@/store/organization";
import { ResourceChannel, useResourcesQuery } from "@/services/resources";
import { useNoticesQuery } from "@/services/notices";
import NewVersionPopup from "@/components/Core/PopupMessages/NewVersionPopup.vue";
import NoticePopup from "@/components/Core/PopupMessages/NoticePopup.vue";
defineProps({ showRefreshPopup: { type: Boolean, default: false } });
defineEmits({
  "refresh-page": () => true,
});
const notices = useNoticesQuery();

const marginTop = computed(() => {
  if ((notices.data ?? []).length > 3) return "mt-38";
  return {
    3: "mt-32",
    2: "mt-28",
    1: "mt-22",
    0: "mt-16",
  }[(notices.data ?? []).length];
});

const router = useRouter();
const shouldShowMissingResourcePopup = ref(false);
const routeRequiredResources = ref<ResourceChannel[]>([]);

const resources = useResourcesQuery();
const channelResources = computed(() => {
  return resources.data?.map((resource) => resource.channel) ?? [];
});

function maybeShowMissingResourcePopup(r: RouteLocationNormalized): void {
  routeRequiredResources.value = [...(r.meta.requiredResources ?? [])];
  if (routeRequiredResources.value.length > 0) {
    shouldShowMissingResourcePopup.value = routeRequiredResources.value.some(
      (r: ResourceChannel) => channelResources.value.includes(r),
    );
  }
}
const organizationStore = useOrganizationStore();

// Don't show the MissingResource popup if there's something else preventing the route from working
const hasRequiredData = toRef(organizationStore, "routeHasRequiredData");

watch(channelResources, () => {
  maybeShowMissingResourcePopup(router.currentRoute.value);
});

router.beforeEach(() => {
  organizationStore.setRouteHasRequiredData(true);
});

router.afterEach((to, _from, _failure) => {
  maybeShowMissingResourcePopup(to);
});
</script>
