<template>
  <div id="base" class="h-full">
    <Navbar
      v-if="
        route.meta.includeNavbar === 'isAuthed'
          ? authStore.isAuthed
          : route.meta.includeNavbar ?? true
      "
      :show-menu-popup="showMenuPopup"
      :guided-menu-item="menuItem"
      :class="{ 'mb-4': !checkRoute }"
    />
    <PopupMessageOverlay
      v-if="authStore.isAuthed || showRefreshPopup"
      :show-refresh-popup="showRefreshPopup"
      @refresh-page="onRefreshPage"
    />
    <div
      id="main-base"
      class="min-h-screen w-full"
      :class="{
        'px-2': route.name !== 'register-user',
        'xl:container': route.meta.containered ?? true,
      }"
    >
      <RouterView />
      <HelpBubble v-if="showHelpBubble" />
      <MenuGuidedPopup
        v-model:menu-item="menuItem"
        :open="showMenuPopup"
        @update:open="handleModalClose"
      ></MenuGuidedPopup>

      <!-- Paused this popup for now -->
      <NewFeaturePopup
        v-if="newFeaturePopupState.show"
        :feature-key="newFeaturePopupState.key"
      />
    </div>
    <Footer v-if="route.meta.includeFooter ?? true" />
  </div>
  <VueQueryDevtools />
</template>

<script setup lang="ts">
import { useLogoutInterceptor } from "@/services/api";
import { useRequiresAuthNavigationGuard } from "@/router";
import {
  computed,
  onMounted,
  onUnmounted,
  provide,
  reactive,
  ref,
  watch,
} from "vue";
import Navbar from "@/components/Navigation/Navbar.vue";
import Footer from "@/components/Core/Footer.vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import PopupMessageOverlay from "@/components/Core/PopupMessages/PopupMessageOverlay.vue";
import { useTrackLogin } from "@/services/gtm";
import {
  watchRouteForAccessTier,
  watchRouteForOrganization,
  watchRouteForUserRole,
  watchRouteForPermissionRequirement,
  watchRouteForResetFilters,
} from "@/router";
import { useQueryClient } from "@tanstack/vue-query";
import HelpBubble from "@/components/Core/HelpBubble.vue";
import { useHead } from "@unhead/vue";
import { useUpdateTemporalFilter } from "./store/data_filters";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import NewFeaturePopup from "@/components/Core/PopupMessages/NewFeaturePopup.vue";
import MenuGuidedPopup from "@/components/Core/PopupMessages/MenuGuidedPopup.vue";
import { NEW_FEATURE_POP_UP } from "@/utils/newFeature";
import { useWindowSize } from "@vueuse/core";

const router = useRouter();
const authStore = useAuthStore();
const route = useRoute();

const queryClient = useQueryClient();
const showRefreshPopup = ref(false);
const checkRoute = computed(() => {
  return (
    route.name === "dashboards" ||
    route.name === "edit-dashboard" ||
    route.name === "report" ||
    route.name === "view-dashboard" ||
    route.name === "shared-dashboard" ||
    route.name === "report-editor"
  );
});
const showMenuPopup = ref(false);
const menuItem = ref("");
const { width } = useWindowSize();
function localGuidedMenu() {
  // if (localStorage.getItem(`dismissedMenuGuidePopup`)) {
  //   showMenuPopup.value = false;
  //   localStorage.setItem("dismissedMenuGuidePopup", "true");
  // } else {
  if (localStorage.getItem(`dismissedMenuGuidePopup`) !== "true") {
    showMenuPopup.value = true;
    localStorage.setItem("dismissedMenuGuidePopup", "false");
  }
}
watch([() => authStore.isAuthed, () => route.name, width], () => {
  if (
    (route.meta.includeNavbar ?? true) &&
    authStore.isAuthed &&
    width.value > 1024
  ) {
    localGuidedMenu();
  }
});

function handleModalClose(isOpen: boolean) {
  showMenuPopup.value = isOpen;
  if (!isOpen) {
    localStorage.setItem("dismissedMenuGuidePopup", "true");
  }
}
// Sync popup state across tabs
function handleStorageEvent(event: StorageEvent) {
  if (event.key === "dismissedMenuGuidePopup" && event.newValue === "true") {
    showMenuPopup.value = false;
  }
}
onMounted(() => {
  window.addEventListener("storage", handleStorageEvent);
});

onUnmounted(() => {
  window.removeEventListener("storage", handleStorageEvent);
});

const guidedTourRef = ref();
provide("guidedTourRef", guidedTourRef);
const newFeaturePopupState = reactive({
  key: "",
  show: false,
});
provide("newFeaturePopupState", newFeaturePopupState);
watch(
  () => route.name,
  (newName) => {
    const routeName = newName as string;
    if (routeName && NEW_FEATURE_POP_UP[routeName]) {
      newFeaturePopupState.key = routeName;
      newFeaturePopupState.show = true;
    } else {
      newFeaturePopupState.show = false;
    }
  },
);

function onRefreshPage(): void {
  showRefreshPopup.value = false;
  location.reload();
}

const showHelpBubble = computed(() => {
  return !["login", "register-user", "reset", "reset-password"].includes(
    String(route.name),
  );
});

watch(
  () => authStore.isAuthed,
  (isAuthed) => {
    if (!isAuthed) {
      router.replace({
        name: "login",
        query: { redirect: router.currentRoute.value.fullPath },
      });
      queryClient.clear();
    }
  },
);

watchRouteForAccessTier();
watchRouteForUserRole();
watchRouteForOrganization();
watchRouteForPermissionRequirement();
watchRouteForResetFilters();

useLogoutInterceptor();
useRequiresAuthNavigationGuard();
useTrackLogin();

useHead({
  titleTemplate: (title?: string) => (title ? `${title} | Fyr` : "Fyr"),
  title: computed(() => route.meta.title),
});

useUpdateTemporalFilter();
</script>
