import api from "@/services/api";
import type { QueryFunctionContext } from "@/utils/typing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { reactive, computed } from "vue";
import { useAuthStore } from "@/store/auth";
import {
  PublicDashboardFilters,
  usePublicDashboardQueryFilters,
} from "@/services/public_dashboard";
import { setTag } from "@sentry/vue";
import { User, UserRole } from "@/services/user";
import { DateTime } from "luxon";

export enum Tier {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface SubscriptionLimits {
  websiteOptimizationPageLimit: number;
  competitorLimit: number;
}

export interface Organization {
  id: number;
  createdAt: DateTime;
  name: string;
  preferredCurrency: string;
  scrapingIgnoreQueryParams: boolean;
  scrapingUsePlaywright: boolean;
  subscriptionTier: Tier;
  subscriptionEnd: string;
  subscriptionLimits: SubscriptionLimits;
  domains: string[];
  alternativeNames: string[];
  manuallyFetchedSiteScoreAt: string | null;
  canFetchSiteScoreManually: boolean;
  isAgency: boolean;
}

export interface AgencySubOrganizationsResponse {
  subOrganizationsWithParentAgencyUsers: Record<string, string[]>;
  subOrganizationDetails: Record<string, Organization>;
  userDetails: Record<string, User>;
}

export interface Currency {
  code: string;
  description: string;
}

export const GET_ORGANIZATION_URL = "/auth/organization/";

function getOrganization({
  queryKey: [, params],
}: QueryFunctionContext<PublicDashboardFilters>): Promise<Organization> {
  return api.get(GET_ORGANIZATION_URL, { params }).then((response) => {
    const organization = response.data;
    setTag("organization.id", organization.id);
    setTag("organization.name", organization.name);
    return organization;
  });
}

export function useOrganizationQuery() {
  const authStore = useAuthStore();
  const publicDashboardQueryFilters = usePublicDashboardQueryFilters();
  return reactive(
    useQuery({
      queryKey: ["organization", { ...publicDashboardQueryFilters }] as const,
      queryFn: getOrganization,
      enabled: computed(
        () =>
          authStore.isAuthed ||
          publicDashboardQueryFilters.dashboard !== undefined,
      ),
    }),
  );
}

function listOrganizations(): Promise<Organization[]> {
  return api.get("/auth/organizations/").then((response) => response.data);
}

export function useOrganizationsQuery() {
  const authStore = useAuthStore();
  return reactive(
    useQuery({
      queryKey: ["organizations"] as const,
      queryFn: listOrganizations,
      enabled: computed(() => authStore.isAuthed),
    }),
  );
}

function listAgencySubOrganizations(): Promise<AgencySubOrganizationsResponse> {
  return api
    .get("/auth/agency_sub_organizations/")
    .then((response) => response.data);
}

export function useAgencySubOrganizationsQuery() {
  const authStore = useAuthStore();
  return reactive(
    useQuery({
      queryKey: ["agencySubOrganizations"] as const,
      queryFn: listAgencySubOrganizations,
      enabled: computed(() => authStore.isAuthed),
    }),
  );
}

function updateOrganization(
  organization: Partial<
    Pick<
      Organization,
      "name" | "preferredCurrency" | "domains" | "alternativeNames"
    >
  >,
): Promise<Organization> {
  return api
    .patch("/auth/organization/", organization)
    .then((response) => response.data);
}

export function useUpdateOrganizationMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: updateOrganization,
      onSuccess: (organization) => {
        queryClient.setQueryData<Organization>(
          ["organization", {}],
          organization,
        );
        queryClient.invalidateQueries({ queryKey: ["organization"] });
        queryClient.invalidateQueries({ queryKey: ["organizations"] });
        // Because these include organization names, we invalidate them in case the name was changed.
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["profile"] });
        queryClient.invalidateQueries({ queryKey: ["changelogEntries"] });
      },
    }),
  );
}

function listSupportedCurrencies(): Promise<Currency[]> {
  return api
    .get("/auth/supported_currencies/")
    .then((response) => response.data);
}

export function useSupportedCurrenciesQuery() {
  return reactive(
    useQuery({
      queryKey: ["supportedCurrencies"],
      queryFn: listSupportedCurrencies,
    }),
  );
}

export interface CreateOrganizationPayload {
  name: string;
  preferredCurrency: string;
  domains: string[];
  users: { email: string; role: UserRole }[];
  isDemo?: boolean;
  isAgency?: boolean;
}
function createOrganization(
  payload: CreateOrganizationPayload,
): Promise<Organization> {
  return api
    .post("/auth/organizations/", payload)
    .then((response) => response.data);
}

export function useCreateOrganizationMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: createOrganization,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["profile"] });
        queryClient.invalidateQueries({ queryKey: ["organizations"] });
        queryClient.invalidateQueries({
          queryKey: ["agency_sub_organizations"],
        });
      },
    }),
  );
}
