<template>
  <div>
    <video
      ref="videoPlayer"
      :src="videoUrl"
      class="w-full rounded-md"
      controls
      :autoplay="true"
      muted
      :loop="true"
    />
  </div>
</template>

<script setup lang="ts">
import { watch, toRefs, useTemplateRef } from "vue";

const props = defineProps({
  videoUrl: {
    type: String,
    required: true,
  },
});

const { videoUrl } = toRefs(props);

const videoPlayer = useTemplateRef("videoPlayer");

watch(videoUrl, (_newUrl) => {
  if (videoPlayer.value) {
    videoPlayer.value.load();
  }
});
</script>
