import {
  UseQueryReturnType,
  QueryFunctionContext as _QueryFunctionContext,
  UseInfiniteQueryReturnType,
} from "@tanstack/vue-query";
import { UnwrapNestedRefs } from "vue";

export function assertUnreachable(x: never): never {
  throw new Error(`Unhandled: ${JSON.stringify(x)}`);
}

export type QueryFunctionContext<T, TPageParam = never> = _QueryFunctionContext<
  readonly [string, T],
  TPageParam
>;

export type UseQueryResult<
  TData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
> = UnwrapNestedRefs<UseQueryReturnType<TData, TError>>;

export type UseInfiniteQueryResult<
  TData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
> = UnwrapNestedRefs<UseInfiniteQueryReturnType<TData, TError>>;
