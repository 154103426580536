<template>
  <BasePopupMessage :show="show">
    <div class="flex flex-col gap-3 p-4">
      <h1 class="flex gap-2 text-xl font-semibold text-gray-700">
        <span> A new version of Fyr is available! </span>
        <span>&#x1F389;</span>
      </h1>
      <div>
        <p>To get the latest update, please refresh your browser window.</p>
      </div>
      <div class="size-full">
        <button
          class="flex w-full items-center justify-center gap-2 rounded-md bg-green-500 px-4 py-2 text-lg font-semibold text-white hover:bg-green-600 focus:outline-none focus:ring"
          @click="emitRefresh"
        >
          <Icon name="sync-alt" class="-mb-0.5 inline size-4" />
          <span> Refresh </span>
        </button>
      </div>
    </div>
  </BasePopupMessage>
</template>

<script setup lang="ts">
import Icon from "@/components/Core/Icon.vue";
import BasePopupMessage from "@/components/Core/PopupMessages/BasePopupMessage.vue";

defineProps({
  show: { type: Boolean, default: false },
});
const emit = defineEmits({
  "refresh-page": () => true,
});
function emitRefresh(): void {
  emit("refresh-page");
}
</script>
