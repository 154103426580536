export interface Page {
  title: string;
  description?: string;
  videoUrl?: string;
  imageUrl?: string;
}

export interface NewFeaturePopup {
  id: string;
  pages: Page[];
}

export function dismissSelectedPopups(ids: string[]) {
  ids.forEach((id) => {
    localStorage.setItem(`dismissedFeaturePopup-${id}`, "true");
  });
  localStorage.setItem("dismissedSelectedPopups", "true");
}

export const NEW_FEATURE_POP_UP: Record<string, NewFeaturePopup> =
  Object.fromEntries(
    [
      "website-analytics-acquisition-tab",
      "website-analytics-pages-tab",
      "website-analytics-conversions-tab",
      "website-analytics-conversion-details",
      "ad-performance-overview",
      "marketing-performance-ads",
      "search-term-report",
      "search-term-detail",
      "social-media-account-summary",
      "ecommerce",
      "site-score",
      "page-insights-content",
      "page-insights-acquisition",
      "page-insights-site-score",
    ].map((route) => [
      route,
      {
        id: "comparison",
        pages: [
          {
            title:
              "You can now compare different time intervals\n in graphs and tables!",
            description:
              "Activate the comparison toggle within the date picker and select a date range and the period you want to compare to.",
            videoUrl:
              "https://lightkeeper-dev.ams3.digitaloceanspaces.com/new_feature_video/Screen%20Recording%202024-10-10%20at%2013.14.15.webm",
          },
          {
            title:
              "If the comparison in tables is too overwhelming,\n you also have the option to turn it off",
            description:
              "Click on the arrow icon to show or hide the comparison in tables.",
            videoUrl:
              "https://lightkeeper-dev.ams3.digitaloceanspaces.com/new_feature_video/Screen%20Recording%202024-10-10%20at%2015.00.23.mov",
          },
          {
            title: "Adform has landed in Fyr! 🎉",
            description:
              "You can connect your Adform account now to start collecting and analysing your data!",
            imageUrl:
              "https://lightkeeper-dev.ams3.digitaloceanspaces.com/new_feature_video/Group%201.png",
          },
        ],
      },
    ]),
  );
