import { RouteLocationNormalizedLoaded, RouteRecordRaw } from "vue-router";

export const authRoutes: RouteRecordRaw[] = [
  {
    path: "register-user",
    name: "register-user",
    meta: { title: "Register", includeNavbar: false, includeFooter: false },
    props: (
      route: RouteLocationNormalizedLoaded,
    ): Record<string, string | (string | null)[]> => ({
      token: route.query.token || "0",
      id: route.query.id || "0",
    }),
    component: () => import("@/views/Auth/RegisterUser.vue"),
  },
  {
    path: "join-organization",
    name: "join-organization",
    meta: {
      title: "Join organization",
      includeNavbar: false,
      includeFooter: false,
    },
    props: (
      route: RouteLocationNormalizedLoaded,
    ): Record<string, string | (string | null)[]> => ({
      token: route.query.token || "0",
      id: route.query.id || "0",
    }),
    component: () => import("@/views/Auth/JoinOrganization.vue"),
  },
  {
    path: "auth",
    name: "auth",
    redirect: { name: "login" },
    meta: {
      title: "Auth",
      requiresAuth: false,
      includeNavbar: false,
      includeFooter: false,
    },
    component: () => import("@/views/Auth/AuthBase.vue"),
    children: [
      {
        path: "login",
        name: "login",
        meta: { title: "Login", depth: 0 },
        component: () => import("@/views/Auth/Components/Login.vue"),
      },
      {
        path: "reset",
        name: "reset",
        meta: { title: "Reset Password", depth: 1 },
        component: () => import("@/views/Auth/Components/ResetPassword.vue"),
      },
      {
        path: "reset-password/:uidb64",
        name: "reset-password",
        meta: {
          title: "Select New Password",

          depth: 1,
        },
        props: true,
        component: () =>
          import("@/views/Auth/Components/SelectNewPassword.vue"),
      },
    ],
  },
];
